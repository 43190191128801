import React from "react";
import { useNavigate } from "react-router-dom";

import "./button.scss";

/**
 *
 * @param {{
 * type: "pill" | "pill" | "roundedSquare" | "hollow";
 * color: "blue" | "grey_blue" | "blue_bg_effect" | "blue_bg_transparent" | "darkgrey" | "blue_2" | "blue_light" | "black_light" | "grey_light" | "red" | "dark_red" | "white" | "grey_2" | "black" | "hollow_white" | "hollow_blue" | "dark_mode_grey" | "dark_mode_white" | "dark_mode_grey_light";
 * size: "smallest" | "small" | "medium" | "large";
 * text: "";
 * link: "";
 * formSubmit: true | false;
 * callback: () => {};
 * centered: false | true,
 * extraClass: "",
 * disabled: true | false,
 * externalLink: true | false
 * }} props Props for the component
 *
 */

const Button = ({
  color = "blue",
  type = "pill",
  size = "small",
  text,
  link = "",
  externalLink = false,
  formSubmit = false,
  callback = null,
  disabled = false,
  centered = false,
  extraClass = "",
  children,
}) => {
  const navigate = useNavigate();

  return (
    <button
      type={formSubmit ? "submit" : "button"}
      onClick={() => {
        if (callback) callback();
        if (link && !externalLink) navigate(link);
        if (link && externalLink) window.location.href = link;
      }}
      className={`btn ${color} ${size} ${type} ${
        centered ? "centered" : ""
      } ${extraClass}`}
      disabled={disabled}
    >
      {children} {text}
    </button>
  );
};

export default Button;
