import { toast } from "react-toastify";
import {
  BTLR_BETA_API_BASE,
  postBusinessEmail,
  business,
  betaAdminGetBusinessDetails,
  betaAdminInviteTeamMemberURL,
  betaAdminAcceptOrRejectTeamMemberURL,
  betaAdminUpdateTeamMemberRoleURL,
  adminGetUserDetails,
  adminUpdateUserDetails,
  adminDeleteUser,
  handleCustomPreferenceStatusAPIURL,
} from "../../api/base";

import { checkLoginCookieBetaAdmin } from "../../api/helpers/authCookies";

import { buildBasicLoader } from "../../api/loaders/basic";

export const sortPreferences = (preferences) => {
  const temp = [...preferences];

  return temp.sort((a, b) => {
    return a["order"] - b["order"];
  });
};

export const updateStatusOfCustomPreferencesAPI = (
  userPreferenceId,
  preferenceId,
  status,
  token
) => {
  return fetch(`${BTLR_BETA_API_BASE}${handleCustomPreferenceStatusAPIURL}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ userPreferenceId, preferenceId, status }),
  });
};

export const saveAllPreferencesAPI = (API_URL, body, token) => {
  return fetch(API_URL, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

export const updateBusinessEmail = (body) => {
  return fetch(`${BTLR_BETA_API_BASE}${postBusinessEmail}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const getBusinessDetail = (id) => {
  return fetch(`${BTLR_BETA_API_BASE}${business}/${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getBusinessMembersBeta = (callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaAdminGetBusinessDetails + businessId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaAdmin() ?? token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data?.users);
      else console.log(data?.messsage ?? "Unable to load team members");
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const inviteBusinessMemberBeta = (
  values,
  callback,
  token,
  businessId
) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaAdminInviteTeamMemberURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaAdmin() ?? token}`,
    },
    body: JSON.stringify({ ...values, businessId }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        callback();
        toast.success("Invite sent!", { theme: "dark" });
      } else
        toast.error(data?.message ?? "Contact admin to add team member", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessAcceptMember = (_id, callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaAdminAcceptOrRejectTeamMemberURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaAdmin() ?? token}`,
    },
    body: JSON.stringify({ status: "APPROVE", businessId, userId: _id }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.messsage ?? "Unable to Accept", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessRejectMember = (_id, callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaAdminAcceptOrRejectTeamMemberURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaAdmin() ?? token}`,
    },
    body: JSON.stringify({ status: "REJECT", businessId, userId: _id }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.messsage ?? "Unable to Reject", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessUpdateMemberRole = (
  _id,
  accessType,
  callback,
  token,
  businessId
) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaAdminUpdateTeamMemberRoleURL, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaAdmin() ?? token}`,
    },
    body: JSON.stringify({ _id, accessType, businessId }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.messsage ?? "Unable to update role of team member", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const getUserDetails = (id, callback, token) => {
  const destroy = buildBasicLoader();
  fetch(`${BTLR_BETA_API_BASE}${adminGetUserDetails}${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        callback(data?.user);
      } else {
        toast.error(
          data?.message ??
            "Could not load the user details. Try reloading the page."
        );
      }
    })
    .catch((error) => {
      console.error("Loading user details error", error);
    })
    .finally(destroy);
};

export const updateUserDetails = (id, values, callback, token) => {
  const destroy = buildBasicLoader();
  fetch(`${BTLR_BETA_API_BASE}${adminUpdateUserDetails}${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      if (data?.status) {
        callback();
      } else {
        toast.error(
          data?.message ??
            "Could not update the user details. Try reloading the page."
        );
      }
    })
    .catch((error) => {
      console.error("Loading user details error", error);
    })
    .finally(destroy);
};

export const deleteUser = (id, callback, token) => {
  const destroy = buildBasicLoader();
  fetch(`${BTLR_BETA_API_BASE}${adminDeleteUser}${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      if (data?.status) {
        callback();
      } else {
        toast.error(
          data?.message ?? "Could not delete the user. Try reloading the page."
        );
      }
    })
    .catch((error) => {
      console.error("Loading user details error", error);
    })
    .finally(destroy);
};
