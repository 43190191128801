import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BetaContainer from "../betaContainer";
import Form from "../../../components/common/form/form";
import Button from "../../../components/common/button/button";
import Modal from "../../../components/common/modal/modal";

import { getSalutations } from "../../b2b/signup/signup.service";
import {
  b2cAccountInputs,
  b2cAccountInputsForPopup,
  betaSignup,
  getUserDataBeta,
  updateUserDataBeta,
} from "./service";
import { resendConfirmationEmail } from "../signup/signup.service";
import { checkLoginCookieBeta } from "../../../api/helpers/authCookies";

import { close_thin, go_back_black } from "../../../assets/svgs/action_icons";
import { gmail_icon } from "../../../assets/svgs/icons";
import success_bg from "../../../assets/images/b2c/auth/success.jpg";

import "./styles.scss";

const GMailURL = `https://mail.google.com/mail/u/0/#advanced-search/from=btlr.vip&subject=Confirm+Email&subset=all&has=Confirm+Email&within=1d&sizeoperator=s_sl&sizeunit=s_smb&query=from%3A(btlr.vip)+subject%3A(Confirm+Email)+Confirm+Email`;

const B2CAccount = () => {
  const navigate = useNavigate();
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [inputs, setInputs] = useState(
    window.innerWidth <= 700
      ? [...b2cAccountInputs]
      : [...b2cAccountInputsForPopup]
  );
  const [prefilledValues, setPrefilledValues] = useState({});
  const [confirmAccountModal, setConfirmAccountModal] = useState(false);
  const [confirmAccountScreen, setConfirmAccountScreen] = useState(false);
  const [confirmAccountEmail, setConfirmAccountEmail] = useState("");
  const [gmail, setGmail] = useState(false);
  const [loginEmailResent, setLoginEmailResent] = useState(false);
  const [signupResend, setSignupResend] = useState(false);

  const handleSignup = (values) => {
    if (signupResend) {
      resendConfirmationEmail(values, () => {
        setLoginEmailResent(true);
      });
      return;
    }

    const updateObject = { ...BTLRBeta, ...values };
    setBTLRBeta(updateObject);
    if (BTLRBeta?.loggedIn)
      updateUserDataBeta("", updateObject, BTLRBeta.loginToken);
    else {
      // Parse preferences for signup
      let parsedData = [];

      // Set predefined data first
      const businessIds = Object.keys(BTLRBeta?.preferences?.predefined);

      businessIds.forEach((bId) => {
        const businessPreferencesObject = {
          businessId: bId,
          preferences: {
            ...BTLRBeta?.preferences,
            predefined: [...BTLRBeta?.preferences.predefined[bId]],
          },
        };
        parsedData.push(businessPreferencesObject);
      });

      const signupObject = {
        ...values,
        ...updateObject,
        ...parsedData[0],
        ...parsedData,
        signupType: "b2c",
      };

      console.log(signupObject);

      betaSignup(signupObject, () => {
        setSignupResend({ ...signupObject, resend: true });
        setConfirmAccountEmail(values?.email);
        if (window.innerWidth <= 700) {
          setConfirmAccountScreen(true);
        } else {
          setConfirmAccountModal(true);

          if (
            values?.email?.split("@")[1].includes("gmail.com") ||
            values?.email?.split("@")[1].includes("btlr.vip")
          )
            setGmail(true);
          else setGmail(false);
        }
      });
    }
  };

  useEffect(() => {
    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      const temp = [...inputs];
      temp[0].options = parsedSalutationsData;
      setInputs(temp);

      // prepopulate
      const userData = {
        salutation: "",
        lastName: "",
        email: "",
        firstName: "",
        country: "",
        language: "",
        dob: "",
      };

      Object.keys(userData).forEach((key) => {
        if (BTLRBeta[key]) userData[key] = BTLRBeta[key];
      });

      setPrefilledValues({ ...userData });
    });
  }, [BTLRBeta]);

  console.log(prefilledValues);

  useEffect(() => {
    const loginToken = checkLoginCookieBeta() ?? BTLRBeta.loginToken;

    if (loginToken && !BTLRBeta?.loggedIn) {
      try {
        getUserDataBeta(setBTLRBeta, BTLRBeta.loginToken);
      } catch (error) {
        console.error("error with user data", error);
      }
    }
  }, []);

  useEffect(() => {
    if (!BTLRBeta?.loggedIn) {
      setBTLRBeta({
        ...BTLRBeta,
        b2cNavGoBack: BTLRBeta?.businessPreview?.pageData?.enableSharePage
          ? `/personal/share-preferences`
          : `/personal/edit-preferences`,
      });
    } else {
      setBTLRBeta({ ...BTLRBeta, b2cNavGoBack: false });
    }
  }, [BTLRBeta?.loggedIn]);

  return (
    <BetaContainer>
      {!confirmAccountScreen && (
        <div className="inner_container inner_container_beta b2c_account">
          <div className="left">
            {BTLRBeta?.businessPreview && (
              <button
                className="nav_go_back logged_in"
                style={{ marginTop: "34px" }}
                onClick={() => navigate(-1)}
              >
                {go_back_black}
              </button>
            )}
            <div className="user-icon">
              {BTLRBeta?.firstName?.charAt(0) ?? "H"}
            </div>
            <h1 className="heading basier center_align">{"Account"}</h1>

            <div style={{ margin: "30px 0 100px 0" }}>
              <Form
                prefilledValues={prefilledValues}
                inputs={inputs}
                submit_text={"Save"}
                submitForm={handleSignup}
                greyInputs={true}
              />
            </div>
          </div>
        </div>
      )}
      {confirmAccountScreen && (
        <div className="signup">
          <div className="form_container" style={{ zIndex: 10 }}>
            <img
              src={success_bg}
              alt="Confirm your login"
              className="success_bg"
            />
            <button
              className="close_btn grey"
              onClick={() => {
                setConfirmAccountScreen(false);
              }}
            >
              {close_thin}
            </button>
            <div className="form_inner move_top">
              <div className="form_header"></div>

              <h2 className="heading medium center_align">
                A link is on the way
              </h2>

              <p
                className="text manrope center_align"
                style={{ color: "#E5E8ED" }}
              >
                We’ve sent you an email to{" "}
                <strong>{confirmAccountEmail}</strong> please confirm your
                account to Sign In/Up.
              </p>
              <div className="success">
                <Button
                  type={"pill"}
                  size={"medium"}
                  color={"white"}
                  callback={() => {
                    handleSignup(signupResend);
                  }}
                >
                  {loginEmailResent ? "Email Resent!" : "Resend Email"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Confirm Login */}
      <Modal
        dark={true}
        open={confirmAccountModal}
        opener={() => setConfirmAccountModal(false)}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">A link is on the way</h1>

          <p className="text manrope center_align" style={{ color: "#E5E8ED" }}>
            We’ve sent you an email to{" "}
            <span className="blue_text">{confirmAccountEmail}</span> please
            confirm your account to Sign In/Up.
          </p>

          {!gmail && (
            <Button
              text={loginEmailResent ? "Email Resent!" : "Resend Email"}
              type={"pill"}
              color={"white"}
              size={"medium"}
              centered={true}
              callback={() => {
                handleSignup(signupResend);
              }}
            />
          )}

          {gmail && (
            <>
              <div className="gmail_link_container">
                <a
                  href={GMailURL}
                  target={"_blank"}
                  className="btn hollow_white medium pill centered gmail_btn"
                >
                  {gmail_icon} Open Gmail
                </a>
              </div>
              <p
                className="blue_3_text text center_align cursor"
                style={{ marginTop: "12px" }}
                onClick={() => {
                  handleSignup(signupResend);
                }}
              >
                {loginEmailResent ? "Email Resent!" : "Resend Email"}
              </p>
            </>
          )}
        </div>
      </Modal>
    </BetaContainer>
  );
};

export default B2CAccount;
