import React, { useState, useEffect, useContext } from "react";
import { findIndex, isEqual } from "lodash";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../api/loaders/basic";

import { saveAllPreferencesAPI } from "../service";

import Button from "../../../components/common/button/button";
import Modal from "../../../components/common/modal/modal";
import Input from "../../../components/common/input/input";
import AdminEditPreference from "./adminEditPreference";

import {
  sortPreferences,
  updateStatusOfCustomPreferencesAPI,
} from "../service";

import { customPrefTypes } from "../../b2c/editPreferences/pref_util";

function editPreferenceTitle(
  data,
  id,
  title,
  description,
  setExistingTitleError
) {
  // Iterate through each category in the data
  for (let i = 0; i < data.length; i++) {
    const category = data[i];

    // Check if the category has the matching _id
    if (category._id === id) {
      // Check if it is a duplicate at this level
      const alreadyExists = data.find(
        (c) =>
          c.title.toLowerCase() === title.toLowerCase() &&
          c._id !== id &&
          !c?.isDeleted
      );

      if (alreadyExists && setExistingTitleError) {
        setExistingTitleError(true);
        return false;
      }

      // Update the category with the new data
      category.title = title;
      category.description = description;
      return true; // Object found and edited
    }

    // Check if the category has subCategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Recursively search for and edit the object in the subCategories
      if (
        editPreferenceTitle(
          category.subCategories,
          id,
          title,
          description,
          setExistingTitleError
        )
      ) {
        return true; // Object found and edited
      }
    }
  }

  return false; // Object not found
}

function deletePreference(data, id, isDelete = true) {
  // Iterate through each category in the data
  for (let i = 0; i < data.length; i++) {
    const category = data[i];

    // Check if the category has the matching _id
    if (category._id === id) {
      if (category._id.includes("temp")) {
        // If it is temp, remove this category from array data
        data.splice(i, 1);
      } else {
        // Update the category with the new data
        category.isDeleted = isDelete;
      }
      return true; // Object found and edited
    }

    // Check if the category has subCategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Recursively search for and edit the object in the subCategories
      if (deletePreference(category.subCategories, id, isDelete)) {
        return true; // Object found and edited
      }
    }
  }

  return false; // Object not found
}

function addPreference(setExistingTitleError, newTitleModal, data, parentId) {
  if (!parentId) {
    // When inserting first level category
    let title = newTitleModal?.title;
    let description = newTitleModal?.description;
    if (!title) return;

    // Check if it already exists
    const alreadyExists = data.find(
      (c) => c.title.toLowerCase() === title.toLowerCase()
    );

    if (alreadyExists) {
      setExistingTitleError(true);
      return false;
    }

    data.map((c) => c.order++);

    data.unshift({
      isDeleted: false,
      order: 0,
      title: title,
      description: description,
      _id: `temp-${+new Date()}`,
      subCategories: [],
    });

    return true;
  }

  // Iterate through each category in the data
  for (let i = 0; i < data.length; i++) {
    const category = data[i];

    // Check if the category has the matching _id
    if (category._id === parentId) {
      let title = newTitleModal?.title;
      let description = newTitleModal?.description;
      if (!title) return;

      const alreadyExists = category.subCategories.find(
        (c) => c.title.toLowerCase() === title.toLowerCase()
      );

      if (alreadyExists) {
        setExistingTitleError(true);
        return false;
      }

      // Update the category with the new data
      category.subCategories.push({
        title: title,
        description: description,
        _id: `temp-${+new Date()}`,
        subCategories: [],
      });
      return true; // Object found and edited
    }

    // Check if the category has subCategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Recursively search for and edit the object in the subCategories
      if (
        addPreference(
          setExistingTitleError,
          newTitleModal,
          category.subCategories,
          parentId
        )
      ) {
        return true; // Object found and edited
      }
    }
  }

  return false; // Object not found
}

function movePreference(data, _id, down = true) {
  // Iterate through each category in the data
  for (let i = 0; i < data.length; i++) {
    const category = data[i];

    // Check if the category has the matching _id
    if (category._id === _id) {
      const index = findIndex(data, { _id });

      if (down) {
        // shift array element to next index
        if (index < data.length - 1)
          data.splice(index + 1, 0, data.splice(index, 1)[0]);
      }

      if (!down) {
        // shift array element to previous index
        if (index > 0) data.splice(index - 1, 0, data.splice(index, 1)[0]);
      }

      return true; // Object found and edited
    }

    // Check if the category has subCategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Recursively search for and edit the object in the subCategories
      if (movePreference(category.subCategories, _id, down)) {
        return true; // Object found and edited
      }
    }
  }

  return false; // Object not found
}

function orderPreferences(data) {
  // Iterate through each category in the data
  for (let i = 0; i < data.length; i++) {
    const category = data[i];

    category.order = i;

    // Check if the category has subCategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Recursively search for and edit the object in the subCategories
      if (orderPreferences(category.subCategories)) {
        return true; // Object found and edited
      }
    }
  }

  return false; // Object not found
}

const AdminEditPreferencesMain = ({
  API_URL,
  token,
  businessId,
  theme = "light",
}) => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [businessName, setBusinessName] = useState();
  const [showActivePreferences, setShowActivePreferences] = useState(true);
  const [adminPreferencesState, setAdminPreferencesState] = useState([]);
  const [adminCustomPreferencesState, setAdminCustomPreferencesState] =
    useState([]);
  const [showCustomPrefType, setShowCustomPrefType] = useState();
  const [approvalNeededCount, setApprovalNeededCount] = useState(0);
  const [preferencesEdited, setPreferencesEdited] = useState(false);
  const [loadLocalChanges, setLoadLocalChanges] = useState({
    view: false,
    data: [],
  });
  const [newTitleModal, setNewTitleModal] = useState(false);
  const [editPreferenceModal, setEditPreferenceModal] = useState(false);
  const [existingTitleError, setExistingTitleError] = useState(false);

  const setLocalPreferencesOfBusiness = (businessId, preferences) => {
    if (!businessId) return;
    try {
      localStorage.setItem(
        `business-${businessId}-preferences`,
        encodeURIComponent(JSON.stringify(preferences))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const removeLocalPreferenesOfBusiness = (businessId) => {
    if (!businessId) return;
    try {
      localStorage.removeItem(`business-${businessId}-preferences`);
    } catch (error) {
      console.error(error);
    }
  };

  const getLocalPreferencesOfBusiness = (businessId) => {
    if (!businessId) return;
    try {
      const localStoredPreferences = JSON.parse(
        decodeURIComponent(
          localStorage.getItem(`business-${businessId}-preferences`)
        )
      );
      return localStoredPreferences;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const compareLocalWithLivePreferences = (callback) => {
    // Compare with original preferences
    if (!token) return;
    fetch(API_URL, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const APIData = data?.preference
          ? data?.preference
          : data?.preferencesWithBusiness[0];

        if (APIData?.preferences) {
          if (callback) callback(APIData?.preferences);
        }
      });
  };

  const preferencesAreEdited = (updated) => {
    setPreferencesEdited(true);

    setLocalPreferencesOfBusiness(businessId, updated);

    setLoadLocalChanges({ ...loadLocalChanges, view: false });

    compareLocalWithLivePreferences((data) => {
      const compare = isEqual([...data], [...updated]);
      console.log("Changes made?", !compare);
      setPreferencesEdited(!compare);
    });
  };

  const getAllPreferences = () => {
    if (!token) return;
    fetch(API_URL, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.status) {
          toast.error(
            data?.message ?? "Could not load the preferences. Unauthorized.",
            { theme }
          );
          return;
        }

        const APIData = data?.preference
          ? data?.preference
          : data?.preferencesWithBusiness[0];

        if (APIData?.preferences?.length > 0) {
          setAdminPreferencesState(sortPreferences([...APIData?.preferences]));
          setBTLRBeta({
            ...BTLRBeta,
            showInActiveCategories: false,
          });
          setPreferencesEdited(false);

          if (APIData?.businessName) setBusinessName(APIData?.businessName);
        }

        if (APIData?.custom?.length > 0) {
          console.log("Custom pref", APIData?.custom);

          // Parsed list
          const temp = [];
          let notApprovedCount = 0;
          APIData.custom.forEach((c) => {
            if (!c?.custom || !c?.custom?.length) return;

            c.custom.forEach((custom_pref) => {
              if (!custom_pref?.userPreference) return;
              const { category, title } = custom_pref.userPreference;
              if (!category || !title) return;
              temp.push({
                ...custom_pref,
                customPrefId: custom_pref._id,
                userPrefId: c._id,
                ...custom_pref?.userPreference,
              });
              if (custom_pref?.status === "PENDING") notApprovedCount++;
            });
          });

          console.log(temp);
          setAdminCustomPreferencesState(temp);
          setApprovalNeededCount(notApprovedCount);
        }
      });
  };

  const savePreferences = (preferences, token) => {
    const destroy = buildBasicLoader();

    saveAllPreferencesAPI(API_URL, { preferences }, token)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          toast.success("Data Uploaded", { theme });
          setPreferencesEdited(false);
          removeLocalPreferenesOfBusiness(businessId);
          setLoadLocalChanges({ view: false, data: [] });
          getAllPreferences();
        } else
          toast.error(data?.message ?? "Something went wrong. Try again.", {
            theme,
          });
      })
      .catch((error) => {
        toast.error("Unathorized", error.message, { theme });
        console.error("Unauthorized", error);
      })
      .finally(() => {
        destroy();
      });
  };

  const updateCustomPreference = (userPreferenceId, preferenceId, status) => {
    const destroy = buildBasicLoader();

    updateStatusOfCustomPreferencesAPI(
      userPreferenceId,
      preferenceId,
      status,
      token
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          toast.success("Custom Preference Updated", { theme });
          setPreferencesEdited(false);
          removeLocalPreferenesOfBusiness(businessId);
          setLoadLocalChanges({ view: false, data: [] });
          getAllPreferences();
        } else
          toast.error(data?.message ?? "Something went wrong. Try again.", {
            theme,
          });
      })
      .catch((error) => {
        toast.error("Unathorized", error.message, { theme });
        console.error("Unauthorized", error);
      })
      .finally(() => {
        destroy();
      });
  };

  useEffect(() => {
    getAllPreferences();
  }, [token]);

  useEffect(() => {
    try {
      const localStoredPreferences = getLocalPreferencesOfBusiness(businessId);

      if (!localStoredPreferences || localStoredPreferences.length === 0)
        return;

      compareLocalWithLivePreferences((data) => {
        const compare = isEqual([...data], [...localStoredPreferences]);
        console.log("Checking on first load: Changes made?", !compare);
        setLoadLocalChanges({
          data: [...localStoredPreferences],
          view: !compare,
        });
      });
    } catch (error) {
      console.error("Error while loading preferences", error);
    }
  }, [token]);

  return (
    <>
      <div
        className="top_bar"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="left">
          <h2 className="heading black_text basier_medium">Preferences</h2>
          <p className="sub_heading semi manrope lightBlack fourHundred">
            Manage all {businessName} Preferences here
          </p>
        </div>
        <div className="right">
          {preferencesEdited && (
            <Button
              text="update"
              type={"roundedSquare"}
              color={"grey_light"}
              size={"medium"}
              callback={() => savePreferences(adminPreferencesState, token)}
            />
          )}
          {!preferencesEdited && (
            <Button
              text="Up to date"
              type={"roundedSquare"}
              color={"blue"}
              size={"medium"}
              callback={() =>
                toast.info("Please make some changes before updating", {
                  theme,
                })
              }
            />
          )}
          <span className="vertical_space"></span>
          {loadLocalChanges?.view && loadLocalChanges?.data?.length > 0 && (
            <Button
              text="Load Local Changes"
              type={"roundedSquare"}
              color={"hollow_blue"}
              size={"medium"}
              callback={() => {
                setLoadLocalChanges({ ...loadLocalChanges, view: false });
                setPreferencesEdited(true);
                setAdminPreferencesState(loadLocalChanges?.data);
              }}
            />
          )}
        </div>
      </div>

      <div className="preferences_switcher hide_scrollbar">
        <button
          className={
            !showCustomPrefType && showActivePreferences ? "active" : ""
          }
          onClick={() => {
            setShowCustomPrefType();
            setShowActivePreferences(true);
            setBTLRBeta({
              ...BTLRBeta,
              showInActiveCategories: false,
            });
          }}
        >
          Active
        </button>
        <button
          className={
            !showCustomPrefType && !showActivePreferences ? "active" : ""
          }
          onClick={() => {
            setShowCustomPrefType();
            setShowActivePreferences(false);
            setBTLRBeta({
              ...BTLRBeta,
              showInActiveCategories: true,
            });
          }}
        >
          Deleted
        </button>
        <button
          className={
            showCustomPrefType === customPrefTypes.pending ? "active" : ""
          }
          onClick={() => {
            setShowCustomPrefType(customPrefTypes.pending);
          }}
        >
          Approval Needed <span>{approvalNeededCount}</span>
        </button>
        <button
          className={
            showCustomPrefType === customPrefTypes.accepted ? "active" : ""
          }
          onClick={() => {
            setShowCustomPrefType(customPrefTypes.accepted);
          }}
        >
          Approved
        </button>
        <button
          className={
            showCustomPrefType === customPrefTypes.rejected ? "active" : ""
          }
          onClick={() => {
            setShowCustomPrefType(customPrefTypes.rejected);
          }}
        >
          Declined
        </button>
      </div>

      <div style={{ display: "flex", gap: "6px" }}>
        <Button
          text="+ Add"
          type={"roundedSquare"}
          color={"grey_light"}
          size={"medium"}
          callback={() => {
            setExistingTitleError(false);
            let adminPreferencesCopy = [...adminPreferencesState];
            setNewTitleModal({
              data: adminPreferencesCopy,
              parent_id: null,
              categoryTitle: null,
            });
          }}
        />
        <Button
          text={
            BTLRBeta?.showInActiveCategories
              ? "Hide Inactive Categories"
              : "Show Inactive Categories"
          }
          type={"roundedSquare"}
          color={"blue_light"}
          size={"medium"}
          callback={() => {
            setBTLRBeta({
              ...BTLRBeta,
              showInActiveCategories: !BTLRBeta?.showInActiveCategories,
            });
          }}
        />
      </div>

      {!showCustomPrefType && (
        <div className="admin_preferences">
          {adminPreferencesState?.length > 0 &&
            adminPreferencesState.map((preference, index) => {
              if (preference?.isDeleted === !showActivePreferences)
                return (
                  <div
                    key={preference?._id ?? index}
                    className={`white_box`}
                    style={{
                      margin: "20px 0",
                      position: "relative",
                      paddingTop: 0,
                      paddingBottom: "6px",
                    }}
                  >
                    <AdminEditPreference
                      index={index}
                      showInActiveCategories={BTLRBeta?.showInActiveCategories}
                      preference={preference}
                      preferences={adminPreferencesState}
                      setPreferences={setAdminPreferencesState}
                      editPreferenceTitle={editPreferenceTitle}
                      deletePreference={deletePreference}
                      addPreference={addPreference}
                      movePreference={movePreference}
                      orderPreferences={orderPreferences}
                      preferencesAreEdited={preferencesAreEdited}
                      setNewTitleModal={setNewTitleModal}
                      setEditPreferenceModal={setEditPreferenceModal}
                      setExistingTitleError={setExistingTitleError}
                    />
                  </div>
                );
            })}
        </div>
      )}
      {showCustomPrefType && (
        <div className="admin_preferences">
          {adminCustomPreferencesState?.length > 0 &&
            adminCustomPreferencesState.map((preference, index) => {
              if (preference?.status === showCustomPrefType)
                return (
                  <div
                    key={index}
                    className={`white_box`}
                    style={{
                      margin: "20px 0",
                      position: "relative",
                    }}
                  >
                    <div className="custom_pref_box">
                      <div className="details">
                        <p className="pref">{preference?.title}</p>
                        <p className="cat">{preference?.category}</p>
                      </div>
                      {showCustomPrefType === customPrefTypes.pending && (
                        <div className="actions">
                          <Button
                            text="Decline"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"red"}
                            callback={() => {
                              updateCustomPreference(
                                preference?.userPrefId,
                                preference?.customPrefId,
                                customPrefTypes.rejected
                              );
                            }}
                          />
                          <Button
                            text="Accept"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"blue_bg_effect"}
                            callback={() => {
                              updateCustomPreference(
                                preference?.userPrefId,
                                preference?.customPrefId,
                                customPrefTypes.accepted
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
            })}
        </div>
      )}

      {/* New preference/category modal */}
      <Modal
        open={newTitleModal}
        opener={setNewTitleModal}
        backgroundColor={"transparent"}
        noBlur={true}
      >
        <div className="white_box">
          <h1 className="heading medium basier black_text">
            New Category/Preference
          </h1>
          <p className="text medium manrope manrope black_text">
            Add
            {newTitleModal?.categoryTitle
              ? ` to ${newTitleModal.categoryTitle}.`
              : ""}
          </p>

          {newTitleModal && (
            <>
              <Input
                type="text"
                label={"Category or Preference"}
                value={""}
                validationCallback={(value) => {
                  setExistingTitleError(false);
                  setNewTitleModal({ ...newTitleModal, title: value });
                }}
                enterPressEvent={() => {
                  const result = addPreference(
                    setExistingTitleError,
                    newTitleModal,
                    newTitleModal.data,
                    newTitleModal?.parentId
                  );
                  if (result) {
                    const sortedListTemp = sortPreferences(newTitleModal.data);
                    setAdminPreferencesState([...sortedListTemp]);
                    preferencesAreEdited([...sortedListTemp]);
                    setNewTitleModal();
                  }
                }}
                greyInputs={theme === "dark"}
              />
              <Input
                type="text"
                label={"Description"}
                value={""}
                validationCallback={(value) =>
                  setNewTitleModal({ ...newTitleModal, description: value })
                }
                enterPressEvent={() => {
                  const result = addPreference(
                    setExistingTitleError,
                    newTitleModal,
                    newTitleModal.data,
                    newTitleModal?.parentId
                  );
                  if (result) {
                    const sortedListTemp = sortPreferences(newTitleModal.data);
                    setAdminPreferencesState([...sortedListTemp]);
                    preferencesAreEdited([...sortedListTemp]);
                    setNewTitleModal();
                  }
                }}
                greyInputs={theme === "dark"}
              />
              {existingTitleError && (
                <p className="text red_text">
                  This preferences or category already exists at this level.
                </p>
              )}
            </>
          )}
          <div className="update_business_btns">
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"blue"}
              text={"Save"}
              callback={() => {
                const result = addPreference(
                  setExistingTitleError,
                  newTitleModal,
                  newTitleModal.data,
                  newTitleModal?.parentId
                );
                if (result) {
                  const sortedListTemp = sortPreferences(newTitleModal.data);
                  setAdminPreferencesState([...sortedListTemp]);
                  preferencesAreEdited([...sortedListTemp]);
                  setNewTitleModal();
                }
              }}
            />
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"red"}
              text={"Delete"}
              callback={() => {
                setNewTitleModal();
              }}
            />
          </div>
        </div>
      </Modal>

      {/* Edit preference/category modal */}
      <Modal
        open={editPreferenceModal}
        opener={setEditPreferenceModal}
        backgroundColor={"transparent"}
        noBlur={true}
      >
        <div className="white_box">
          <h1 className="heading medium basier black_text">
            {editPreferenceModal?.originalTitle}
          </h1>
          <p className="text medium manrope manrope black_text">
            Update or delete
          </p>

          {editPreferenceModal && (
            <>
              <Input
                type="text"
                label={"Category"}
                defaultValue={editPreferenceModal?.updatedTitle}
                validationCallback={(value) => {
                  setExistingTitleError(false);
                  setEditPreferenceModal({
                    ...editPreferenceModal,
                    updatedTitle: value,
                  });
                }}
                enterPressEvent={() => {
                  editPreferenceModal?.editAction(
                    editPreferenceModal,
                    setExistingTitleError
                  );
                }}
                greyInputs={theme === "dark"}
              />
              <Input
                type="text"
                label={"Description"}
                defaultValue={editPreferenceModal?.updatedDescription}
                validationCallback={(value) =>
                  setEditPreferenceModal({
                    ...editPreferenceModal,
                    updatedDescription: value,
                  })
                }
                enterPressEvent={() => {
                  editPreferenceModal?.editAction(
                    editPreferenceModal,
                    setExistingTitleError
                  );
                }}
                greyInputs={theme === "dark"}
              />
              {existingTitleError && (
                <p className="text red_text">
                  This preferences or category already exists at this level.
                </p>
              )}
            </>
          )}
          <div className="update_business_btns">
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"blue"}
              text={"Save"}
              callback={() => {
                editPreferenceModal?.editAction(
                  editPreferenceModal,
                  setExistingTitleError
                );
              }}
            />
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"red"}
              text={"Disable/Hide"}
              callback={() => {
                editPreferenceModal?.deleteAction();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminEditPreferencesMain;
