import React, { useState, useEffect } from "react";

import "./switch.scss";

const Switch = ({ name, defaultValue = false, callback = undefined }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <label className="switch">
      <input
        type="checkbox"
        name={name}
        onChange={() => {
          const updatedValue = !value;
          setValue(updatedValue);
          if (callback) callback(updatedValue);
        }}
        checked={value}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
