import React, { useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BusinessDashboardContainer from "./components/businessContainer";
import B2BAccountModal from "./components/b2bAccountModal";

import { checkLoginCookieBetaBusiness } from "../../../api/helpers/authCookies";

import "./styles.scss";

const BusinessAccount = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  const businessLoginToken =
    checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken;

  return (
    <BusinessDashboardContainer>
      <section id="view-preference">
        <h1 className="heading basier">Share</h1>
        <p className="text light_grey_text manrope">
          Invite team members to join you
        </p>

        <B2BAccountModal
          businessLoginToken={businessLoginToken}
          businessData={BTLRBeta?.selectedBusiness}
          //   refresh={loadBusinessProfileDetails}
        />
      </section>
    </BusinessDashboardContainer>
  );
};

export default BusinessAccount;
