import React, { useState, createContext } from "react";

export const BTLRBetaContext = createContext();

export const BTLRBetaProvider = ({ children }) => {
  const [BTLRBeta, setBTLRBeta] = useState({
    firstTimeUser: true,

    preferences: {
      // predefined: [],
      // custom: [],
    },
    businesses: [],
  });

  return (
    <BTLRBetaContext.Provider value={[BTLRBeta, setBTLRBeta]}>
      {children}
    </BTLRBetaContext.Provider>
  );
};
