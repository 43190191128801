import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { updateBusinessEmail, getBusinessDetail } from './service';

const AddBusinessEmail = () => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [businessDetails, setBusinessDetails] = useState({});
  const { id } = useParams();

  const updateEmail = () => {
    setLoading(true);

    updateBusinessEmail({ email, businessId: id })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) alert(data.message);
      })
      .catch((error) => {
        alert(error.message);
        console.error('Unauthorized', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    getData();
  }, [id]);

  const getData = async () => {
    await getBusinessDetail(id)
      .then((response) => response.json())
      .then((data) => {
        setBusinessDetails(data.business);
      })
      .catch((error) => {
        console.error('Unauthorized', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        color: 'black',
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h1 style={{ marginBottom: 20 }}>Update User Email</h1>{' '}
      <div style={{ display: 'flex' }}>
        <p>Title: </p>
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
          {businessDetails.title}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p>google Place ID: </p>
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
          {businessDetails.googlePlaceID}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p>phone Number: </p>
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
          {businessDetails.phoneNumber}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p>Location: </p>
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
          lat:{businessDetails.lat} long:{businessDetails.long}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p>email: </p>
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
          {businessDetails.email}
        </p>
      </div>
      <input
        style={{ borderRadius: 10, fontSize: 16, height: 40, marginBlock: 30 }}
        onChange={({ target: { value } }) => setEmail(value)}
      />
      <button
        style={{
          padding: 10,
        }}
        title={businessDetails.email && 'email already added'}
        disabled={isLoading || businessDetails.email}
        onClick={updateEmail}>
        Update Email
      </button>
    </div>
  );
};

export default AddBusinessEmail;
