const non_b2b_location = [
  "/",
  "/business",
  "/personal",
  "/faq",
  "/pricing",
  "/contact",
];

export const non_b2b_location_hide = (pathname) => {
  return non_b2b_location.includes(pathname);
};

export const betaLocationCheck = (pathname) => !pathname.includes("internal");

const inviteToBTLR_locations = ["/b2b", "/onboarding"];

export const inviteToBTLR_location_check = (pathname) => {
  let show = false;
  for (let i = 0; i < inviteToBTLR_locations.length; i++) {
    if (pathname.includes(inviteToBTLR_locations[i])) {
      show = true;
      break;
    }
  }
  return show;
};
