import React from "react";

import { close_thin } from "../../assets/svgs/action_icons";
import logo from "../../assets/images/global/logo.svg";
import success from "../../assets/images/paymentSuccess/success.svg";
import "./paymentSuccessful.scss";

const PaymenSuccessful = () => {
  return (
    <div className="payment_successful">
      <button className="close">{close_thin}</button>
      <div className="side receipt">
        <img src={logo} alt="BTLR" className="logo" />
        <div className="payment_details">
          <p className="text grey_2">Paid</p>
          <p className="b_heading">$39,99</p>
          <div className="payment_breakdown">
            <div className="detail">
              <p className="text grey_2">Personal</p>
              <p className="text grey_2">$39,99</p>
            </div>
            <span className="divider"></span>
            <div className="detail">
              <p className="text grey_2">Total Paid</p>
              <p className="text grey_2">$39,99</p>
            </div>
          </div>
        </div>
      </div>
      <div className="side confirmation_message">
        <img src={success} alt="Payment Successful" className="success_image" />
        <div className="message_details">
          <p className="b_heading">Thank you for your payment!</p>
          <p className="text grey_2">
            A payment confirmation email has been sent to you
          </p>
          <div className="user_email">
            <p>hayder@btlr.vip</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymenSuccessful;
