import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import HybridModal from "../../../components/common/hybridModal/hybridModal";
import SlideInPopup from "../../../components/common/slideInPopup/slideInPopup";
import Form from "../../../components/common/form/form";
import Button from "../../../components/common/button/button";
import Input from "../../../components/common/input/input";

import {
  b2bAccountInputs,
  b2bSignupInputsBusinessOnly,
  getSalutations,
  businessBetaSignup,
  addPlaceDetailsToSignupObject,
} from "./signup.service";
import {
  b2cAccountInputs,
  b2cAccountInputsForPopup,
} from "../../b2c/account/service";
import { loginInputs } from "../businessbeta/data.service";
import { businessBetaLogin } from "../businessbeta/data.service";
import { resendConfirmationEmail } from "../../b2c/signup/signup.service";
import { updateBusinessTypesOptionsInList } from "../businessbeta/data.service";

import "./signup.scss";
import logo_white from "../../../assets/images/global/logo_white.svg";
import success_bg from "../../../assets/images/b2b/auth/success.jpg";

import { close_thin, go_back_black } from "../../../assets/svgs/action_icons";
import { gmail_icon } from "../../../assets/svgs/icons";

const GMailURL = `https://mail.google.com/mail/u/0/#advanced-search/from=btlr.vip&subject=Confirm+Email&subset=all&has=Confirm+Email&within=1d&sizeoperator=s_sl&sizeunit=s_smb&query=from%3A(btlr.vip)+subject%3A(Confirm+Email)+Confirm+Email`;

const Signup = () => {
  const navigate = useNavigate();
  const { showForm } = useParams();

  const [showWelcomeScreen, set_showWelcomeScreen] = useState(true);
  const [signUpModal, setSignUpModal] = useState(false);
  const [businessSignupModal, setBusinessSignupModal] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showBusinessFormInModal, setShowBusinessFormInModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [confirmLoginModal, setConfirmLoginModal] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [accountNotFoundModal, setAccountNotFoundModal] = useState(false);
  const [requestSubmittedModal, setRequestSubmittedModal] = useState(false);
  const [businessAccountExistsModal, setBusinessAccountExistsModal] =
    useState(false);
  const [updatedSignUpInputs, setUpdatedSignUPInputs] = useState(
    window.innerWidth <= 700
      ? [...b2cAccountInputs]
      : [...b2cAccountInputsForPopup]
  );
  const [userLoginEmail, setUserLoginEmail] = useState("");
  const [loginEmailResent, setLoginEmailResent] = useState(false);
  const [showEmailNotFoundError, setEmailNotFoundError] = useState(false);
  const [gmail, setGmail] = useState(false);
  const [showSuccessScreenEmailSent, set_showSuccessScreenEmailSent] =
    useState();
  const [signUpValues, setSignUpValues] = useState();
  const [signupResend, setSignupResend] = useState(false);
  const [updatedBusinessCreateInputs, setUpdatedBusinessCreateInputs] =
    useState([...b2bSignupInputsBusinessOnly]);

  const handleLogin = ({ email, resend = false }) => {
    if (email) {
      setUserLoginEmail(email);
      setSignupResend(false);

      businessBetaLogin(
        email,
        undefined,
        () => {
          if (window.innerWidth <= 700) {
            set_showWelcomeScreen(false);
            setShowLoginForm(false);
            set_showSuccessScreenEmailSent(true);
          } else {
            setLoginModal(false);
            setConfirmLoginModal(true);
          }

          if (resend) {
            setLoginEmailResent(true);
          }

          if (
            email.split("@")[1].includes("gmail.com") ||
            email.split("@")[1].includes("btlr.vip")
          )
            setGmail(true);
        },
        () => {
          setEmailNotFoundError(true);
        }
      );
    }
  };

  const handleSignUp = (unParsedValues) => {
    const values = {
      ...unParsedValues,
      businessType:
        unParsedValues?.businessType?.title ?? unParsedValues?.businessType,
    };

    if (signupResend) {
      resendConfirmationEmail({ ...signupResend, signupType: "b2b" }, () => {
        setLoginEmailResent(true);
      });
      return;
    }

    setUserLoginEmail(values?.email);
    setSignupResend({ ...values });

    if (values?.address?.place_id) {
      addPlaceDetailsToSignupObject(
        {
          ...values,
          address: values?.address?.description,
          googlePlaceID: values?.address?.place_id,
        },
        values?.address?.place_id,
        ({ status, data }) => {
          if (status) {
            businessBetaSignup(data, handlePostSignup);
          } else {
            toast.error(
              "Unable to verify business location details. Try again.",
              {
                theme: "dark",
              }
            );
          }
        }
      );
    } else {
      businessBetaSignup(values, handlePostSignup);
    }
  };

  const handlePostSignup = (result) => {
    setShowBusinessFormInModal(false);

    // Gmail button
    if (
      result?.email?.split("@")[1]?.includes("gmail.com") ||
      result?.email?.split("@")[1]?.includes("btlr.vip")
    )
      setGmail(true);

    const { message, modalStatus, status: signUpStatus } = result;

    if (signUpStatus) {
      setSignUpModal(false);
      if (modalStatus === 1) setBusinessAccountExistsModal(true);
      if (modalStatus === 2) setRequestSubmittedModal(true);
      if (modalStatus === 3) {
        if (window.innerWidth <= 700) {
          set_showWelcomeScreen(false);
          setShowLoginForm(false);
          setShowSignupForm(false);
          set_showSuccessScreenEmailSent(true);
          setSignUpModal(false);
          setShowBusinessFormInModal(false);
          setBusinessSignupModal(false);
        } else {
          setLoginModal(false);
          setSignUpModal(false);
          setShowBusinessFormInModal(false);
          setConfirmLoginModal(true);
        }
      }
    } else {
      console.log("Signup unhandleded error");
      toast.error(message ?? "Unable to signup. Try again.", {
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      const temp = [...updatedSignUpInputs];
      temp[0].options = parsedSalutationsData;
      setUpdatedSignUPInputs(temp);
    });
  }, []);

  // Fetch and update business types from API
  useEffect(() => {
    updateBusinessTypesOptionsInList(
      1,
      updatedBusinessCreateInputs,
      setUpdatedBusinessCreateInputs
    );
  }, []);

  useEffect(() => {
    // Reset all first
    setSignUpModal(false);
    setBusinessSignupModal(false);
    setLoginModal(false);
    setConfirmLoginModal(false);
    setLoginModal(false);
    setShowLoginForm(false);
    setShowSignupForm(false);
    setGmail(false);
    setSignupResend(false);

    if (!showForm) {
      set_showWelcomeScreen(true);
    }
    if (showForm === "form") {
      if (window.innerWidth <= 700) {
        set_showWelcomeScreen(false);
        setShowLoginForm(true);
      } else {
        setLoginModal(true);
      }
    }
    if (showForm === "signup") {
      if (window.innerWidth <= 700) {
        set_showWelcomeScreen(false);
        setShowSignupForm(true);
      } else {
        setSignUpModal(true);
      }
    }
  }, [showForm]);

  return (
    <div className="signup">
      {showWelcomeScreen && (
        <>
          <div className="welcome">
            <h1 className="heading medium white center_align">
              Welcome to BTLR Business
            </h1>
            <p className="text grey_2 center_align">
              The tool you use to create sensational customer experiences.
            </p>
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"grey_blue"}
              callback={() => navigate("/login/business/form")}
            >
              Login
            </Button>
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"white"}
              callback={() => navigate("/login/business/signup")}
            >
              Signup
            </Button>
            <p
              className="text light_grey_text center_align"
              style={{ marginTop: "24px" }}
            >
              Personal Account?{" "}
              <Link to="/login" className="blue_text">
                Click here
              </Link>
            </p>
          </div>
        </>
      )}

      {!showWelcomeScreen && (
        <div className="form_container">
          {showLoginForm && (
            <>
              <button
                className="close_btn grey"
                onClick={() => {
                  navigate("/login/business");
                }}
              >
                {close_thin}
              </button>
              <div className="form_inner">
                <div className="form_header">
                  <div className="b_heading white_text center_algn">
                    Nice to see you again!
                  </div>
                </div>
                <Form
                  inputs={loginInputs}
                  submit_text={"Login"}
                  submitForm={handleLogin}
                  greyInputs={true}
                />
                {showEmailNotFoundError && (
                  <p
                    className="inner_semi_smaller_info red_text left_align"
                    style={{ marginTop: "4px" }}
                  >
                    Hmmm. We don’t recognise your email.{" "}
                    <span
                      className="blue_text"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login/business/signup");
                      }}
                    >
                      Sign Up
                    </span>
                  </p>
                )}
              </div>
            </>
          )}

          {showSuccessScreenEmailSent && (
            <>
              <img
                src={success_bg}
                alt="Confirm your login"
                className="success_bg"
              />
              <button
                className="close_btn grey"
                onClick={() => {
                  navigate("/login/business");
                }}
              >
                {close_thin}
              </button>
              <div className="form_inner move_top">
                <div className="form_header"></div>

                <h2 className="heading medium center_align">
                  A link is on the way
                </h2>

                <p
                  className="text manrope center_align"
                  style={{ color: "#E5E8ED" }}
                >
                  We’ve sent you an email to <strong>{userLoginEmail}</strong>{" "}
                  please confirm your account to Sign In/Up.
                </p>
                <div className="success">
                  <Button
                    type={"pill"}
                    size={"medium"}
                    color={"white"}
                    callback={() => {
                      if (!signupResend)
                        handleLogin({
                          email: userLoginEmail,
                          resend: true,
                        });
                      else handleSignUp(signupResend);
                    }}
                  >
                    {loginEmailResent ? "Email Resent!" : "Resend Email"}
                  </Button>
                </div>
              </div>
            </>
          )}

          {showSignupForm && (
            <>
              <button
                className="close_btn grey"
                onClick={() => {
                  navigate("/login/business");
                }}
              >
                {close_thin}
              </button>
              <div className="form_inner">
                <div className="form_header">
                  <div className="b_heading white_text center_align">
                    Nice to meet you!
                  </div>
                </div>
                <Form
                  inputs={updatedSignUpInputs}
                  submit_text={"Sign Up"}
                  submitForm={(values) => {
                    setSignupResend();
                    setSignUpValues({ ...values });
                    setBusinessSignupModal(true);
                  }}
                  greyInputs={true}
                />
              </div>
            </>
          )}
        </div>
      )}

      <div className="bg_container business">
        <div className="overlay"></div>
        <img src={logo_white} alt="BTLR" className="logo" />
      </div>

      {/* Signup */}
      <HybridModal
        dark={true}
        open={signUpModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <div style={{ display: !showBusinessFormInModal ? "block" : "none" }}>
            <div
              className="top_area"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="left">
                <h1 className="heading basier">Sign Up</h1>
                <p className="sub_heading semi medium manrope light_grey_text manrope ">
                  Setup your BTLR account and get started.
                </p>
              </div>
              <div className="right"></div>
            </div>
            {signUpModal && (
              <Form
                inputs={updatedSignUpInputs}
                greyInputs={true}
                submit_text={"Continue"}
                custom_submit_2={true}
                size="small"
                submitForm={(values) => {
                  setSignupResend();
                  setSignUpValues({ ...values });
                  // setSignUpModal(false);
                  setShowBusinessFormInModal(true);
                }}
              />
            )}
          </div>
          <div style={{ display: showBusinessFormInModal ? "block" : "none" }}>
            <div
              className="top_area"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <button
                className="white_path"
                style={{ background: "transparent" }}
                onClick={() => setShowBusinessFormInModal(false)}
              >
                {go_back_black}
              </button>
            </div>
            <h1 className="heading basier center_align">BTLR Business</h1>
            <p className="sub_heading semi medium manrope light_grey_text manrope center_align">
              Setup your business account and get started.
            </p>
            {signUpModal && (
              <Form
                inputs={b2bSignupInputsBusinessOnly}
                greyInputs={true}
                submit_text={"Create Account"}
                custom_submit_2={true}
                submitForm={(values) => {
                  setSignupResend();
                  handleSignUp({ ...signUpValues, ...values });
                }}
              />
            )}
          </div>
        </div>
      </HybridModal>

      {/* Add Business */}
      <SlideInPopup
        dark={true}
        open={businessSignupModal}
        opener={() => setBusinessSignupModal(false)}
        backgroundColor={"#000000"}
        bottom
        autoHeight
        blur
      >
        <div className="business_signup_popup">
          {businessSignupModal && (
            <>
              <div className="b_heading white_text">BTLR Business</div>
              <p className="text light_grey_text">
                Setup your business account and get started.
              </p>
              <Form
                inputs={updatedBusinessCreateInputs}
                greyInputs={true}
                submit_text={"Create Account"}
                custom_submit_2={true}
                size="small"
                submitForm={(values) => {
                  handleSignUp({
                    ...signUpValues,
                    ...values,
                    businessType:
                      values?.businessType?.title ?? values?.businessType,
                  });
                }}
              />
            </>
          )}

          <span className="vertical_space"></span>
        </div>
      </SlideInPopup>

      {/* login */}
      <HybridModal
        dark={true}
        open={loginModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">
            Nice to see you again!
          </h1>

          <Form
            inputs={loginInputs}
            prefilledValues={{ consent: true }}
            transparentInputs={true}
            submit_text={"Login"}
            greyInputs={true}
            custom_submit_2={true}
            submitForm={(values) => handleLogin(values)}
          />

          {showEmailNotFoundError && (
            <p
              className="inner_semi_smaller_info red_text left_align"
              style={{ marginTop: "4px" }}
            >
              Hmmm. We don’t recognise your email.{" "}
              <span
                className="blue_text"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/login/business/signup");
                }}
              >
                Sign Up
              </span>
            </p>
          )}
        </div>
      </HybridModal>

      {/* Confirm Login */}
      <HybridModal
        dark={true}
        open={confirmLoginModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">A link is on the way</h1>
          <p className="text manrope center_align" style={{ color: "#E5E8ED" }}>
            We’ve sent you an email to{" "}
            <span className="blue_text">{userLoginEmail}</span> please confirm
            your account to Sign In/Up.
          </p>

          {!gmail && (
            <Button
              type={"pill"}
              size={"medium"}
              color={"white"}
              text={!loginEmailResent ? "Resend Link" : "Email Resent!"}
              centered={true}
              callback={() => {
                if (!signupResend)
                  handleLogin({ email: userLoginEmail, resend: true });
                else handleSignUp(signupResend);
              }}
            />
          )}

          {gmail && (
            <>
              <div className="gmail_link_container">
                <a
                  href={GMailURL}
                  target={"_blank"}
                  className="btn hollow_white medium pill centered gmail_btn"
                >
                  {gmail_icon} Open Gmail
                </a>
              </div>
              <p
                className="blue_3_text text center_align cursor"
                style={{ marginTop: "12px" }}
                onClick={() => {
                  if (!signupResend)
                    handleLogin({
                      email: userLoginEmail,
                      resend: true,
                    });
                  else handleSignUp(signupResend);
                }}
              >
                {loginEmailResent ? "Email Resent!" : "Resend Email"}
              </p>
            </>
          )}
        </div>
      </HybridModal>

      {/* Account not found */}
      <HybridModal
        dark={true}
        open={accountNotFoundModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">Please Sign Up</h1>
          <p className="sub_heading semi medium manrope light_grey_text manrope center_align">
            Your account could not be found.
          </p>
          <Button
            type={"pill"}
            size={"medium"}
            color={"blue_bg_transparent"}
            text={"Sign Up"}
            centered={true}
            callback={() => {
              setAccountNotFoundModal(false);
              setSignUpModal(true);
            }}
          />
        </div>
      </HybridModal>

      {/* Request Submitted */}
      <HybridModal
        dark={true}
        open={requestSubmittedModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">Request Submitted</h1>
          <p className="sub_heading semi medium manrope light_grey_text manrope center_align">
            We are reviewing your request and will send you an email as soon as
            your account is approved.
          </p>
          <Input
            label={"confirm email"}
            transparentInputs={true}
            defaultValue={userLoginEmail}
            greyInputs
            disableInput={true}
          />
          <p
            className="text manrope small center_align"
            style={{ color: "#f6f6f6" }}
          >
            We will send your confirmation email to this address.
          </p>
        </div>
      </HybridModal>

      {/* Business Account exists */}
      <HybridModal
        dark={true}
        open={businessAccountExistsModal}
        opener={() => navigate("/login/business")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">
            A Business Exists With This Address
          </h1>
          <p className="sub_heading semi medium manrope light_grey_text manrope center_align">
            We have sent a join request to the business.
          </p>
          <Input
            label={"confirm email"}
            transparentInputs={true}
            defaultValue={userLoginEmail}
            disableInput={true}
          />
          <p
            className="text manrope small center_align"
            style={{ color: "#f6f6f6" }}
          >
            All communication will be sent to this email address.
          </p>
        </div>
      </HybridModal>
    </div>
  );
};

export default Signup;
