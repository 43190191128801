export default [
  { title: "Afghanistan", key: "AF" },
  { title: "Åland Islands", key: "AX" },
  { title: "Albania", key: "AL" },
  { title: "Algeria", key: "DZ" },
  { title: "American Samoa", key: "AS" },
  { title: "AndorrA", key: "AD" },
  { title: "Angola", key: "AO" },
  { title: "Anguilla", key: "AI" },
  { title: "Antigua and Barbuda", key: "AG" },
  { title: "Argentina", key: "AR" },
  { title: "Armenia", key: "AM" },
  { title: "Aruba", key: "AW" },
  { title: "Australia", key: "AU" },
  { title: "Austria", key: "AT" },
  { title: "Azerbaijan", key: "AZ" },
  { title: "Bahamas", key: "BS" },
  { title: "Bahrain", key: "BH" },
  { title: "Bangladesh", key: "BD" },
  { title: "Barbados", key: "BB" },
  { title: "Belarus", key: "BY" },
  { title: "Belgium", key: "BE" },
  { title: "Belize", key: "BZ" },
  { title: "Benin", key: "BJ" },
  { title: "Bermuda", key: "BM" },
  { title: "Bhutan", key: "BT" },
  { title: "Bolivia", key: "BO" },
  { title: "Bosnia and Herzegovina", key: "BA" },
  { title: "Botswana", key: "BW" },
  // { title: "Bouvet Island", key: "BV" },
  { title: "Brazil", key: "BR" },
  { title: "British Indian Ocean Territory", key: "IO" },
  { title: "Brunei Darussalam", key: "BN" },
  { title: "Bulgaria", key: "BG" },
  { title: "Burkina Faso", key: "BF" },
  { title: "Burundi", key: "BI" },
  { title: "Cambodia", key: "KH" },
  { title: "Cameroon", key: "CM" },
  { title: "Canada", key: "CA" },
  { title: "Cape Verde", key: "CV" },
  { title: "Cayman Islands", key: "KY" },
  { title: "Central African Republic", key: "CF" },
  { title: "Chad", key: "TD" },
  { title: "Chile", key: "CL" },
  { title: "China", key: "CN" },
  { title: "Christmas Island", key: "CX" },
  { title: "Cocos (Keeling) Islands", key: "CC" },
  { title: "Colombia", key: "CO" },
  { title: "Comoros", key: "KM" },
  { title: "Congo", key: "CG" },
  { title: "Congo, The Democratic Republic of the", key: "CD" },
  { title: "Cook Islands", key: "CK" },
  { title: "Costa Rica", key: "CR" },
  { title: "Cote D'Ivoire", key: "CI" },
  { title: "Croatia", key: "HR" },
  { title: "Cuba", key: "CU" },
  { title: "Cyprus", key: "CY" },
  { title: "Czech Republic", key: "CZ" },
  { title: "Denmark", key: "DK" },
  { title: "Djibouti", key: "DJ" },
  { title: "Dominica", key: "DM" },
  { title: "Dominican Republic", key: "DO" },
  { title: "Ecuador", key: "EC" },
  { title: "Egypt", key: "EG" },
  { title: "El Salvador", key: "SV" },
  { title: "Equatorial Guinea", key: "GQ" },
  { title: "Eritrea", key: "ER" },
  { title: "Estonia", key: "EE" },
  { title: "Ethiopia", key: "ET" },
  { title: "Falkland Islands (Malvinas)", key: "FK" },
  { title: "Faroe Islands", key: "FO" },
  { title: "Fiji", key: "FJ" },
  { title: "Finland", key: "FI" },
  { title: "France", key: "FR" },
  { title: "French Guiana", key: "GF" },
  { title: "French Polynesia", key: "PF" },
  // { title: "French Southern Territories", key: "TF" },
  { title: "Gabon", key: "GA" },
  { title: "Gambia", key: "GM" },
  { title: "Georgia", key: "GE" },
  { title: "Germany", key: "DE" },
  { title: "Ghana", key: "GH" },
  { title: "Gibraltar", key: "GI" },
  { title: "Greece", key: "GR" },
  { title: "Greenland", key: "GL" },
  { title: "Grenada", key: "GD" },
  { title: "Guadeloupe", key: "GP" },
  { title: "Guam", key: "GU" },
  { title: "Guatemala", key: "GT" },
  { title: "Guernsey", key: "GG" },
  { title: "Guinea", key: "GN" },
  { title: "Guinea-Bissau", key: "GW" },
  { title: "Guyana", key: "GY" },
  { title: "Haiti", key: "HT" },
  // { title: "Heard Island and Mcdonald Islands", key: "HM" },
  { title: "Holy See (Vatican City State)", key: "VA" },
  { title: "Honduras", key: "HN" },
  { title: "Hong Kong", key: "HK" },
  { title: "Hungary", key: "HU" },
  { title: "Iceland", key: "IS" },
  { title: "India", key: "IN" },
  { title: "Indonesia", key: "ID" },
  { title: "Iran, Islamic Republic Of", key: "IR" },
  { title: "Iraq", key: "IQ" },
  { title: "Ireland", key: "IE" },
  { title: "Isle of Man", key: "IM" },
  { title: "Israel", key: "IL" },
  { title: "Italy", key: "IT" },
  { title: "Jamaica", key: "JM" },
  { title: "Japan", key: "JP" },
  { title: "Jersey", key: "JE" },
  { title: "Jordan", key: "JO" },
  { title: "Kazakhstan", key: "KZ" },
  { title: "Kenya", key: "KE" },
  { title: "Kiribati", key: "KI" },
  { title: "Korea, Democratic People'S Republic of", key: "KP" },
  { title: "Korea, Republic of", key: "KR" },
  { title: "Kuwait", key: "KW" },
  { title: "Kyrgyzstan", key: "KG" },
  { title: "Lao People'S Democratic Republic", key: "LA" },
  { title: "Latvia", key: "LV" },
  { title: "Lebanon", key: "LB" },
  { title: "Lesotho", key: "LS" },
  { title: "Liberia", key: "LR" },
  { title: "Libyan Arab Jamahiriya", key: "LY" },
  { title: "Liechtenstein", key: "LI" },
  { title: "Lithuania", key: "LT" },
  { title: "Luxembourg", key: "LU" },
  { title: "Macao", key: "MO" },
  { title: "Macedonia, The Former Yugoslav Republic of", key: "MK" },
  { title: "Madagascar", key: "MG" },
  { title: "Malawi", key: "MW" },
  { title: "Malaysia", key: "MY" },
  { title: "Maldives", key: "MV" },
  { title: "Mali", key: "ML" },
  { title: "Malta", key: "MT" },
  { title: "Marshall Islands", key: "MH" },
  { title: "Martinique", key: "MQ" },
  { title: "Mauritania", key: "MR" },
  { title: "Mauritius", key: "MU" },
  { title: "Mayotte", key: "YT" },
  { title: "Mexico", key: "MX" },
  { title: "Micronesia, Federated States of", key: "FM" },
  { title: "Moldova, Republic of", key: "MD" },
  { title: "Monaco", key: "MC" },
  { title: "Mongolia", key: "MN" },
  { title: "Montserrat", key: "MS" },
  { title: "Morocco", key: "MA" },
  { title: "Mozambique", key: "MZ" },
  { title: "Myanmar", key: "MM" },
  { title: "Namibia", key: "NA" },
  { title: "Nauru", key: "NR" },
  { title: "Nepal", key: "NP" },
  { title: "Netherlands", key: "NL" },
  // { title: "Netherlands Antilles", key: "AN" },
  { title: "New Caledonia", key: "NC" },
  { title: "New Zealand", key: "NZ" },
  { title: "Nicaragua", key: "NI" },
  { title: "Niger", key: "NE" },
  { title: "Nigeria", key: "NG" },
  { title: "Niue", key: "NU" },
  { title: "Norfolk Island", key: "NF" },
  { title: "Northern Mariana Islands", key: "MP" },
  { title: "Norway", key: "NO" },
  { title: "Oman", key: "OM" },
  { title: "Pakistan", key: "PK" },
  { title: "Palau", key: "PW" },
  { title: "Palestinian Territory, Occupied", key: "PS" },
  { title: "Panama", key: "PA" },
  { title: "Papua New Guinea", key: "PG" },
  { title: "Paraguay", key: "PY" },
  { title: "Peru", key: "PE" },
  { title: "Philippines", key: "PH" },
  // { title: "Pitcairn", key: "PN" },
  { title: "Poland", key: "PL" },
  { title: "Portugal", key: "PT" },
  { title: "Puerto Rico", key: "PR" },
  { title: "Qatar", key: "QA" },
  { title: "Reunion", key: "RE" },
  { title: "Romania", key: "RO" },
  { title: "Russian Federation", key: "RU" },
  { title: "RWANDA", key: "RW" },
  { title: "Saint Helena", key: "SH" },
  { title: "Saint Kitts and Nevis", key: "KN" },
  { title: "Saint Lucia", key: "LC" },
  { title: "Saint Pierre and Miquelon", key: "PM" },
  { title: "Saint Vincent and the Grenadines", key: "VC" },
  { title: "Samoa", key: "WS" },
  { title: "San Marino", key: "SM" },
  { title: "Sao Tome and Principe", key: "ST" },
  { title: "Saudi Arabia", key: "SA" },
  { title: "Senegal", key: "SN" },
  // { title: "Serbia and Montenegro", key: "CS" },
  { title: "Seychelles", key: "SC" },
  { title: "Sierra Leone", key: "SL" },
  { title: "Singapore", key: "SG" },
  { title: "Slovakia", key: "SK" },
  { title: "Slovenia", key: "SI" },
  { title: "Solomon Islands", key: "SB" },
  { title: "Somalia", key: "SO" },
  { title: "South Africa", key: "ZA" },
  // { title: "South Georgia and the South Sandwich Islands", key: "GS" },
  { title: "Spain", key: "ES" },
  { title: "Sri Lanka", key: "LK" },
  { title: "Sudan", key: "SD" },
  { title: "Surititle", key: "SR" },
  { title: "Svalbard and Jan Mayen", key: "SJ" },
  { title: "Swaziland", key: "SZ" },
  { title: "Sweden", key: "SE" },
  { title: "Switzerland", key: "CH" },
  { title: "Syrian Arab Republic", key: "SY" },
  { title: "Taiwan, Province of China", key: "TW" },
  { title: "Tajikistan", key: "TJ" },
  { title: "Tanzania, United Republic of", key: "TZ" },
  { title: "Thailand", key: "TH" },
  { title: "Timor-Leste", key: "TL" },
  { title: "Togo", key: "TG" },
  { title: "Tokelau", key: "TK" },
  { title: "Tonga", key: "TO" },
  { title: "Trinidad and Tobago", key: "TT" },
  { title: "Tunisia", key: "TN" },
  { title: "Turkey", key: "TR" },
  { title: "Turkmenistan", key: "TM" },
  { title: "Turks and Caicos Islands", key: "TC" },
  { title: "Tuvalu", key: "TV" },
  { title: "Uganda", key: "UG" },
  { title: "Ukraine", key: "UA" },
  { title: "United Arab Emirates", key: "AE" },
  { title: "United Kingdom", key: "GB" },
  { title: "United States", key: "US" },
  // { title: "United States Minor Outlying Islands", key: "UM" },
  { title: "Uruguay", key: "UY" },
  { title: "Uzbekistan", key: "UZ" },
  { title: "Vanuatu", key: "VU" },
  { title: "Venezuela", key: "VE" },
  { title: "Viet Nam", key: "VN" },
  { title: "Virgin Islands, British", key: "VG" },
  { title: "Virgin Islands, U.S.", key: "VI" },
  { title: "Wallis and Futuna", key: "WF" },
  { title: "Western Sahara", key: "EH" },
  { title: "Yemen", key: "YE" },
  { title: "Zambia", key: "ZM" },
  { title: "Zimbabwe", key: "ZW" },
];
