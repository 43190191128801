import {
  checkLoginCookie,
  setLoginCookie,
  logout,
} from "../helpers/authCookies";
import { buildBasicLoader } from "../loaders/basic";

import {
  base_url,
  b2b_signin_url,
  b2b_signup_url,
  b2b_magic_login_url,
  b2b_get_user_info,
  AWS_DEV_API_BASE,
  getSubscriptionsData,
} from "../base";

export const loginOrSignUp = (email, fail, success) => {
  const destroy = buildBasicLoader();
  fetch(base_url + b2b_signin_url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      destroy();

      if (!data || data?.statusCode !== 200) {
        fail();
      } else {
        console.log("Successfully logged in");
        success(email);
      }
    });
};

export const LoginWithToken = (token) => {
  const destroy = buildBasicLoader();
  fetch(base_url + b2b_magic_login_url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ magic_token: token }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.message || data?.code) {
        destroy();
        return;
      }
      setLoginCookie(data);
      destroy();
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error(error);
      destroy();
    });
};

export const getUserDataWithAuthToken = (
  navigate = null,
  setLoggedData = null
) => {
  const destroy = buildBasicLoader();

  const authToken = checkLoginCookie();
  if (!authToken) {
    destroy();
    return;
  }

  fetch(AWS_DEV_API_BASE + getSubscriptionsData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      destroy();

      console.log(data);

      if (setLoggedData) setLoggedData({ ...data, user: data.user[0] });

      if (navigate) {
        navigate("/my-btlr");
      }
    })
    .catch((error) => {
      console.error(error);
      destroy();
    });
};

export const signUp = (formData, fail) => {
  const destroy = buildBasicLoader();

  fetch(base_url + b2b_signup_url, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      destroy();

      if (!data || data?.message || data?.code) {
        fail();
      } else {
        console.log("Successfully Signed up");
      }
    });
};
