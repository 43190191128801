import React, { useState, useEffect } from "react";

import Input from "../input/input";
import PillsInput from "../pillsInput/pillsInput";

import "./form.scss";

import { right_arrow_icon } from "../../../assets/svgs/icons";

const Form = ({
  inputs,
  submit_text = "Submit",
  custom_submit = false,
  custom_submit_2 = false,
  right_arrow_submit = true,
  auto_width_submit = false,
  submitForm = null,
  prefilledValues = {},
  transparentInputs,
  greyInputs,
  formClass = "",
  continuousSubmit = false,
  children,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (continuousSubmit) continuousSubmit(values);

    let disableForm = false;

    const parseInputs = inputs.map((inp) => {
      if (inp?.notRequired || inp.type === "heading") {
        return { ...inp, required: false };
      } else {
        return { ...inp, required: true };
      }
    });

    // Filtered inputs that are required
    const filteredInputs = parseInputs.filter((inp) => inp.required);

    // If not required fields in form
    if (filteredInputs.length === 0) {
      setDisabled(false);
      return;
    }

    const allInputsNames = Object.keys(values);

    if (allInputsNames.length < filteredInputs.length) return;

    for (let i = 0; i < allInputsNames.length; i++) {
      if (!values[allInputsNames[i]]) {
        disableForm = true;
        break;
      }
    }

    setDisabled(disableForm);
  }, [values]);

  useEffect(() => {
    if (Object.keys(prefilledValues).length === 0) return;
    const temp = { ...values };
    Object.keys(prefilledValues).forEach((prefilledValue) => {
      if (prefilledValue && !temp[prefilledValue])
        temp[prefilledValue] = prefilledValues[prefilledValue];
    });
    setValues(temp);
  }, [prefilledValues]);

  const handleSubmit = (e) => {
    e.preventDefault();

    submitForm && submitForm(values);
  };

  return (
    <form className={`form_body ${formClass}`} onSubmit={handleSubmit}>
      {inputs.map(
        ({ name, label, type, options, filterOptions, ...rest }, index) => {
          if (type === "pills_input")
            return <PillsInput key={index} label={label} />;
          // Heading in mid
          else if (type === "heading")
            return (
              <p key={index} className="info_small_heading black_text">
                {label}
              </p>
            );
          // Normal Ones
          else
            return (
              <Input
                key={index}
                name={name}
                label={label}
                type={type}
                options={options}
                filterOptions={filterOptions}
                defaultValue={
                  prefilledValues[name] ? prefilledValues[name] : ""
                }
                validationCallback={(filled) => {
                  const tempValues = { ...values };
                  tempValues[name] = filled;
                  if (tempValues.hasOwnProperty(name) && !filled) {
                    delete tempValues[name];
                  }
                  setValues(tempValues);
                }}
                transparentInputs={transparentInputs}
                greyInputs={greyInputs}
                {...rest}
              />
            );
        }
      )}
      {children}
      {!custom_submit && (
        <button type="submit" value={submit_text} disabled={disabled}>
          {submit_text}
        </button>
      )}
      {custom_submit && (
        <div className="align_right">
          <button
            type="submit"
            disabled={disabled}
            className={`custom_submit ${
              custom_submit_2 ? "custom_submit_2" : ""
            }`}
            style={{ width: auto_width_submit ? "auto" : "" }}
          >
            {submit_text}{" "}
            {right_arrow_submit && <span>{right_arrow_icon}</span>}
          </button>
        </div>
      )}
    </form>
  );
};
export default Form;
