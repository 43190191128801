import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";

import "./cookie.scss";
import Button from "../button/button";

const CookieBanner = () => {
  const [bannerOpen, setBannerOpen] = useState(false);

  const acceptCookies = () => {
    Cookies.set("btlrCookiesAccept", true, { expires: 999 });
    setBannerOpen(false);
    ReactGA.initialize("UA-162422479-1");
  };

  const closeBanner = () => {
    setBannerOpen(false);
  };

  useEffect(() => {
    if (!navigator.cookieEnabled) return;

    if (!Cookies.get("btlrCookiesAccept")) {
      setBannerOpen(true);
    } else {
      ReactGA.initialize("UA-162422479-1");
    }
  }, []);

  return (
    <div className={`cookie ${bannerOpen ? "open" : ""}`}>
      <p>
        By clicking “Accept”, you agree to the storing of cookies on your device
        to enhance site navigation, analyze site usage. View our{" "}
        <a href="/privacy" target={"_blank"}>
          Privacy Policy{" "}
        </a>
        for more information.
      </p>
      <div className="cookie-mobile-btns">
        <Button
          type={"hollow"}
          color={"hollow_white"}
          size={"small"}
          text={"Deny"}
          callback={closeBanner}
        />
        <Button
          type={"pill"}
          color={"blue"}
          size={"small"}
          text={"Accept"}
          callback={acceptCookies}
        />
      </div>
    </div>
  );
};

export default CookieBanner;
