import React, { useState, useEffect, useContext } from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../../../contexts/btlrBeta.context";

import HybridModal from "../../../../components/common/hybridModal/hybridModal";
import Form from "../../../../components/common/form/form";
import Input from "../../../../components/common/input/input";
import Button from "../../../../components/common/button/button";
import ConfirmationModal from "../../../../components/common/confirmationModal/confirmationModal";

import { sharePreferenceInputs } from "../data.service";

import { close_bg_grey } from "../../../../assets/svgs/action_icons";
import { copy_chain_blue_svg } from "../../../../assets/svgs/icons";

import "./styles.scss";

const SharePreferencesModal = ({
  type = "black",
  businessId,
  businessName,
  admin_id,
  adminAccess,
  getBusinessMembersBeta,
  inviteBusinessMemberBeta,
  betaBusinessAcceptMember,
  betaBusinessRejectMember,
  betaBusinessUpdateMemberRole,
  showUserDetailsForm = () => {},
  authToken,
}) => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [teamMembers, setTeamMembers] = useState([]);
  const [memberRoleUpdate, setMemberRoleUpdate] = useState();

  const { sharePreferenceModal } = BTLRBeta;

  const refreshTeamMembers = () => {
    getBusinessMembersBeta(
      (data) => {
        setTeamMembers(data);

        // Check if pending invite
        try {
          data.forEach((tm) => {
            if (tm?.accessType === "ACCESS_REQUESTED") {
              setBTLRBeta({ ...BTLRBeta, pendingInvite: true });
            }
          });
        } catch (error) {
          console.error(error);
        }
      },
      authToken,
      businessId
    );
  };

  const inviteTeamMemberSubmit = (values) => {
    inviteBusinessMemberBeta(
      values,
      () => {
        refreshTeamMembers();
      },
      authToken,
      businessId
    );
  };

  useEffect(() => {
    refreshTeamMembers();
  }, [businessId]);

  return (
    <div className={`preference_share_modal ${type}`}>
      <HybridModal
        open={sharePreferenceModal}
        opener={() => setBTLRBeta({ ...BTLRBeta, sharePreferenceModal: false })}
        backgroundColor={type === "black" ? "#000000" : "#ffffff"}
        dark={type === "black" ? true : false}
        blur
      >
        <div className={`beta_modal_top_area ${type}_bg`}>
          <h1 className="heading basier" style={{ marginBottom: 0 }}>
            {businessName ? businessName : "Share"}
          </h1>
          <button
            className="close_thin"
            onClick={() =>
              setBTLRBeta({ ...BTLRBeta, sharePreferenceModal: false })
            }
          >
            {type === "black" ? close_bg_grey : close_bg_grey}
          </button>
        </div>
        <div className="share_form">
          <Form
            inputs={sharePreferenceInputs}
            submit_text="Share"
            transparentInputs={type === "black"}
            submitForm={inviteTeamMemberSubmit}
          />
        </div>

        <p className="text light_grey_text">Share invite with a guest.</p>
        <p
          className="text blue_text copy_invite_link"
          onClick={() => {
            console.log(
              `https://www.beta.btlr.vip/personal/edit-preferences?b_id=${businessId}`
            );
            copy(
              `https://www.beta.btlr.vip/personal/edit-preferences?b_id=${businessId}`,
              {
                debug: true,
                message: "Press #{key} to copy",
              }
            );
            toast.success("Link copied!");
          }}
        >
          {copy_chain_blue_svg} Copy Guest Invite Link
        </p>

        <div className="team_members_list">
          {teamMembers.map(
            (
              {
                user: { email, firstName, lastName, _id },
                accessType,
                pendingInvite,
                active,
              },
              index
            ) => {
              return (
                <div key={index} className="team_member">
                  <div
                    className="left"
                    style={{ cursor: "pointer" }}
                    onClick={() => showUserDetailsForm(_id)}
                  >
                    <span className="user_icon medium">
                      {firstName ? firstName.charAt(0) : email.charAt(0)}
                    </span>
                    <span>
                      <p className="sub_heading medium white_text manrope">
                        {firstName && lastName
                          ? firstName + " " + lastName
                          : email}
                      </p>

                      <p className="text light_grey_text">
                        {accessType === "ACCESS_REQUESTED"
                          ? "New Member"
                          : accessType}
                      </p>
                    </span>
                  </div>
                  {adminAccess && (
                    <div className="right">
                      {accessType === "ACCESS_REQUESTED" && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "6px",
                            flexWrap: "wrap",
                          }}
                        >
                          <Button
                            text="Decline"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"red"}
                            callback={() => {
                              betaBusinessRejectMember(
                                _id,
                                () => {
                                  refreshTeamMembers();
                                },
                                authToken,
                                businessId
                              );
                            }}
                          />
                          <Button
                            text="Accept"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"blue_bg_effect"}
                            callback={() => {
                              betaBusinessAcceptMember(
                                _id,
                                () => {
                                  refreshTeamMembers();
                                },
                                authToken,
                                businessId
                              );
                            }}
                          />
                        </div>
                      )}
                      {pendingInvite && (
                        <div style={{ display: "flex", gap: "6px" }}>
                          <Button
                            text="Resend Invite"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"blue_bg_effect"}
                            callback={() =>
                              inviteTeamMemberSubmit({
                                email,
                                accessType,
                              })
                            }
                          />
                          <Button
                            text="Delete Invite"
                            type={"roundedSquare"}
                            size={"medium"}
                            color={"red"}
                            callback={() =>
                              setMemberRoleUpdate({
                                _id,
                                firstName,
                                lastName,
                                email,
                              })
                            }
                          />
                        </div>
                      )}
                      {active && (
                        <Input
                          type="select"
                          defaultValue={accessType}
                          label={"type"}
                          disableInput={
                            admin_id !== "admin" && _id === admin_id
                          }
                          options={[
                            { key: "MEMBER", title: "member" },
                            { key: "ADMIN", title: "admin" },
                            { key: "DELETE", title: "delete" },
                          ]}
                          transparentInputs={type === "black"}
                          validationCallback={(value) => {
                            if (admin_id !== "admin" && _id === admin_id)
                              return; // You cannot change your own role

                            if (value === "DELETE") {
                              setMemberRoleUpdate({
                                _id,
                                firstName,
                                lastName,
                                email,
                              });
                              return;
                            }

                            betaBusinessUpdateMemberRole(
                              _id,
                              value,
                              () => {
                                refreshTeamMembers();
                              },
                              authToken,
                              businessId
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      </HybridModal>

      {/* To confirm deletion of member */}
      <ConfirmationModal
        open={memberRoleUpdate}
        opener={setMemberRoleUpdate}
        title={`Remove ${
          memberRoleUpdate?.firstName && memberRoleUpdate?.lastName
            ? memberRoleUpdate?.firstName + " " + memberRoleUpdate?.lastName
            : memberRoleUpdate?.email
        }?`}
        info={
          "Confirm you want to remove this team member from your business? This action cannot be undone."
        }
        confirmButtonText={"Remove"}
        cancelButtonText={"Cancel"}
        confirmButtonColor={"dark_red"}
        confirmButtonCallback={() => {
          if (!memberRoleUpdate?._id) return;
          betaBusinessUpdateMemberRole(
            memberRoleUpdate?._id,
            "DELETE",
            () => {
              refreshTeamMembers();
            },
            authToken,
            businessId
          );
        }}
      />
    </div>
  );
};

export default SharePreferencesModal;
