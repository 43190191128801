import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import QRCodeStyling from "qr-code-styling";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";
import BetaContainer from "../betaContainer";
import Button from "../../../components/common/button/button";
import HybridModal from "../../../components/common/hybridModal/hybridModal";
import Input from "../../../components/common/input/input";
import ConfirmationModal from "../../../components/common/confirmationModal/confirmationModal";
import { buildBasicLoader } from "../../../api/loaders/basic";

import btlr_logo_white from "../../../assets/images/global/logo_white.svg";
import {
  grey_qr_icon,
  share_icon_blue_svg,
  add_blue_bg,
  copy_chain_svg,
  black_add_svg,
  dark_right_arrow_circle_svg,
  copy_chain_blue_svg,
  search_magnifier_white_svg,
  white_calendar_icon,
} from "../../../assets/svgs/icons";
import {
  close_thin,
  right_arrow,
  add_blue_without_circle,
  go_back_black,
} from "../../../assets/svgs/action_icons";

import {
  BTLR_BID,
  BTLR_BETA_API_BASE,
  getPlacesPredictionsURL,
  removeBusinessAccess,
} from "../../../api/base";
import { getPlaceDetails } from "../../b2b/signup/signup.service";
import { occasions } from "./service";
import { getUserDataBeta, updateUserDataBeta } from "../account/service";
import {
  getBusinessDetailsById,
  saveInvitedBusinessForLoggedInUser,
} from "./service";
import { checkLoginCookieBeta } from "../../../api/helpers/authCookies";
import { shareBTLRNavigator } from "../b2cUtil";
import { checkIfDate1IsTodayOrInFuture } from "../../../assets/scripts/utility";
import "./styles.scss";

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  backgroundOptions: { color: "transparent" },
  image: btlr_logo_white,
  dotsOptions: {
    color: "#ffffff",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
});

const SharePreferences = () => {
  const { show } = useParams();
  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [sharedBusinesses, setSharedBusinesses] = useState([]);
  const [sharedBusinessesTimeline, setSharedBusinessesTimeline] = useState([]);
  const [sharedBusinessesNonTimeline, setSharedBusinessesNonTimeline] =
    useState([]);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [addNewBusiness, setAddNewBusiness] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [searchForLocation, setSearchForLocation] = useState(false);
  const [mapsPredictions, setMapsPredictions] = useState([]);
  const [searchedLocation, setSearchedLocation] = useState("");
  const [businessEmailValue, setBusinessEmailValue] = useState("");
  const [businessOccasionInputOpen, setBusinessOccasionInputOpen] =
    useState(false);
  const [
    openOccassionAndArrivalInputsDesktop,
    setOpenOccassionAndArrivalInputsDesktop,
  ] = useState(false);
  const [occasionValue, setOccasionValue] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [businessArrivalDateInputOpen, setBusinessArrivalDateInputOpen] =
    useState(false);
  const [arrivalDateValue, setArrivalDateValue] = useState(undefined);
  const [arrivalTimeValue, setArrivalTimeValue] = useState({});
  const [disableAccessForBusiness, setDisableAccessForBusiness] = useState();
  const [confirmPreferencesPopup, setConfirmPreferencesPopup] = useState(false);
  const [confirmShareWithInvitedBusiness, setConfirmShareWithInvitedBusiness] =
    useState(false);
  const [showBusinessShareHistory, setShowBusinessShareHistory] = useState();

  const qrCodeRef = useRef(null);
  const businessNameInputRef = useRef(null);

  let controller;
  let signal;

  const saveUserDataForBusinesses = (newBusinessesData) => {
    // if (!BTLRBeta?.loggedIn) return;
    // Saving it online
    const updateObject = {
      ...BTLRBeta,
      businesses: newBusinessesData,
    };
    setBTLRBeta(updateObject);
    if (BTLRBeta?.loggedIn)
      updateUserDataBeta(
        "business",
        { businesses: newBusinessesData },
        BTLRBeta.loginToken,
        true,
        () => {
          getUserDataBeta(setBTLRBeta, BTLRBeta.loginToken);
        }
      );
  };

  const searchForLocationOnMaps = (searchText) => {
    if (!searchText) return;

    // Using abort controller so only last keystroke request gets processed
    controller?.abort();
    controller = new AbortController();
    signal = controller.signal;

    fetch(`${BTLR_BETA_API_BASE}${getPlacesPredictionsURL}${searchText}`, {
      method: "get",
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, predictions } = data;

        if (status === "OK") {
          setMapsPredictions(predictions);
        } else {
          console.error("Error while searching Place on Google Maps");
        }
      })
      .catch((error) => {
        console.error("Error while searching Place on Google Maps", error);
      });
  };

  const saveBusiness = async () => {
    const businessData = {
      occasion: "",
      arrivalDate: "",
      arrivalTime: {},
      title: "",
      googlePlaceID: "",
      lat: "",
      long: "",
      website: "",
      phoneNumber: "",
      email: "",
    };

    if (!businessEmailValue && !searchedLocation) {
      toast.error("You must enter buisness email or location first");
      return;
    }

    const oneDayBefore = new Date();

    let parsedTimeStringWithoutTimezone;
    try {
      // Convert to zero timezone
      const inputDate = new Date(arrivalDateValue);
      console.log("Valid date?", inputDate);
      if (isNaN(inputDate)) {
        parsedTimeStringWithoutTimezone = undefined;
      } else {
        const day = inputDate.getDate();
        const month = inputDate.getMonth() + 1;
        const year = inputDate.getFullYear();
        const datePart = `${year}-${month < 10 ? `0${month}` : month}-${
          day < 10 ? `0${day}` : day
        }`;
        const hour = arrivalTimeValue?.hour.split(":")[0];
        const minute =
          arrivalTimeValue?.minute < 10
            ? `0${arrivalTimeValue?.minute}`
            : arrivalTimeValue?.minute;

        parsedTimeStringWithoutTimezone = new Date(
          `${datePart}T${hour}:${minute}:00.000`
        )
          ? `${datePart}T${hour}:${minute}:00.000`
          : undefined;
      }
    } catch (error) {
      console.log(error);
      parsedTimeStringWithoutTimezone = !isNaN(new Date(arrivalDateValue))
        ? arrivalDateValue
        : undefined;
    }

    if (arrivalDateValue || arrivalTimeValue?.hour) {
      if (
        !arrivalDateValue ||
        !checkIfDate1IsTodayOrInFuture(arrivalDateValue, oneDayBefore)
      ) {
        toast.error("You must select arrival date in future");
        return;
      }

      if (!arrivalTimeValue?.hour) {
        toast.error("Your must select an arrival time as well");
        return;
      }
    }

    if (searchedLocation?.place_id) {
      const destroyLoader = buildBasicLoader();
      try {
        await getPlaceDetails(searchedLocation?.place_id)
          .then((response) => response.json())
          .then((data) => {
            const { result, status } = data;

            if (status === "OK") {
              const { formatted_phone_number, website, geometry } = result;
              const { location } = geometry;
              businessData.address = searchedLocation.title;
              businessData.businessName =
                searchedLocation?.structured_formatting?.main_text;
              businessData.googlePlaceID = searchedLocation?.place_id;
              businessData.lat = location.lat.toString();
              businessData.long = location.lng.toString();
              businessData.website = website;
              businessData.phoneNumber = formatted_phone_number;
              businessData.occasion = occasionValue;
              businessData.arrivalDate = parsedTimeStringWithoutTimezone;
              businessData.arrivalTime = arrivalTimeValue;

              // Add to businesses list
              const duplicateIndex = sharedBusinesses.findIndex(
                (sb) => sb.business.googlePlaceID === searchedLocation.place_id
              );

              if (duplicateIndex < 0) {
                const updateData = [
                  ...sharedBusinesses,
                  { business: businessData },
                ];
                setSharedBusinesses(updateData);
                saveUserDataForBusinesses(updateData);
              } else {
                const tempSB = [...sharedBusinesses];
                tempSB[duplicateIndex] = {
                  ...tempSB[duplicateIndex],
                  business: {
                    ...tempSB[duplicateIndex].business,
                    ...businessData,
                  },
                };
                setSharedBusinesses(tempSB);
                saveUserDataForBusinesses(tempSB);
              }
            }
          });
        destroyLoader();
      } catch (error) {
        destroyLoader();
        console.error("Unable to fetch place details", error);
      }
    } else {
      businessData.occasion = occasionValue;
      businessData.arrivalDate = parsedTimeStringWithoutTimezone;
      businessData.arrivalTime = arrivalTimeValue;
      businessData.email = businessEmailValue;

      // Add to businesses list
      const duplicateIndex = sharedBusinesses.findIndex(
        (sb) => sb.business.email === businessEmailValue
      );
      if (duplicateIndex < 0) {
        const updateData = [...sharedBusinesses, { business: businessData }];
        setSharedBusinesses(updateData);
        saveUserDataForBusinesses(updateData);
      } else {
        const tempSB = [...sharedBusinesses];
        tempSB[duplicateIndex] = {
          ...tempSB[duplicateIndex],
          business: {
            ...tempSB[duplicateIndex].business,
            ...businessData,
          },
        };
        setSharedBusinesses(tempSB);
        saveUserDataForBusinesses(tempSB);
      }
    }

    setSearchedText("");
    setAddNewBusiness(false);
    setSearchForLocation(false);
    setBusinessOccasionInputOpen(false);
    setBusinessArrivalDateInputOpen(false);
    setOpenOccassionAndArrivalInputsDesktop(false);

    setSearchedLocation("");
    setArrivalDateValue({});
    setArrivalTimeValue();
    setOccasionValue();
  };

  useEffect(() => {
    if (show) {
      if (!BTLRBeta?.loggedIn) setIncompleteProfile(true);
      else setShowQRCode(true);
    }
  }, [show]);

  useEffect(() => {
    if (BTLRBeta?.businesses) {
      let temp = [];
      let tempWithoutArrivalDate = [];
      BTLRBeta?.businesses?.forEach((b) => {
        if (!b?.business?.visits) return;

        let allVistsOfBusiness = [...b?.business?.visits];

        allVistsOfBusiness.forEach((sv) => {
          if (!sv?.arrivalDate) {
            tempWithoutArrivalDate.push({ ...sv, ...b?.business });
          } else temp.push({ ...sv, ...b?.business });
        });
      });

      temp = temp.sort((a, b) => {
        return (
          new Date(a?.arrivalDate).getTime() -
          new Date(b?.arrivalDate).getTime()
        );
      });

      setSharedBusinesses(BTLRBeta?.businesses);
      setSharedBusinessesTimeline(temp);
      setSharedBusinessesNonTimeline(tempWithoutArrivalDate);
    }
    if (BTLRBeta?._id) {
      qrCode.update({
        data: `https://beta.btlr.vip/business/guests/${BTLRBeta?._id}`,
      });
    }
  }, [BTLRBeta]);

  useEffect(() => {
    const loginToken = checkLoginCookieBeta() ?? BTLRBeta.loginToken;

    if (loginToken && !BTLRBeta?.loggedIn) {
      try {
        getUserDataBeta(setBTLRBeta, BTLRBeta.loginToken);
      } catch (error) {
        console.error("error with user data", error);
      }
    }
  }, []);

  useEffect(() => {
    try {
      qrCodeRef.current.innerHTML = "";
      qrCode.append(qrCodeRef.current);
    } catch (error) {
      qrCode.append(qrCodeRef.current);
    }
  }, []);

  useEffect(() => {
    if (!BTLRBeta?.loggedIn) {
      setBTLRBeta({ ...BTLRBeta, b2cNavGoBack: `/personal/edit-preferences` });
    }
  }, [BTLRBeta?.loggedIn]);

  const disableBusinessPreference = () => {
    fetch(`${BTLR_BETA_API_BASE}${removeBusinessAccess}/${businessId}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken
        }`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status) {
          toast.success(data?.message ?? "Email address updated");
          getUserDataBeta(setBTLRBeta, BTLRBeta.loginToken);
          setSearchedLocation(null);
          setBusinessEmailValue("");
          setOccasionValue("");
          setBusinessId("");
          setArrivalDateValue(undefined);
          setArrivalTimeValue({});
          setAddNewBusiness(false);
          setShowBusinessShareHistory(null);
        } else {
          toast.error(data?.message ?? "Email update failed");
          // setBTLRBeta({ ...BTLRBeta, internalPagesUnlocked: false });
        }
      });
  };

  return (
    <BetaContainer>
      <div
        className={`inner_container inner_container_beta ${
          addNewBusiness ? "two_column" : ""
        }`}
      >
        <div className="left">
          {BTLRBeta?.loggedIn && (
            <div className="top_right_button">
              <Button
                type="pill"
                color="blue"
                size="small"
                extraClass="flex"
                callback={() => {
                  setSearchedLocation(null);
                  setBusinessEmailValue("");
                  setOccasionValue("");
                  setBusinessId("");
                  setArrivalDateValue(undefined);
                  setArrivalTimeValue({});
                  setAddNewBusiness(true);
                }}
              >
                {add_blue_without_circle} New
              </Button>
            </div>
          )}
          {!BTLRBeta?.loggedIn && (
            <div className="top_right_button left_right">
              <button
                className="nav_go_back logged_in"
                onClick={() => navigate(-1)}
              >
                {go_back_black}
              </button>
              <Button
                type="pill"
                color="blue"
                size="small"
                extraClass="flex"
                callback={() => navigate("/personal/account")}
              >
                {!BTLRBeta?.loggedIn && <>Next {right_arrow}</>}
              </Button>
            </div>
          )}
          {BTLRBeta?.loggedIn && (
            <button
              className="cursor qr_code_button"
              onClick={() => {
                if (!BTLRBeta?.loggedIn) setIncompleteProfile(true);
                else setShowQRCode(true);
              }}
            >
              {grey_qr_icon}
            </button>
          )}
          {!BTLRBeta?.businessPreview && (
            <>
              <h1 className="heading basier center_align">
                <span style={{ textTransform: "capitalize" }}>
                  Share
                  <br />
                  your preferences
                </span>
              </h1>
              <p
                className="text center_align"
                style={{ color: "rgba(255, 255, 255, 0.80" }}
              >
                Share your preferences with businesses directly or use your
                share link to personalise your experience.
              </p>
            </>
          )}
          {BTLRBeta?.businessPreview && (
            <>
              <h1 className="heading basier center_align">Make it special</h1>
              <p
                className="text center_align"
                style={{ color: "rgba(255, 255, 255, 0.80" }}
              >
                Select an occasion and arrival date.
              </p>
            </>
          )}
          {BTLRBeta?.loggedIn && (
            <Button
              type="pill"
              size="medium"
              color="blue_bg_effect"
              centered={true}
              callback={() => {
                if (!BTLRBeta?.loggedIn) setIncompleteProfile(true);
                else shareBTLRNavigator();
              }}
            >
              {copy_chain_svg} Preference link
            </Button>
          )}

          {!BTLRBeta?.businessPreview && (
            <>
              <span className="vertical_space"></span>
              {sharedBusinesses.length === 0 && (
                <Button
                  text="Share my preference now"
                  type={"pill"}
                  color={"white"}
                  size={"medium"}
                  extraClass={"add_new_business_share_btn flex"}
                  callback={() => {
                    setSearchedLocation(null);
                    setBusinessEmailValue("");
                    setOccasionValue("");
                    setBusinessId("");
                    setArrivalDateValue(undefined);
                    setArrivalTimeValue({});
                    setAddNewBusiness(true);
                  }}
                >
                  {black_add_svg}
                </Button>
              )}

              {/* Timeline display Upcoming */}
              {BTLRBeta?.loggedIn && sharedBusinessesTimeline.length > 0 && (
                <>
                  <p
                    className="text smallest light_grey_text"
                    style={{ marginBottom: "6px", marginTop: "20px" }}
                  >
                    Upcoming
                  </p>
                  <div className="preferences_display shared_business_timeline">
                    <div className="shared_businesses">
                      {sharedBusinessesTimeline.map((sb, index) => {
                        if (!sb?.businessName && !sb?.email) return;

                        const show = new Date(sb?.arrivalDate);
                        if (show < new Date()) return;

                        const formattedDate = sb?.arrivalDate
                          ? format(
                              new Date(sb?.arrivalDate?.replace("Z", "")),
                              "dd.MM.yyyy"
                            )
                          : "";

                        return (
                          <div
                            key={index}
                            className="cursor single_shared_business"
                            onClick={() => {
                              setBusinessId(sb?._id);
                              setShowBusinessShareHistory({
                                ...showBusinessShareHistory,
                                business: sb,
                                show: true,
                              });
                            }}
                          >
                            <div className="single_shared_business_left_side">
                              <p className="business_name">
                                {sb?.businessName
                                  ? sb?.businessName
                                  : sb?.email}
                              </p>
                              <p className="business_participants">
                                {sb?.address} {sb?.address && "|"}{" "}
                                <span className="blue_text">
                                  {formattedDate}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {/* Timeline display History */}
              {BTLRBeta?.loggedIn && sharedBusinessesTimeline.length > 0 && (
                <>
                  <p
                    className="text smallest light_grey_text"
                    style={{ marginBottom: "6px", marginTop: "20px" }}
                  >
                    History
                  </p>
                  <div className="preferences_display shared_business_timeline">
                    <div className="shared_businesses">
                      {sharedBusinessesTimeline.map((sb, index) => {
                        if (!sb?.businessName && !sb?.email) return;

                        const show = new Date(sb?.arrivalDate);
                        if (show >= new Date()) return;

                        const formattedDate = sb?.arrivalDate
                          ? format(
                              new Date(sb?.arrivalDate?.replace("Z", "")),
                              "dd.MM.yyyy"
                            )
                          : "";

                        return (
                          <div
                            key={index}
                            className="cursor single_shared_business"
                            onClick={() => {
                              setBusinessId(sb?._id);
                              setShowBusinessShareHistory({
                                ...showBusinessShareHistory,
                                business: sb,
                                show: true,
                              });
                            }}
                          >
                            <div className="single_shared_business_left_side">
                              <p className="business_name">
                                {sb?.businessName
                                  ? sb?.businessName
                                  : sb?.email}
                              </p>
                              <p className="business_participants">
                                {sb?.address} {sb?.address && "|"}{" "}
                                <span>{formattedDate}</span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {/* Timeline without time display */}
              {BTLRBeta?.loggedIn && sharedBusinessesNonTimeline.length > 0 && (
                <>
                  <p
                    className="text smallest light_grey_text"
                    style={{ marginBottom: "6px", marginTop: "20px" }}
                  >
                    Without Arrival Date
                  </p>
                  <div className="preferences_display shared_business_timeline">
                    <div className="shared_businesses">
                      {sharedBusinessesNonTimeline.map((sb, index) => {
                        if (!sb?.businessName && !sb?.email) return;

                        return (
                          <div
                            key={index}
                            className="cursor single_shared_business"
                            onClick={() => {
                              setBusinessId(sb?._id);
                              setShowBusinessShareHistory({
                                ...showBusinessShareHistory,
                                business: {
                                  ...sb,
                                  businessName: sb?.businessName ?? sb?.email,
                                },
                                show: true,
                              });
                            }}
                          >
                            <div className="single_shared_business_left_side">
                              <p className="business_name">
                                {sb?.businessName
                                  ? sb?.businessName
                                  : sb?.email}
                              </p>
                              <p className="business_participants">
                                {sb?.address}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {/* Share preferenes list for first time signup users or business preview users */}
          {BTLRBeta?.businessPreview?.pageData?.enableSharePage && (
            <>
              {/* Non BTLR Business */}
              {BTLRBeta?.businessPreview?.pageData?.business !== BTLR_BID && (
                <>
                  <span className="vertical_space"></span>
                  <Input
                    name={"occasion"}
                    type={
                      window.innerWidth < 450 ? "selectWithPopup" : "select"
                    }
                    options={occasions}
                    label={"🎉 Occasion"}
                    defaultValue={occasionValue || BTLRBeta?.occasion || ""}
                    validationCallback={(value) => {
                      setOccasionValue(value);
                      setBTLRBeta({ ...BTLRBeta, occasion: value });
                    }}
                    selectPopupAutoOpenOptions={businessOccasionInputOpen}
                    // selectPopupNextButtonText={"Skip"}
                    // selectPopupNextAction={() =>
                    //   setBusinessArrivalDateInputOpen(true)
                    // }
                    greyInputs={true}
                  />

                  <Input
                    name={"occastion_date"}
                    type="date"
                    label={"Arrival Date (DD.MM.YYYY)"}
                    defaultValue={
                      arrivalDateValue || BTLRBeta?.arrivalDate || ""
                    }
                    validationCallback={(value) => {
                      setArrivalDateValue(value);
                      setBTLRBeta({ ...BTLRBeta, arrivalDate: value });
                    }}
                    selectPopupAutoOpenOptions={businessArrivalDateInputOpen}
                    selectPopupBackAction={() => {
                      setBusinessArrivalDateInputOpen(false);
                    }}
                    selectPopupNextButtonText={"Save"}
                    selectPopupNextAction={() => {
                      saveBusiness();
                    }}
                    greyInputs={true}
                  />

                  <Input
                    name={"occastion_time"}
                    type="time"
                    label={"Arrival Time"}
                    defaultValue={arrivalTimeValue}
                    validationCallback={(value) => {
                      setArrivalTimeValue(value);
                    }}
                    greyInputs={true}
                  />
                </>
              )}

              {/* BTLR Business  */}
              {BTLRBeta?.businessPreview?.pageData?.business === BTLR_BID && (
                <div className="preferences_display">
                  <div className="preferences_options_header">
                    <span>
                      <p className="pref_heading">Share</p>
                      <p className="pref_subheading">Preferences</p>
                    </span>
                    <p
                      className="cursor text blue_text add_new_preference"
                      onClick={() => {
                        setSearchedLocation("");
                        setBusinessEmailValue("");
                        setOccasionValue("");
                        setBusinessId("");
                        setArrivalDateValue(undefined);
                        setArrivalTimeValue({});
                        setAddNewBusiness(true);
                      }}
                    >
                      {add_blue_bg}
                      Add
                    </p>
                  </div>

                  <div className="shared_businesses">
                    <p className="pref_subheading">All</p>
                    {sharedBusinesses.map((sb, index) => {
                      if (sb?.business?._id === BTLR_BID) return;
                      if (!sb?.business?.businessName || sb?.business?.email)
                        return;

                      if (!sb?.business) return;

                      return (
                        <div
                          key={index}
                          className="cursor single_shared_business"
                          onClick={() => {
                            setSearchedLocation({
                              title: sb?.business?.address,
                              place_id: sb?.business?.googlePlaceID,
                            });
                            setBusinessEmailValue(sb?.business?.email);
                            setOccasionValue(sb?.business?.occasion);
                            setBusinessId(sb?.business?._id);
                            setArrivalDateValue(sb?.business?.arrivalDate);
                            setArrivalTimeValue(sb?.business?.arrivalTime);
                            setAddNewBusiness(true);
                          }}
                        >
                          <div className="single_shared_business_left_side">
                            <p className="business_name">
                              {sb?.business?.businessName ||
                                sb?.business?.email}
                            </p>
                            <p className="business_participants">
                              {sb?.business?.arrivalDate && (
                                <span>
                                  {" "}
                                  {new Date(sb?.business?.arrivalDate)
                                    ? format(
                                        new Date(sb?.business?.arrivalDate),
                                        "dd.MM.yyyy"
                                      ) + " | "
                                    : sb?.business?.arrivalDate + " | "}
                                </span>
                              )}
                              <span>
                                {sb?.business?.occasion
                                  ? `${sb?.business?.occasion}`
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div className="single_shared_business_right_side">
                            {dark_right_arrow_circle_svg}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="right">
          {/* Share with new business */}
          <HybridModal
            open={addNewBusiness}
            opener={() => setAddNewBusiness(null)}
            bottom={true}
            backgroundColor={"#0d0d0d"}
            dark={true}
            blur={true}
          >
            {addNewBusiness && (
              <div className="inner_container popup">
                <div className="share_business_popup_padding">
                  <p className="text">Share with</p>

                  <div className="hide_1300">
                    {!businessEmailValue && (
                      <div
                        onClick={() => {
                          setSearchForLocation(true);
                          setSearchedText("");
                          setTimeout(() => {
                            businessNameInputRef.current?.focus();
                          }, 100);
                        }}
                      >
                        <span style={{ pointerEvents: "none" }}>
                          <Input
                            name={"business name"}
                            type="text"
                            label={"Search for business"}
                            defaultValue={searchedLocation?.title ?? ""}
                            icon={search_magnifier_white_svg}
                            greyInputs={true}
                          />
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="show_1300">
                    {!businessEmailValue && (
                      <Input
                        name={"business_location"}
                        type="location"
                        label={"Search for business"}
                        defaultValue={searchedText || searchedLocation?.title}
                        icon={search_magnifier_white_svg}
                        validationCallback={(mp) => {
                          if (!mp) {
                            setSearchedLocation(null);
                            return;
                          }
                          setSearchedText(mp?.description);
                          setSearchedLocation({
                            title: mp?.description,
                            ...mp,
                          });
                        }}
                        noAnimation={true}
                        greyInputs={true}
                      />
                    )}
                  </div>

                  {(!searchedLocation || businessEmailValue) && (
                    <Input
                      name={"business_email"}
                      type="text"
                      label={"Enter contact email"}
                      defaultValue={businessEmailValue}
                      validationCallback={(value) =>
                        setBusinessEmailValue(value)
                      }
                      greyInputs={true}
                    />
                  )}

                  {(businessOccasionInputOpen ||
                    openOccassionAndArrivalInputsDesktop ||
                    occasionValue) && (
                    <Input
                      name={"occasion"}
                      type={
                        window.innerWidth < 450 ? "selectWithPopup" : "select"
                      }
                      options={occasions}
                      label={"🎉 Occasion"}
                      defaultValue={occasionValue}
                      validationCallback={(value) => {
                        setOccasionValue(value);
                        if (!openOccassionAndArrivalInputsDesktop)
                          setBusinessArrivalDateInputOpen(true);
                      }}
                      selectPopupAutoOpenOptions={businessOccasionInputOpen}
                      selectPopupBackAction={() =>
                        setBusinessOccasionInputOpen(false)
                      }
                      selectPopupNextButtonText={"Skip"}
                      selectPopupNextAction={() =>
                        setBusinessArrivalDateInputOpen(true)
                      }
                      greyInputs={true}
                    />
                  )}
                  {(businessArrivalDateInputOpen ||
                    openOccassionAndArrivalInputsDesktop ||
                    arrivalDateValue) && (
                    <>
                      <Input
                        name={"occastion_date"}
                        type="date"
                        label={"Arrival Date (DD.MM.YYYY)"}
                        defaultValue={arrivalDateValue ? arrivalDateValue : ""}
                        validationCallback={(value) => {
                          setArrivalDateValue(value);
                        }}
                        greyInputs={true}
                      />
                      <Input
                        name={"occastion_time"}
                        type="time"
                        label={"Arrival Time"}
                        defaultValue={arrivalTimeValue}
                        validationCallback={(value) => {
                          setArrivalTimeValue(value);
                        }}
                        greyInputs={true}
                      />
                    </>
                  )}
                  {businessId && (
                    <button
                      className="button-delete"
                      onClick={() => setDisableAccessForBusiness(businessId)}
                    >
                      Disable Access
                    </button>
                  )}

                  <div className="hide_1300">
                    <Button
                      text={
                        openOccassionAndArrivalInputsDesktop ? "Next" : "Save"
                      }
                      type={"roundedSquare"}
                      size={"medium"}
                      extraClass="fullWidth"
                      color={"blue"}
                      callback={() => {
                        if (!openOccassionAndArrivalInputsDesktop)
                          saveBusiness();
                        else if (searchedLocation || businessEmailValue)
                          setBusinessOccasionInputOpen(true);
                      }}
                    />
                  </div>
                  <div className="show_1300">
                    <Button
                      text={
                        !openOccassionAndArrivalInputsDesktop ? "Next" : "Save"
                      }
                      type={"roundedSquare"}
                      size={"medium"}
                      extraClass="fullWidth"
                      color={"blue"}
                      callback={() => {
                        if (searchedLocation || businessEmailValue)
                          setOpenOccassionAndArrivalInputsDesktop(true);
                        if (openOccassionAndArrivalInputsDesktop)
                          saveBusiness();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </HybridModal>
        </div>
      </div>

      {/* Incomplete Profile popup */}
      <HybridModal
        open={incompleteProfile}
        opener={() => setIncompleteProfile(false)}
        bottom={true}
        autoHeight={true}
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
      >
        <div className="inner_container popup">
          <h1 className="heading basier">
            Save your preferences first before sharing them
          </h1>
          <p className="text">
            Once your profile is setup you will be able to share them. Please
            save preferences now to access your share link or QR code.
          </p>

          <Button
            type={"roundedSquare"}
            size={"medium"}
            color={"blue"}
            text={"Save Preferences Now"}
            extraClass={"static_full_width"}
            callback={() => navigate("/personal/account")}
          />

          <span style={{ display: "block", marginBottom: "50px" }}></span>
        </div>
      </HybridModal>

      {/* Share preference with QR Code */}
      <HybridModal
        open={showQRCode}
        opener={() => setShowQRCode(false)}
        bottom={true}
        autoHeight
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
      >
        <div className="inner_container popup">
          <div className="qr_share_header">
            <button
              className="navigator_share_via_qr"
              onClick={shareBTLRNavigator}
            >
              {share_icon_blue_svg}
            </button>
          </div>

          <p className="text center_align lighter_grey_text">
            Share with QR Code
          </p>

          <div className="qr_container">
            <div className="qr_share_div" ref={qrCodeRef} />
            <img src={btlr_logo_white} alt="BTLR" className="btlr_logo_qr" />
          </div>

          <p className="text center_align lighter_grey_text">
            Simply share your QR code or preference link with anyone to share
            your preferences.
          </p>

          <Button
            text={"Copy Your Preference Link"}
            type={"pill"}
            size={"small"}
            color={"blue_bg_effect"}
            extraClass={"static_auto_width"}
            callback={() => {
              copy(`https://btlr.vip/business/${BTLRBeta._id}`);
            }}
          >
            {copy_chain_blue_svg}
          </Button>

          {/* <span className="continue_btn_bg"></span> */}
          {/* <Button
            type={"roundedSquare"}
            size={"medium"}
            color={"grey_light"}
            text={"Close"}
            // extraClass="continue"
            callback={() => setShowQRCode(false)}
          />

          <span style={{ display: "block", marginBottom: "160px" }}></span> */}
        </div>
      </HybridModal>

      {/* Search for business location */}
      <HybridModal
        open={searchForLocation}
        opener={() => setSearchForLocation(null)}
        bottom={true}
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
      >
        {searchForLocation && (
          <>
            <div className="search_location_header">
              <Input
                refVar={businessNameInputRef}
                name={"business name"}
                type="text"
                label={""}
                defaultValue={searchedText}
                icon={search_magnifier_white_svg}
                validationCallback={(value) => {
                  setSearchedText(value);
                  searchForLocationOnMaps(value);
                }}
                noAnimation={true}
                greyInputs={true}
              />
              <button
                className="reset_search"
                onClick={() => {
                  setSearchedText("");
                  setMapsPredictions([]);
                }}
              >
                {close_thin}
              </button>
              <p
                className="cursor text blue_text"
                onClick={() => setSearchForLocation(false)}
              >
                Cancel
              </p>
            </div>

            <div
              className="search_location_options"
              style={{ height: "67vh", overflowY: "auto" }}
            >
              {mapsPredictions.map((mp, index) => {
                const { description, place_id } = mp;
                return (
                  <p
                    key={index}
                    className="single_option"
                    onClick={() => {
                      setSearchedLocation({
                        title: description,
                        place_id,
                        ...mp,
                      });
                      setSearchForLocation(false);
                    }}
                  >
                    {description}
                  </p>
                );
              })}
            </div>

            {/* <span className="continue_btn_bg"></span> */}
            <Button
              text={"Close"}
              type={"roundedSquare"}
              size={"medium"}
              color={"grey_light"}
              callback={() => setSearchForLocation(false)}
              // extraClass="continue"
            />
          </>
        )}
      </HybridModal>

      {/* Select arrival date */}
      <HybridModal
        titleInsteadOfBar={"🗓️ Arrival Date"}
        open={businessArrivalDateInputOpen}
        opener={() => setBusinessArrivalDateInputOpen(null)}
        bottom={true}
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
        backButtonAction={() => {
          setBusinessArrivalDateInputOpen(false);
          setBusinessOccasionInputOpen(true);
        }}
      >
        {businessArrivalDateInputOpen && (
          <>
            <p className="text lighter_grey_text">Make it special</p>
            <Input
              name={"occastion_date"}
              type="date"
              label={"Arrival Date (DD.MM.YYYY)"}
              defaultValue={arrivalDateValue ? arrivalDateValue : ""}
              validationCallback={(value) => {
                setArrivalDateValue(value);
              }}
              selectPopupNextButtonText={"Save"}
              selectPopupNextAction={saveBusiness}
              greyInputs={true}
            />

            <p className="text lighter_grey_text">Arrival Time</p>
            <Input
              name={"occastion_time"}
              type="time"
              label={"Arrival Time"}
              defaultValue={arrivalTimeValue}
              validationCallback={(value) => {
                setArrivalTimeValue(value);
              }}
              greyInputs={true}
            />

            <Button
              text={"Save"}
              type={"roundedSquare"}
              size={"medium"}
              color={"blue"}
              callback={saveBusiness}
              extraClass="fullWidth"
              // extraClass="continue"
            />
          </>
        )}
      </HybridModal>

      {/* Confirm Preferences (Business shared preferences) */}
      <HybridModal
        open={confirmPreferencesPopup}
        opener={() => setConfirmPreferencesPopup(false)}
        bottom={true}
        autoHeight={true}
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
      >
        <div className="inner_container popup">
          <h1 className="heading basier">Confirm Preferences</h1>
          <p className="text light_grey_text">
            Please login or sign up to save your preferneces
          </p>

          <span className="vertical_space"></span>
          <Button
            type={"roundedSquare"}
            size={"medium"}
            color={"grey_blue"}
            extraClass={"fullWidth"}
            callback={() => navigate("/login/form")}
          >
            Login
          </Button>
          <span className="vertical_space"></span>
          <Button
            type={"roundedSquare"}
            size={"medium"}
            color={"white"}
            extraClass={"fullWidth"}
            callback={() => navigate("/personal/account")}
          >
            Signup
          </Button>

          <span style={{ display: "block", marginBottom: "50px" }}></span>
        </div>
      </HybridModal>

      {/* Business share history */}
      <HybridModal
        open={showBusinessShareHistory?.show}
        opener={() => setShowBusinessShareHistory(null)}
        bottom={true}
        autoHeight={true}
        backgroundColor={"#0d0d0d"}
        dark={true}
        blur={true}
      >
        <div className="inner_container popup">
          <h1 className="heading basier">
            {showBusinessShareHistory?.business?.businessName}
          </h1>
          <p className="text light_grey_text">
            {showBusinessShareHistory?.business?.address}
          </p>

          <span className="vertical_space"></span>
          <Button
            text={"Share Again"}
            type={"pill"}
            size={"medium"}
            color={"blue"}
            extraClass={"flex"}
            callback={() => {
              const { business } = showBusinessShareHistory;
              if (!business) return;
              if (business?.address && business?.googlePlaceID) {
                setSearchedLocation({
                  title: business?.address,
                  place_id: business?.googlePlaceID,
                });
                setBusinessEmailValue();
              } else {
                setBusinessEmailValue(business?.email);
                setSearchedLocation();
              }
              setOccasionValue(undefined);
              setBusinessId(undefined);
              setArrivalDateValue(undefined);
              setArrivalTimeValue(undefined);
              setShowBusinessShareHistory(null);
              setAddNewBusiness(true);
              if (window.innerWidth < 1300) setBusinessOccasionInputOpen(true);
              else setOpenOccassionAndArrivalInputsDesktop(true);
            }}
          >
            {white_calendar_icon}
          </Button>
          <span className="vertical_space"></span>
          {businessId && (
            <button
              className="button-delete"
              onClick={() => setDisableAccessForBusiness(businessId)}
            >
              Disable Access
            </button>
          )}
          <span className="vertical_space"></span>
          <>
            <p
              className="text smallest light_grey_text"
              style={{ marginBottom: "6px", marginTop: "20px" }}
            >
              History
            </p>
            <div
              className="preferences_display shared_business_timeline hide_scrollbar"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="shared_businesses">
                {showBusinessShareHistory?.business?.visits?.length && (
                  <>
                    {showBusinessShareHistory.business.visits
                      .sort(
                        (a, b) =>
                          new Date(b?.arrivalDate)?.getTime() -
                          new Date(a?.arrivalDate)?.getTime()
                      )
                      .map((visit, index) => {
                        const formattedDate = visit?.arrivalDate
                          ? format(
                              new Date(visit?.arrivalDate?.replace("Z", "")),
                              "dd.MM.yyyy"
                            )
                          : "Without Date";

                        return (
                          <div key={index} className="single_shared_business">
                            <div className="single_shared_business_left_side">
                              <p className="business_name">{formattedDate}</p>
                              <p className="business_participants">
                                {visit?.arrivalDate
                                  ? new Date(
                                      visit.arrivalDate?.replace("Z", "")
                                    )?.toLocaleTimeString() + " "
                                  : "Without Time"}{" "}
                                {visit?.occasion ? " | " + visit.occasion : ""}{" "}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </>

          <span style={{ display: "block", marginBottom: "50px" }}></span>
        </div>
      </HybridModal>

      {/* For confirmation of shared preferences with invited businesses (As logged in user) */}
      <ConfirmationModal
        dark={true}
        open={confirmShareWithInvitedBusiness}
        opener={setConfirmShareWithInvitedBusiness}
        title={"Preference Shared!"}
        info={
          "Thank you for sharing your preferences, a confirmation email has been sent to you."
        }
        cancelButtonText={"OK"}
        showConfirmButton={false}
      />

      {/* For confirmation of deleting shared business */}
      <ConfirmationModal
        dark={true}
        open={disableAccessForBusiness}
        opener={setDisableAccessForBusiness}
        title={"Disable Access?"}
        info={
          "Confirm you want to disable this business from access your data? This action cannot be undone."
        }
        confirmButtonText={"Disable Access"}
        cancelButtonText={"Cancel"}
        confirmButtonColor={"dark_red"}
        confirmButtonCallback={disableBusinessPreference}
      />
    </BetaContainer>
  );
};

export default SharePreferences;
