import React, { useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BusinessDashboardContainer from "./components/businessContainer";
import PreferenceLinkComp from "./components/preferenceLinksComp";

import { checkLoginCookieBetaBusiness } from "../../../api/helpers/authCookies";

import {
  BTLR_BETA_API_BASE,
  businessPreferenceLinksAPIURL,
} from "../../../api/base";

const BusinessPreferenceLinks = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  return (
    <BusinessDashboardContainer>
      <section id="view-preference">
        <PreferenceLinkComp
          businessId={BTLRBeta?.selectedBusiness?._id}
          token={checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken}
          API_URL={BTLR_BETA_API_BASE + businessPreferenceLinksAPIURL}
        />
      </section>
    </BusinessDashboardContainer>
  );
};

export default BusinessPreferenceLinks;
