import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { BTLRBetaContext } from "../../../../contexts/btlrBeta.context";

import B2CNav from "../../../b2c/b2cNav";
import Modal from "../../../../components/common/modal/modal";
import Form from "../../../../components/common/form/form";
import Input from "../../../../components/common/input/input";
import Button from "../../../../components/common/button/button";

import { loginInputs, businessBetaLogin } from "../data.service";
import { checkLoginCookieBetaBusiness } from "../../../../api/helpers/authCookies";
import { getUserDataBeta } from "../../../b2c/account/service";

const BusinessDashboardContainer = ({ children }) => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [loginModal, setLoginModal] = useState(false);
  const [confirmLoginModal, setConfirmLoginModal] = useState(false);
  const [userLoginEmail, setUserLoginEmail] = useState("");
  const [loginEmailResent, setLoginEmailResent] = useState(false);

  const handleLogin = ({ email, resend = false }) => {
    setUserLoginEmail(email);

    businessBetaLogin(
      email,
      BTLRBeta?.userIdBusiness,
      () => {
        setLoginModal(false);
        setConfirmLoginModal(true);
        if (resend) setLoginEmailResent(true);
      },
      () => navigate("/login/business")
    );
  };

  const businessLoginToken =
    checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken;

  const goToBusinessPage = (data, accessType, business) => {
    setBTLRBeta({
      ...data,
      selectedBusiness: { accessType, ...business },
      goToBusiness: false,
    });

    navigate("/business/guests");
  };

  // Load initial data and manage redirection to which business page
  useEffect(() => {
    if (!businessLoginToken) {
      setLoginModal(true);
      return;
    }

    if (businessLoginToken && !BTLRBeta?.loggedIn) {
      try {
        getUserDataBeta(setBTLRBeta, businessLoginToken, (ignore, data) => {
          if (BTLRBeta?.goToBusiness && !user_id) {
            const { ownedBusinesses } = data;
            if (!ownedBusinesses?.length) {
              navigate("/personal/edit-preferences");
              return;
            }

            const lastVisitedBusinessId = localStorage.getItem(
              "lastVisitedBusinessId"
            );

            if (lastVisitedBusinessId) {
              const findBusiness = ownedBusinesses.find(
                (ob) => ob?.business?._id === lastVisitedBusinessId
              );

              if (findBusiness)
                goToBusinessPage(
                  data,
                  findBusiness?.accessType,
                  findBusiness?.business
                );
              else {
                const firstBusinessInList = ownedBusinesses[0];
                goToBusinessPage(
                  data,
                  firstBusinessInList?.accessType,
                  firstBusinessInList?.business
                );
              }
            } else {
              const firstBusinessInList = ownedBusinesses[0];
              goToBusinessPage(
                data,
                firstBusinessInList?.accessType,
                firstBusinessInList?.business
              );
            }
          }
        });
      } catch (error) {
        console.error("error with user data", error);
      }
    }
  }, []);

  useEffect(() => {
    if (!BTLRBeta?.selectedBusiness && !user_id && !BTLRBeta?.goToBusiness)
      navigate("/personal/edit-preferences");
  }, []);

  useLayoutEffect(() => {
    window.document.body.style.backgroundColor = "#0D0D0D";
  });

  return (
    <>
      <div className="beta_container">
        <div className="bg_container">
          <B2CNav />
        </div>
        <div className="beta_app">{children}</div>
      </div>

      <Modal
        open={loginModal}
        opener={() => {}}
        backgroundColor={"#000000"}
        blur
        biggerSize
      >
        <div className="modal-container">
          <h1 className="heading basier">Confirm It’s You</h1>
          <p className="text ml-2 medium manrope light_grey_text manrope ">
            Please enter your business email to view Preference.
          </p>
          <div className="w-50">
            <Form
              inputs={loginInputs}
              prefilledValues={{ consent: true }}
              transparentInputs={true}
              submit_text={"View Preference"}
              custom_submit_2={true}
              size="small"
              submitForm={(values) => handleLogin(values)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={confirmLoginModal}
        opener={() => {}}
        backgroundColor={"#000000"}
        blur
        biggerSize
      >
        <div className="modal-container">
          <h1 className="heading basier">Almost There...</h1>
          <p className="text medium manrope light_grey_text manrope center_align">
            A login link has been sent to your email.
          </p>
          <div className="w-50">
            <Input
              label={"confirm email"}
              transparentInputs={true}
              defaultValue={userLoginEmail}
              disableInput={true}
            />
            <p
              className="text manrope small center_align"
              style={{ color: "#f6f6f6" }}
            >
              Please check your spam folder if you are unable to find the link .
            </p>
            <Button
              type={"pill"}
              size={"medium"}
              color={"blue_bg_transparent"}
              text={!loginEmailResent ? "Resend Link" : "Email Resent!"}
              centered={true}
              callback={() =>
                handleLogin({ business_email: userLoginEmail, resend: true })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BusinessDashboardContainer;
