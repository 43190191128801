import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import Modal from "../../../components/common/modal/modal";
import Form from "../../../components/common/form/form";
import Button from "../../../components/common/button/button";

import {
  loginUserBeta,
  loginUserBetaWithBusiness,
  signInUPInputs,
  signupUserBeta,
  resendConfirmationEmail,
} from "./signup.service";
import { b2cAccountInputs, b2cAccountInputsForPopup } from "../account/service";
import { getSalutations } from "../../b2b/signup/signup.service";

import { close_thin, go_back_black } from "../../../assets/svgs/action_icons";
import { gmail_icon } from "../../../assets/svgs/icons";

import "./signup.scss";

import logo_white from "../../../assets/images/global/logo_white.svg";
import success_bg from "../../../assets/images/b2c/auth/success.jpg";

const GMailURL = `https://mail.google.com/mail/u/0/#advanced-search/from=btlr.vip&subject=Confirm+Email&subset=all&has=Confirm+Email&within=1d&sizeoperator=s_sl&sizeunit=s_smb&query=from%3A(btlr.vip)+subject%3A(Confirm+Email)+Confirm+Email`;

const B2C_Signup = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const { showForm } = useParams();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [showWelcomeScreen, set_showWelcomeScreen] = useState(true);
  const [showSigninPopup, setShowSigninPopup] = useState(false);
  const [showSignInForm, set_showSignInForm] = useState(false);
  const [showSignupForm, setShowSignupupForm] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const [inputs, setInputs] = useState(
    window.innerWidth <= 700
      ? [...b2cAccountInputs]
      : [...b2cAccountInputsForPopup]
  );
  const [confirmLoginModal, setConfirmLoginModal] = useState(false);
  const [loginEmailResent, setLoginEmailResent] = useState(false);
  const [gmail, setGmail] = useState(false);
  const [showSuccessScreenEmailSent, set_showSuccessScreenEmailSent] =
    useState();
  const [showEmailNotFoundError, setEmailNotFoundError] = useState(false);
  const [signupResend, setSignupResend] = useState(false);

  const loginSubmit = (values) => {
    const { email } = values;
    if (!email) return;

    if (BTLRBeta?.businessId) {
      // Parse preferences for login
      let parsedData = [];

      // Set predefined data first
      const businessIds = Object.keys(BTLRBeta?.preferences?.predefined);
      businessIds.forEach((bId) => {
        const businessPreferencesObject = {
          businessId: bId,
          preferences: {
            predefined: [...BTLRBeta?.preferences.predefined[bId]],
            custom: [...BTLRBeta?.preferences.custom[bId]],
          },
        };
        parsedData.push(businessPreferencesObject);
      });

      const loginObject = { ...values, ...BTLRBeta, ...parsedData[0] };

      console.log(loginObject);

      loginUserBetaWithBusiness(
        loginObject,
        () => {
          if (values?.resend) {
            setLoginEmailResent(true);
          }

          if (window.innerWidth <= 700) {
            set_showSuccessScreenEmailSent(email);
            set_showSignInForm(false);
          } else {
            setConfirmLoginModal(email);

            if (
              email.split("@")[1].includes("gmail.com") ||
              email.split("@")[1].includes("btlr.vip")
            )
              setGmail(true);
          }
        },
        setEmailNotFoundError
      );
      return;
    }

    loginUserBeta(
      email,
      () => {
        setSignupResend(false);
        if (values?.resend) {
          setLoginEmailResent(true);
        }

        set_showSuccessScreenEmailSent(email);

        if (window.innerWidth <= 700) {
          set_showSignInForm(false);
        } else {
          setConfirmLoginModal(email);

          if (
            email.split("@")[1].includes("gmail.com") ||
            email.split("@")[1].includes("btlr.vip")
          )
            setGmail(true);
        }
      },
      setEmailNotFoundError
    );
  };

  const signupSubmit = (values) => {
    if (values?.resend) {
      resendConfirmationEmail({ ...values, signupType: "b2c" }, () => {
        setLoginEmailResent(true);
      });
      return;
    }

    signupUserBeta({ ...values, signupType: "b2c" }, () => {
      setSignupResend({ ...values, resend: true });
      set_showSuccessScreenEmailSent(values?.email);

      if (window.innerWidth <= 700) {
        set_showSignInForm(false);
        setShowSignupupForm(false);
      } else {
        setConfirmLoginModal(values?.email);
        setShowSignupPopup(false);

        if (
          values?.email?.split("@")[1].includes("gmail.com") ||
          values?.email?.split("@")[1].includes("btlr.vip")
        )
          setGmail(true);
      }
    });
  };

  useEffect(() => {
    // Reset all first
    set_showSignInForm(false);
    setShowSigninPopup(false);
    setShowSignupupForm(false);
    setShowSignupPopup(false);
    setConfirmLoginModal(false);
    setGmail(false);
    setSignupResend(false);

    if (!showForm) {
      set_showWelcomeScreen(true);
      return;
    }

    if (showForm === "form") {
      if (window.innerWidth <= 700) {
        set_showWelcomeScreen(false);
        set_showSignInForm(true);
      } else {
        setShowSigninPopup(true);
      }
    }
    if (showForm === "signup") {
      if (window.innerWidth <= 700) {
        set_showWelcomeScreen(false);
        setShowSignupupForm(true);
      } else {
        setShowSignupPopup(true);
      }
    }
  }, [showForm]);

  useEffect(() => {
    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      const temp = [...inputs];
      temp[0].options = parsedSalutationsData;
      setInputs(temp);
    });
  }, [b2cAccountInputs]);

  useEffect(() => {
    const showOTP = searchParams.get("otp");
    if (!showOTP) return;

    Cookies.set("showOTP", true, { expires: 30 });
  }, [searchParams.get("otp")]);

  useEffect(() => {
    if (!BTLRBeta?.loggedIn && BTLRBeta?.b2cNavGoBack) {
      setBTLRBeta({ ...BTLRBeta, b2cNavGoBack: "/personal/account" });
    }
  }, [BTLRBeta?.loggedIn]);

  return (
    <div className="signup b2c_signup">
      {showWelcomeScreen && (
        <>
          <div className="welcome center_align">
            <h1 className="heading medium white">Welcome to BTLR</h1>
            <p className="text grey_2">
              Lifestyle personalisation made simple. Safely store and share all
              your personal preferences with businesses and brands you love.
            </p>
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"grey_blue"}
              link={"/login/form"}
            >
              Login
            </Button>
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"white"}
              link={"/login/signup"}
            >
              Signup
            </Button>
            <p
              className="text light_grey_text center_align"
              style={{ marginTop: "24px" }}
            >
              BTLR Business?{" "}
              <Link to="/login/business" className="blue_text">
                Click here
              </Link>
            </p>
          </div>
        </>
      )}
      {!showWelcomeScreen && (
        <div className={`form_container`}>
          {showSignInForm && (
            <>
              {!BTLRBeta?.b2cNavGoBack && (
                <button
                  className="close_btn grey"
                  onClick={() => {
                    // set_showWelcomeScreen(true);
                    // set_showSignInForm(false);
                    navigate("/login");
                  }}
                >
                  {close_thin}
                </button>
              )}
              {!BTLRBeta?.b2cNavGoBack && (
                <button
                  className="close_btn grey"
                  onClick={() => {
                    set_showWelcomeScreen(true);
                    set_showSignInForm(false);
                    navigate("/login");
                  }}
                >
                  {close_thin}
                </button>
              )}
              {BTLRBeta?.b2cNavGoBack && (
                <button
                  className="nav_go_back"
                  onClick={() => navigate(BTLRBeta?.b2cNavGoBack)}
                >
                  {go_back_black}
                </button>
              )}
              <div className="form_inner">
                <div className="form_header">
                  <div className="b_heading white_text center_algn">
                    Nice to see you again!
                  </div>
                  {/* {showSignInForm && <LanguageSwitch />} */}
                </div>
                <Form
                  inputs={signInUPInputs}
                  submit_text={"Login"}
                  submitForm={loginSubmit}
                  greyInputs={true}
                />
                {showEmailNotFoundError && (
                  <p
                    className="inner_semi_smaller_info red_text left_align"
                    style={{ marginTop: "4px" }}
                  >
                    Hmmm. We don’t recognise your email.{" "}
                    <span
                      className="blue_text"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login/signup");
                      }}
                    >
                      Sign Up
                    </span>
                  </p>
                )}

                {/* <p className="form_info_text">
                By proceeding, I accept the BTLR Service and confirm that I have
                read{" "}
                <a
                  href={
                    "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
                  }
                  target="_blank"
                >
                  BTLR's Privacy Notice
                </a>
              </p> */}
              </div>
            </>
          )}

          {showSuccessScreenEmailSent && (
            <>
              <img
                src={success_bg}
                alt="Confirm your login"
                className="success_bg"
              />
              <button
                className="close_btn grey"
                onClick={() => {
                  navigate("/login");
                }}
              >
                {close_thin}
              </button>
              <div className="form_inner move_top">
                <div className="form_header"></div>

                <h2 className="heading medium center_align">
                  A link is on the way
                </h2>

                <p
                  className="text manrope center_align"
                  style={{ color: "#E5E8ED" }}
                >
                  We’ve sent you an email to{" "}
                  <strong>{showSuccessScreenEmailSent}</strong> please confirm
                  your account to Sign In/Up.
                </p>
                <div className="success">
                  <Button
                    type={"pill"}
                    size={"medium"}
                    color={"white"}
                    callback={() => {
                      if (!signupResend) {
                        loginSubmit({
                          email: showSuccessScreenEmailSent,
                          resend: true,
                        });
                      } else {
                        signupSubmit(signupResend);
                      }
                    }}
                  >
                    {loginEmailResent ? "Email Resent!" : "Resend Email"}
                  </Button>
                </div>
              </div>
            </>
          )}

          {showSignupForm && (
            <>
              <button
                className="close_btn grey"
                onClick={() => {
                  // set_showWelcomeScreen(true);
                  // setShowSignupupForm(false);
                  navigate("/login");
                }}
              >
                {close_thin}
              </button>
              <div className="form_inner static">
                <div className="form_header">
                  <div className="b_heading white_text center_align">
                    Nice to meet you!
                  </div>
                </div>
                <Form
                  inputs={inputs}
                  submit_text={"Sign Up"}
                  submitForm={signupSubmit}
                  greyInputs={true}
                />
              </div>
            </>
          )}
        </div>
      )}

      <div className="bg_container">
        {/* <div className="welcome_text">
          <p className="welcome_text_main">
            Shop. Dine. Travel. <br />
            Love. Explore.
          </p>
          <p className="welcome_text_sub">On your own terms</p>
        </div> */}
        <div className="overlay"></div>
        <img src={logo_white} alt="BTLR" className="logo" />
      </div>

      {/* login */}
      <Modal
        dark={true}
        open={showSigninPopup}
        opener={() => navigate("/login")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">Login</h1>
          <div>
            <Form
              inputs={signInUPInputs}
              submit_text={"Login"}
              greyInputs={true}
              submitForm={loginSubmit}
            />

            {showEmailNotFoundError && (
              <p
                className="inner_semi_smaller_info red_text left_align"
                style={{ marginTop: "4px" }}
              >
                Hmmm. We don’t recognise your email.{" "}
                <span
                  className="blue_text"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login/signup");
                  }}
                >
                  Sign Up
                </span>
              </p>
            )}
          </div>
        </div>
      </Modal>

      {/* Confirm Login */}
      <Modal
        dark={true}
        open={confirmLoginModal}
        opener={() => navigate("/login")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">A link is on the way</h1>

          <p className="text manrope center_align" style={{ color: "#E5E8ED" }}>
            We’ve sent you an email to{" "}
            <span className="blue_text">{showSuccessScreenEmailSent}</span>{" "}
            please confirm your account to Sign In/Up.
          </p>

          {!gmail && (
            <Button
              text={loginEmailResent ? "Email Resent!" : "Resend Email"}
              type={"pill"}
              color={"white"}
              size={"medium"}
              centered={true}
              callback={() => {
                if (!signupResend) {
                  loginSubmit({
                    email: confirmLoginModal,
                    resend: true,
                  });
                } else {
                  signupSubmit(signupResend);
                }
              }}
            />
          )}

          {gmail && (
            <>
              <div className="gmail_link_container">
                <a
                  href={GMailURL}
                  target={"_blank"}
                  className="btn hollow_white medium pill centered gmail_btn"
                >
                  {gmail_icon} Open Gmail
                </a>
              </div>
              <p
                className="blue_3_text text center_align cursor"
                style={{ marginTop: "12px" }}
                onClick={() => {
                  if (!signupResend) {
                    loginSubmit({
                      email: confirmLoginModal,
                      resend: true,
                    });
                  } else {
                    signupSubmit(signupResend);
                  }
                }}
              >
                {loginEmailResent ? "Email Resent!" : "Resend Email"}
              </p>
            </>
          )}
        </div>
      </Modal>

      {/* Signup */}
      <Modal
        dark={true}
        open={showSignupPopup}
        opener={() => navigate("/login")}
        backgroundColor={"#000000"}
        blur
      >
        <div className="business_signup_popup">
          <h1 className="heading basier center_align">Signup</h1>
          <div>
            <Form
              inputs={inputs}
              greyInputs={true}
              submitForm={signupSubmit}
              submit_text={"Sign Up"}
              custom_submit_2={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default B2C_Signup;
