import React, { useContext } from "react";
import { Link } from "react-router-dom";

import SlideInPopup from "../../../../components/common/slideInPopup/slideInPopup";

import {
  right_arrow_dark,
  share_pref_svg,
  my_account_icon_svg,
  guests_icon,
  preferences_icon,
  pref_link_icon,
  faq_icon,
} from "../../../../assets/svgs/icons";

const B2BBottomNav = ({ nav, openNav }) => {
  return (
    <SlideInPopup
      open={nav}
      opener={() => openNav(false)}
      bottom={true}
      autoHeight={true}
      dark={true}
      backgroundColor="#000000"
      blur={true}
    >
      <>
        <div className="nav_box dark">
          <Link
            to={"/business/unlinked_guests"}
            className="nav_link_pref"
            onClick={() => {
              // if (!BTLRBeta?.guestAdmin)
              //   setBTLRBeta({
              //     ...BTLRBeta,
              //     sharePreferenceModal: true,
              //   });
              // else
              //   setBTLRBeta({
              //     ...BTLRBeta,
              //     b2bAccountModal: true,
              //   });
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{guests_icon}</span>
              <p>Unlinked guests</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/business/guests"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{guests_icon}</span>
              <p>My Guests</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/business/share_preferences"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{share_pref_svg}</span>
              <p>Share</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/business/preferences"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{preferences_icon}</span>
              <p>Preferences</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/business/preference_links"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{pref_link_icon}</span>
              <p>Preference Link</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/business/account"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{my_account_icon_svg}</span>
              <p>Account</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
          <Link
            to={"/faq"}
            className="nav_link_pref"
            onClick={() => {
              openNav(false);
            }}
          >
            <div className="nav_left_side">
              <span className="nav_icon">{faq_icon}</span>
              <p>FAQ</p>
            </div>
            <div className="nav_right_side">{right_arrow_dark}</div>
          </Link>
        </div>
      </>
    </SlideInPopup>
  );
};

export default B2BBottomNav;
