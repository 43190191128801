export const swap = (array, i, j) =>
  ([array[i], array[j]] = [array[j], array[i]]);

export const ValidateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const checkIfDate1IsTodayOrInFuture = (date1, date2) => {
  try {
    const date1Obj = new Date(date1);
    const date2Obj = new Date(date2);

    if (date1Obj.getFullYear() > date2Obj.getFullYear()) return true;

    if (date1Obj.getFullYear() < date2Obj.getFullYear()) return false;

    if (date1Obj.getMonth() > date2Obj.getMonth()) return true;

    if (date1Obj.getMonth() < date2Obj.getMonth()) return false;

    if (
      date1Obj.getMonth() === date2Obj.getMonth() &&
      date1Obj.getDate() < date2Obj.getDate()
    )
      return false;

    return true;
  } catch (error) {
    return false;
  }
};
