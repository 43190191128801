export const shareBTLRNavigator = async () => {
  try {
    await window.navigator.share({
      title: "You’re Invited To Personalise Your Lifestyle.",
      text: "BTLR is a lifestyle experience platform that allows you to personalise: Hotels, dinners, flights ….",
      url: "https://beta.btlr.vip/default",
    });
  } catch (error) {
    console.log("Error in navigator share", error);
  }
};
