export const buildBasicLoader = () => {
  const loader = document.createElement("div");
  loader.className = "loader";
  loader.innerHTML = `<span class="loader_icon">
  <div class="loadingio-spinner-spinner-i194m2purpg"><div class="ldio-0at4w42a1mmu">
  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div></div>
  </span>`;
  document.body.appendChild(loader);

  const destroyLoader = () => {
    loader.classList.add("destroy");
    setTimeout(() => {
      loader.remove();
    }, 300);
  };

  return destroyLoader;
};
