import React from "react";
import { format } from "date-fns";

import Button from "../../../components/common/button/button";

import { arrow_right_grey_bg } from "../../../assets/svgs/action_icons";
import {
  members_icon_blue,
  account_icon_blue,
} from "../../../assets/svgs/icons";

const BusinessListAdmin = ({
  renderList,
  BTLRBeta,
  setBTLRBeta,
  setSelectedBusiness,
  manuallyVerifyBusiness,
  setSelectedBusinessAccount,
  getBusinessDetails,
}) => {
  return (
    <div className="business_list">
      {renderList.map((b, index) => {
        const {
          _id,
          address: title,
          businessName,
          joinedData,
          createdAt: date,
          registered,
          emailNeeded,
          resendEmail,
          verifificationNeeded,
          unregistered,
        } = b;

        return (
          <div key={index} className="business_item">
            <div className="left">
              {businessName && <p className="title">{businessName}</p>}
              {!businessName && title && (
                <p className="title">{title.split(",")[0]}</p>
              )}
              {!businessName && !title && joinedData?.user?.[0]?.email && (
                <p className="title">
                  {joinedData?.user?.[0].email.split("@")[1]}
                </p>
              )}
              <p className="details">
                {new Date(date) ? format(new Date(date), "dd.MM.yyyy") : date} |{" "}
                {joinedData?.user?.[0]?.email} <br />
                {title}
              </p>
            </div>
            <div className="right">
              {registered && (
                <>
                  <button
                    className="light_blue_btn"
                    onClick={() =>
                      setBTLRBeta({
                        ...BTLRBeta,
                        sharePreferenceModal: true,
                        adminEditBusinessId: _id,
                        adminEditBusinessName: title
                          ? title.split(",")[0]
                          : joinedData?.user?.[0]?.email.split("@")[1],
                      })
                    }
                  >
                    {members_icon_blue} Members
                  </button>
                  <button
                    className="light_blue_btn"
                    onClick={() => {
                      getBusinessDetails(_id);
                    }}
                  >
                    {account_icon_blue} Account
                  </button>
                </>
              )}
              {resendEmail && (
                <Button
                  text={"Resend Email"}
                  type={"pill"}
                  size={"small"}
                  color={"blue_bg_effect"}
                  callback={() => setSelectedBusiness(b)}
                />
              )}
              {(emailNeeded || resendEmail) && (
                <button
                  className="edit_email"
                  onClick={() => setSelectedBusiness(b)}
                >
                  {arrow_right_grey_bg}
                </button>
              )}
              {verifificationNeeded && (
                <>
                  <button
                    className="light_blue_btn red"
                    onClick={() => {
                      manuallyVerifyBusiness(_id, "REJECT");
                    }}
                  >
                    Disapprove Account
                  </button>
                  <button
                    className="light_blue_btn"
                    onClick={() => {
                      manuallyVerifyBusiness(_id);
                    }}
                  >
                    Approve Account
                  </button>
                  <button
                    className="edit_email"
                    onClick={() => {
                      const userData = joinedData?.user?.[0]
                        ? {
                            ...joinedData?.user?.[0],
                            ...b,
                            user_id: joinedData?.user?.[0]?._id,
                          }
                        : b;

                      console.log(userData, b);

                      setSelectedBusinessAccount({
                        type: "verify",
                        userData,
                      });
                      setBTLRBeta({
                        ...BTLRBeta,
                        AccountDetailsModalAdmin: true,
                      });
                    }}
                  >
                    {arrow_right_grey_bg}
                  </button>
                </>
              )}
              {unregistered && (
                <button
                  className="edit_email"
                  onClick={() =>
                    setSelectedBusiness({
                      ...renderList[index],
                      unregistered: true,
                    })
                  }
                >
                  {arrow_right_grey_bg}
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BusinessListAdmin;
