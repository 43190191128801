import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import { BTLR_BETA_API_BASE, betaAdminLoginURL } from "../../../api/base";

import {
  checkLoginCookieBetaAdmin,
  setLoginCookieBetaAdmin,
} from "../../../api/helpers/authCookies";

import Modal from "../../../components/common/modal/modal";
import Input from "../../../components/common/input/input";
import Button from "../../../components/common/button/button";

import {
  btlr_ring_svg,
  gpt_icon,
  personal_accounts_icon,
  preferences_icon,
  pref_link_icon,
} from "../../../assets/svgs/icons";

import "../styles.scss";

const InternalDashboardContainer = ({ children }) => {
  const navigate = useNavigate();
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [enteredPassword, setEnteredPassword] = useState("");

  const { internalPagesUnlocked } = BTLRBeta;

  const betaAdminLogin = (password) => {
    fetch(`${BTLR_BETA_API_BASE}${betaAdminLoginURL}`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setBTLRBeta({
            ...BTLRBeta,
            internalPagesUnlocked: true,
            betaAdminToken: data?.message,
          });
          setLoginCookieBetaAdmin(data?.message);
        } else {
          toast.error("Wrong password");
        }
      });
  };

  const adminToken = checkLoginCookieBetaAdmin() ?? BTLRBeta?.betaAdminToken;

  useEffect(() => {
    if (adminToken)
      setBTLRBeta({
        ...BTLRBeta,
        betaAdminToken: adminToken,
        internalPagesUnlocked: true,
      });
  }, []);

  useLayoutEffect(() => {
    window.document.body.style.backgroundColor = "#f6f6f6";
  });

  return (
    <div className="internal_dashboard">
      {internalPagesUnlocked && (
        <>
          <div className="sidebar">
            <NavLink
              to={"/internal/business-accounts"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {btlr_ring_svg} Business Accounts
            </NavLink>
            <NavLink
              to={"/internal/gpt-prompt"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {gpt_icon} Prompts
            </NavLink>
            <NavLink
              to={"/internal/personal-accounts"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {personal_accounts_icon} Personal Accounts
            </NavLink>
            <NavLink
              to={"/internal/preferences"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {preferences_icon} Preferences
            </NavLink>
            <NavLink
              to={"/internal/preference-links"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {pref_link_icon} Preference Links
            </NavLink>
          </div>
          <div className="body">{children}</div>
        </>
      )}

      <Modal
        open={!internalPagesUnlocked}
        opener={() => {}}
        backgroundColor={"transparent"}
        noBlur={true}
      >
        <div className="white_box">
          <h1 className="heading medium basier black_text">
            Password Required
          </h1>
          <p className="text medium manrope manrope black_text">
            Enter the password to access this Admin page.
          </p>
          <Input
            type="text"
            label={"Password"}
            defaultValue={""}
            validationCallback={(value) => setEnteredPassword(value)}
            enterPressEvent={() => {
              betaAdminLogin(enteredPassword);
            }}
          />
          <Button
            type={"roundedSquare"}
            size={"medium"}
            color={"blue"}
            text={"Login"}
            callback={() => {
              betaAdminLogin(enteredPassword);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InternalDashboardContainer;
