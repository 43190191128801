import React, { useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BusinessDashboardContainer from "./components/businessContainer";
import SharePreferencesModal from "./components/sharePreferencesModal";

import {
  getBusinessMembersBeta,
  inviteBusinessMemberBeta,
  betaBusinessAcceptMember,
  betaBusinessRejectMember,
  betaBusinessUpdateMemberRole,
} from "./data.service";

import "./styles.scss";

const TeamMembers = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  return (
    <BusinessDashboardContainer>
      <section id="view-preference">
        <h1 className="heading basier">Share</h1>
        <p className="text light_grey_text manrope">
          Invite team members to join you
        </p>
        <div className="non_modal_share_preferences">
          <SharePreferencesModal
            admin_id={BTLRBeta?.selectedBusiness?._id}
            businessId={BTLRBeta?.selectedBusiness?._id}
            adminAccess={BTLRBeta?.selectedBusiness?.accessType === "ADMIN"}
            blur
            getBusinessMembersBeta={getBusinessMembersBeta}
            inviteBusinessMemberBeta={inviteBusinessMemberBeta}
            betaBusinessAcceptMember={betaBusinessAcceptMember}
            betaBusinessRejectMember={betaBusinessRejectMember}
            betaBusinessUpdateMemberRole={betaBusinessUpdateMemberRole}
            authToken={BTLRBeta?.businessLoginToken}
          />
        </div>
      </section>
    </BusinessDashboardContainer>
  );
};

export default TeamMembers;
