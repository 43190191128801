export function swapElements(arr, index1, index2) {
  // Validate the indices
  if (
    index1 < 0 ||
    index1 >= arr.length ||
    index2 < 0 ||
    index2 >= arr.length
  ) {
    console.error("Invalid indices provided");
    return;
  }

  // Swap the elements using destructuring assignment
  [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
}

export const findIndexOfPreference = (arr, pref) => {
  let index = 0;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i]._id === pref._id) {
      index = i;
      break;
    }
  }

  return index;
};

export const customPrefTypes = {
  pending: "PENDING",
  accepted: "APPROVED",
  rejected: "REJECTED",
};
