import React, { useState, useEffect, useContext, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { find } from "lodash";

import { BTLRBetaContext } from "../../contexts/btlrBeta.context";

import {
  BTLR_BETA_API_BASE,
  getGPTPromptURL,
  updateGPTPromptURL,
} from "../../api/base";

import { logoutBetaAdmin } from "../../api/helpers/authCookies";

import { buildBasicLoader } from "../../api/loaders/basic";

import InternalDashboardContainer from "./components/container";
import Button from "../../components/common/button/button";

const InternalGPTPromptConfig = () => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [gptVariables, setGptVariables] = useState([]);
  const [promptJSON, setPromptJSON] = useState();
  const [promptResult, setPromptResult] = useState();
  const [jsonError, setJSONError] = useState();

  const onChange = useCallback(
    (value) => {
      try {
        setPromptJSON(value);
        updatePromptResultString(JSON.parse(value));
      } catch (error) {
        console.log(error, gptVariables);
        setJSONError(error.toString());
      }
    },
    [gptVariables]
  );

  const updatePromptResultString = (promptCode) => {
    setJSONError();
    let promptText = "";
    for (const item of promptCode) {
      if (item.type === "TEXT") promptText += " " + item.value;
      if (item.type === "VARIABLE") {
        const backendVal = find(
          gptVariables,
          (itemInner) =>
            itemInner.value.toLowerCase() === item.value.toLowerCase()
        );
        if (backendVal) {
          promptText += " " + "${" + backendVal.backendValue + "}";
        } else {
          console.log(`Invalid variable:  "${item.value}"`, gptVariables);
          setJSONError(`Invalid variable:  "${item.value}"`);
        }
      }
    }

    setPromptResult(promptText);
  };

  const updateGPTPrompt = () => {
    const destroyLoader = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${updateGPTPromptURL}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
      body: JSON.stringify({ promptValues: JSON.parse(promptJSON) }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          toast.success("Prompt updated!", { theme: "dark" });
        } else {
          toast.error(data?.message ?? "Error while updating prompt");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(() => destroyLoader());
  };

  useEffect(() => {
    if (!BTLRBeta?.internalPagesUnlocked) return;

    const destroyLoader = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${getGPTPromptURL}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.promptData) {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        } else {
          const { promptValues, promptVariables } = data.promptData;

          setGptVariables(promptVariables);
          setPromptJSON(JSON.stringify(promptValues, null, 2));
          setPromptResult(data?.promptText);
        }
      })
      .finally(() => destroyLoader());
  }, [BTLRBeta?.internalPagesUnlocked]);

  return (
    <InternalDashboardContainer>
      <h2 className="heading black_text basier_medium">Manage Prompts</h2>
      <p className="sub_heading semi manrope lightBlack fourHundred">
        Update all prompts here
      </p>

      <div className="white_box">
        <div className="prompt_editor_top_bar">
          <div className="left">
            <h2 className="heading medium black_text basier_medium">
              Get Guest Preference
            </h2>
            <p className="text manrope light_black_text">
              For businesses to generate a guest preferences in BETA app.
            </p>
          </div>
          <div className="right">
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"blue"}
              text={"Update"}
              callback={updateGPTPrompt}
            />
          </div>
        </div>
        <div className="editor">
          <CodeMirror
            value={promptJSON}
            height="200px"
            extensions={[json()]}
            onChange={onChange}
            theme={"dark"}
            autoFocus
            minHeight="800px"
          />

          {jsonError && (
            <p className="text red_text">
              {typeof jsonError === "string"
                ? jsonError
                : JSON.stringify(jsonError)}
            </p>
          )}

          <div className="gpt_variables">
            {gptVariables.map(({ value }) => {
              return (
                <button
                  key={value}
                  className="gpt_variable btn pill small black"
                  onClick={() => copy(value)}
                >
                  {value}
                </button>
              );
            })}
          </div>

          <h3
            className="sub_heading semi manrope black_text"
            style={{ marginTop: "30px" }}
          >
            Result
          </h3>
          <p className="text manrope light_black_text">{promptResult}</p>
        </div>
      </div>
    </InternalDashboardContainer>
  );
};

export default InternalGPTPromptConfig;
