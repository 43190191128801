import React, { useContext } from "react";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";

import { BTLR_BETA_API_BASE, BTLR_BID, adminPreference } from "../../api/base";

// import { logoutBetaAdmin } from "../../api/helpers/authCookies";

import InternalDashboardContainer from "./components/container";

import AdminEditPreferencesMain from "./components/adminEditPreferencesMain";

const Preferences = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  return (
    <InternalDashboardContainer>
      <AdminEditPreferencesMain
        API_URL={BTLR_BETA_API_BASE + adminPreference}
        businessId={BTLR_BID}
        token={BTLRBeta?.betaAdminToken}
      />
    </InternalDashboardContainer>
  );
};

export default Preferences;
