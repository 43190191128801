import React, { useContext } from "react";

import { LangugageProvider } from "./language.context";
import { ActionsProvider } from "./actions.context";
import { LoggedDataProvider } from "./loggedData.context";
import { BTLRBetaProvider } from "./btlrBeta.context";

const AllContexts = ({ children }) => {
  return (
    <LangugageProvider>
      <ActionsProvider>
        <LoggedDataProvider>
          <BTLRBetaProvider>{children}</BTLRBetaProvider>
        </LoggedDataProvider>
      </ActionsProvider>
    </LangugageProvider>
  );
};

export default AllContexts;
