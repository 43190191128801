import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";

import {
  BTLR_BETA_API_BASE,
  betaGetBusinessesList,
  betaBusinessEmailUpdate,
  betaBusinessDelete,
  betaAdminGetBusinessDetails,
  betaAdminManuallyVerifyBusiness,
} from "../../api/base";

import {
  getBusinessMembersBeta,
  inviteBusinessMemberBeta,
  betaBusinessAcceptMember,
  betaBusinessRejectMember,
  betaBusinessUpdateMemberRole,
  getUserDetails,
  updateUserDetails,
  deleteUser,
} from "./service";

import { logoutBetaAdmin } from "../../api/helpers/authCookies";

import InternalDashboardContainer from "./components/container";
import Modal from "../../components/common/modal/modal";
import Button from "../../components/common/button/button";
import Input from "../../components/common/input/input";
import BusinessListAdmin from "./components/businessList";
import ConfirmationModal from "../../components/common/confirmationModal/confirmationModal";
import AccountDetailsModal from "./components/accountDetailsModal";
import SharePreferencesModal from "../b2b/businessbeta/components/sharePreferencesModal";
import PersonalAccountModal from "./components/personalAccountEditForm";

import { buildBasicLoader } from "../../api/loaders/basic";

import { location_svg } from "../../assets/svgs/icons";

const InternalBusinessAccounts = () => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [tabs] = useState([
    { title: "Registered" },
    { title: "🤚 Email Needed" },
    { title: "✔ Verification Needed" },
    { title: "📨 Invited" },
    { title: "👀 Viewed Not Reg" },
  ]);

  const [tab, setTab] = useState(0);
  // Business lists
  const [businessList, setBusinessList] = useState([]);
  const [emailNeededList, setEmailNeededList] = useState([]);
  const [invitedList, setInvitedList] = useState([]);
  const [verificationNeededList, setVerificationNeededList] = useState([]);
  const [unregisteredList, setUnregisteredList] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [confirmDeleteBusiness, setConfirmDeleteBusiness] = useState(false);
  const [selectedBusinessAccount, setSelectedBusinessAccount] = useState({});

  // User data
  const [editUser, setEditUser] = useState();

  const fetchRegisteredBusinesses = () => {
    // All list
    fetch(`${BTLR_BETA_API_BASE}${betaGetBusinessesList}all`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.message) {
          const parsedList = data.map((b) => {
            return { ...b, registered: true };
          });
          setBusinessList(parsedList);
        } else {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const fetchEmailNeededBusinesses = () => {
    // Email needed list
    fetch(`${BTLR_BETA_API_BASE}${betaGetBusinessesList}email-required`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.message) {
          const parsedList = data.map((b) => {
            return { ...b, emailNeeded: true };
          });
          setEmailNeededList(parsedList);
        } else {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const fetchVerificationNeededList = () => {
    // Verification needed list
    fetch(`${BTLR_BETA_API_BASE}${betaGetBusinessesList}verification-needed`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.message) {
          const parsedList = data.map((b) => {
            return { ...b, verifificationNeeded: true };
          });
          setVerificationNeededList(parsedList);
        } else {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const fetchInvitedList = () => {
    // Invited list
    fetch(`${BTLR_BETA_API_BASE}${betaGetBusinessesList}invited`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.message) {
          const parsedList = data.map((b) => {
            return { ...b, resendEmail: true };
          });
          setInvitedList(parsedList);
        } else {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const fetchUnregisteredList = () => {
    // Unregistered list
    fetch(`${BTLR_BETA_API_BASE}${betaGetBusinessesList}not-registered`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.message) {
          const parsedList = data.map((b) => {
            return { ...b, unregistered: true };
          });
          setUnregisteredList(parsedList);
        } else {
          toast.error(data?.message ?? "Wrong password");
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const fetchAllBusinesses = () => {
    fetchRegisteredBusinesses();
    fetchEmailNeededBusinesses();
    fetchVerificationNeededList();
    fetchInvitedList();
    fetchUnregisteredList();
  };

  const updateBusinessEmail = () => {
    console.log(selectedBusiness);
    if (
      !selectedBusiness.newEmail &&
      !selectedBusiness?.joinedData?.user?.[0]?.email
    ) {
      toast.error("Enter an email for business first");
      return;
    }
    const body = {
      email: selectedBusiness?.newEmail
        ? selectedBusiness.newEmail
        : selectedBusiness?.joinedData?.user?.[0]?.email,
      businessId: selectedBusiness?._id,
    };

    fetch(`${BTLR_BETA_API_BASE}${betaBusinessEmailUpdate}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status) {
          toast.success(data?.message ?? "Email address updated");
          setSelectedBusiness(null);
          fetchAllBusinesses();
        } else {
          toast.error(data?.message ?? "Email update failed");
          // logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      });
  };

  const deleteBusiness = () => {
    console.log(selectedBusiness);
    if (!selectedBusiness._id) {
      toast.error("Invalid business");
      return;
    }

    fetch(`${BTLR_BETA_API_BASE}${betaBusinessDelete}${selectedBusiness._id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status) {
          toast.success(data?.message ?? "Business deleted.");
          setSelectedBusiness(null);
          fetchAllBusinesses();
        } else {
          toast.error(data?.message ?? "Failed to delete business. Try again.");
          // logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(() => {
        setSelectedBusiness(null);
        fetchAllBusinesses();
      });
  };

  const manuallyVerifyBusiness = (id, approve = "APPROVE") => {
    const destroy = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${betaAdminManuallyVerifyBusiness}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
      body: JSON.stringify({
        status: approve, //"APPROVE", "REJECT"
        businessId: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status) {
          toast.success(data?.message ?? "Business verified.");
          fetchAllBusinesses();
        } else {
          toast.error(data?.message ?? "Failed to verify business. Try again.");
          // logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(() => {
        setSelectedBusiness(null);
        destroy();
      });
  };

  const getBusinessDetails = (id) => {
    const destroy = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${betaAdminGetBusinessDetails}${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setSelectedBusinessAccount({
            type: "business-details",
            businessData: data?.business,
          });
          setBTLRBeta({
            ...BTLRBeta,
            AccountDetailsModalAdmin: true,
          });
        } else {
          toast.error(data?.message ?? "Failed to fetch business. Try again.");
          // logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(destroy);
  };

  const showBusinessUserDetails = (id) => {
    getUserDetails(
      id,
      (user) => {
        setEditUser(user);
        setBTLRBeta({ ...BTLRBeta, personalAccountModal: true });
      },
      BTLRBeta?.betaAdminToken
    );
  };

  useEffect(() => {
    if (!BTLRBeta?.internalPagesUnlocked) return;

    fetchAllBusinesses();
  }, [BTLRBeta?.internalPagesUnlocked]);

  return (
    <InternalDashboardContainer>
      <h2 className="heading black_text basier_medium">Businesses</h2>
      <p className="sub_heading semi manrope lightBlack fourHundred">
        List of all BTLR businesses.
      </p>
      <div className="tabs">
        {tabs.map(({ title }, index) => {
          let counter = 0;
          if (index === 0) counter = businessList.length;
          if (index === 1) counter = emailNeededList.length;
          if (index === 2) counter = verificationNeededList.length;
          if (index === 3) counter = invitedList.length;
          if (index === 4) counter = unregisteredList.length;
          return (
            <button
              key={index}
              className={`tab ${index === tab ? "active" : ""}`}
              onClick={() => setTab(index)}
            >
              <p className="title">{title}</p>
              <p className="counter">{counter}</p>
            </button>
          );
        })}
      </div>

      {tab === 0 && (
        <BusinessListAdmin
          renderList={businessList}
          BTLRBeta={BTLRBeta}
          setBTLRBeta={setBTLRBeta}
          setSelectedBusiness={setSelectedBusiness}
          manuallyVerifyBusiness={manuallyVerifyBusiness}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          getBusinessDetails={getBusinessDetails}
        />
      )}
      {tab === 1 && (
        <BusinessListAdmin
          renderList={emailNeededList}
          BTLRBeta={BTLRBeta}
          setBTLRBeta={setBTLRBeta}
          setSelectedBusiness={setSelectedBusiness}
          manuallyVerifyBusiness={manuallyVerifyBusiness}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          getBusinessDetails={getBusinessDetails}
        />
      )}
      {tab === 2 && (
        <BusinessListAdmin
          renderList={verificationNeededList}
          BTLRBeta={BTLRBeta}
          setBTLRBeta={setBTLRBeta}
          setSelectedBusiness={setSelectedBusiness}
          manuallyVerifyBusiness={manuallyVerifyBusiness}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          getBusinessDetails={getBusinessDetails}
        />
      )}
      {tab === 3 && (
        <BusinessListAdmin
          renderList={invitedList}
          BTLRBeta={BTLRBeta}
          setBTLRBeta={setBTLRBeta}
          setSelectedBusiness={setSelectedBusiness}
          manuallyVerifyBusiness={manuallyVerifyBusiness}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          getBusinessDetails={getBusinessDetails}
        />
      )}
      {tab === 4 && (
        <BusinessListAdmin
          renderList={unregisteredList}
          BTLRBeta={BTLRBeta}
          setBTLRBeta={setBTLRBeta}
          setSelectedBusiness={setSelectedBusiness}
          manuallyVerifyBusiness={manuallyVerifyBusiness}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          getBusinessDetails={getBusinessDetails}
        />
      )}

      <Modal
        open={selectedBusiness}
        opener={setSelectedBusiness}
        backgroundColor={"transparent"}
        blur={true}
      >
        {selectedBusiness && (
          <div className="white_box">
            <h1 className="heading medium basier black_text">
              {selectedBusiness?.businessName}
            </h1>
            <p className="text medium manrope manrope black_text">
              Enter the email you want to assign to this business.
            </p>
            <p
              className="text small manrope manrope black_text"
              style={{
                color: "rgba(38, 91, 129, 1)",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                marginBottom: "20px",
              }}
            >
              {location_svg}{" "}
              {selectedBusiness?.address
                ? selectedBusiness?.address
                : selectedBusiness?.joinedData?.user?.[0]?.email}
            </p>

            <Input
              type="text"
              label={"Business Email"}
              defaultValue={selectedBusiness?.joinedData?.user?.[0]?.email}
              validationCallback={(value) =>
                setSelectedBusiness({
                  ...selectedBusiness,
                  newEmail: value,
                })
              }
              enterPressEvent={() => updateBusinessEmail()}
            />
            {!selectedBusiness?.unregistered && (
              <div className="update_business_btns">
                <Button
                  type={"roundedSquare"}
                  size={"medium"}
                  color={"blue"}
                  text={"Update & Send"}
                  callback={() => updateBusinessEmail()}
                />
                <Button
                  type={"roundedSquare"}
                  size={"medium"}
                  color={"red"}
                  text={"Delete Account"}
                  callback={() => setConfirmDeleteBusiness(true)}
                />
              </div>
            )}
          </div>
        )}
      </Modal>

      <ConfirmationModal
        open={confirmDeleteBusiness}
        opener={setConfirmDeleteBusiness}
        title={"Delete Account??"}
        info={
          "Confirm you want to delete this account? This action cannot be undone."
        }
        confirmButtonText={"Delete Account"}
        cancelButtonText={"Cancel"}
        confirmButtonColor={"dark_red"}
        confirmButtonCallback={() => {
          deleteBusiness();
        }}
      />

      {selectedBusinessAccount && (
        <AccountDetailsModal
          data={selectedBusinessAccount}
          refresh={fetchAllBusinesses}
          resetData={setSelectedBusinessAccount}
        />
      )}

      <SharePreferencesModal
        type="white"
        businessId={BTLRBeta?.adminEditBusinessId}
        businessName={BTLRBeta?.adminEditBusinessName}
        admin_id={"admin"}
        adminAccess={true}
        getBusinessMembersBeta={getBusinessMembersBeta}
        inviteBusinessMemberBeta={inviteBusinessMemberBeta}
        betaBusinessAcceptMember={betaBusinessAcceptMember}
        betaBusinessRejectMember={betaBusinessRejectMember}
        betaBusinessUpdateMemberRole={betaBusinessUpdateMemberRole}
        showUserDetailsForm={showBusinessUserDetails}
        authToken={BTLRBeta?.betaAdminToken}
      />

      <PersonalAccountModal
        userData={editUser}
        updateUserDetails={(id, values) => {
          updateUserDetails(
            id,
            values,
            () => {
              setEditUser(null);
              setBTLRBeta({
                ...BTLRBeta,
                personalAccountModal: false,
                sharePreferenceModal: false,
                adminEditBusinessId: null,
                adminEditBusinessName: null,
              });
            },
            BTLRBeta?.betaAdminToken
          );
        }}
        deleteUser={(id) => {
          deleteUser(
            id,
            () => {
              setEditUser(null);
              setBTLRBeta({ ...BTLRBeta, personalAccountModal: false });
            },
            BTLRBeta?.betaAdminToken
          );
        }}
      />
    </InternalDashboardContainer>
  );
};

export default InternalBusinessAccounts;
