import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BTLRBetaContext } from '../../../contexts/btlrBeta.context';

import BetaContainer from '../betaContainer';
import Button from '../../../components/common/button/button';
import Input from '../../../components/common/input/input';
import ConfirmationModal from '../../../components/common/confirmationModal/confirmationModal';
import HybridModal from '../../../components/common/hybridModal/hybridModal';
import PillsInput from '../../../components/common/pillsInput/pillsInput';

import { search_magnifier_svg } from '../../../assets/svgs/icons';
import {
  add_blue_without_circle,
  editCustomCatIcon,
  go_back_grey,
  right_arrow,
} from '../../../assets/svgs/action_icons';
import preferences_cover from '../../../assets/images/b2c/preferences/cover.png';

import { buildBasicLoader } from '../../../api/loaders/basic';
import {
  BTLR_BETA_API_BASE,
  S3_IMAGE_BASE_URL_BETA,
  getBusinessPreferencesURL,
  BTLR_BID,
  getAllUserPreferences,
} from '../../../api/base';
import {
  getUserDataBeta,
  updateUserDataBeta,
  createCustomPreferenceAPI,
  updateCustomPreferenceAPI,
  deleteCustomPreferenceAPI,
} from '../account/service';
import { checkLoginCookieBeta } from '../../../api/helpers/authCookies';
import {
  findIndexOfPreference,
  swapElements,
  customPrefTypes,
} from './pref_util';

import './styles.scss';

const EditPreferences = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const { discoverId } = useParams();

  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [searchedText, setSearchedText] = useState('');
  const [allPreferencesCategories, setAllPreferencesCategories] = useState([]);
  const [allPreferencesByBusiness, setAllPreferencesByBusiness] = useState([]); // All attached businesses preferences
  const [preferences, setPreferences] = useState([]); // To display
  const [selectedPreferenceListID, setSelectedPreferenceID] = useState('All'); // Business ID of selected preferences list
  const [thirdCategoryPopupParams, setThirdCategoryPopupParams] = useState({
    title: '',
    description: '',
    parentId: '',
  });
  const [levelThreeCategory, setLevelThreeCategory] = useState();
  const [prevThirdCategoryPopupParams, setPrevThirdCategoryPopupParams] =
    useState([]);
  const [prevLevelThreeCategory, setPrevLevelThreeCategory] = useState([]);
  const [prevLevelThreeSubCategoryAction, setPrevLevelThreeSubCategoryAction] =
    useState(null);
  const [thirdCategoryMainParentID, setThirdCategoryMainParentID] = useState();
  const [addNewPreference, setAddNewPreference] = useState();
  const [editCustomPreference, setEditCustomPreference] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openOtherCategoryDeleteModal, setOpenOtherCategoryDeleteModal] =
    useState(false);
  const [savePreferenceError, setSavePreferenceError] = useState(false);
  const [addNewCustomCategory, setAddNewCustomCategory] = useState({
    show: false,
    title: '',
  });
  const [newCustomCategoryError, setNewCustomCategoryError] = useState(false);
  const [deletedSelectedPreferences, setDeletedSelectedPreferences] = useState(
    []
  );
  const [seeAllPreferences, setSeeAllPreferences] = useState({});
  const [preferenceSearchResults, setPreferenceSearchResults] = useState([]);
  const [
    availableBusinessesForCustomPref,
    setAvailableBusinessesForCustomPref,
  ] = useState([]);
  const [discoverBusinessId, setDiscoverBusinessId] = useState(null);
  const [customCatSubCategoriesPopup, setCustomCatSubCategoriesPopup] =
    useState({ show: false, categoryTitle: '', list: null });
  const [
    customCatSubCategoriesPopupPrevLevels,
    setCustomCatSubCategoriesPopupPrevLevels,
  ] = useState([]);
  const [addOrEditCustomCategory, setAddOrEditCustomCategory] = useState(null);

  function findPreferenceByText(text) {
    let results = [];

    // // Custom Preferences
    let singleArrayCustomPreferences = [...BTLRBeta?.preferences?.custom];

    singleArrayCustomPreferences.forEach((pref) => {
      if (
        !pref?.isDeleted &&
        pref.title.toLowerCase().includes(text.toLowerCase())
      ) {
        results.push(pref);
      }
    });

    const resultPrefIds = results.map((pref) => pref?.category || pref?.title);
    console.log('Found results IDs: ', resultPrefIds);
    setPreferenceSearchResults(resultPrefIds);
  }

  function checkIfAnyChildPreferenceIsSelected(predefined, data) {
    for (let i = 0; i < data.length; i++) {
      const category = data[i];

      if (predefined.includes(category._id)) {
        return true;
      }

      if (category.subCategories && category.subCategories.length > 0) {
        if (
          checkIfAnyChildPreferenceIsSelected(
            predefined,
            category.subCategories
          )
        ) {
          return true;
        }
      }
    }

    return false; // Object not found
  }

  const unselectParentPreferenceWithNoChildren = (
    parentId,
    { predefined, custom },
    businessId
  ) => {
    let temp = { ...predefined };
    let tempB = temp[businessId];

    allPreferencesCategories.forEach((dp) => {
      if (dp?.business !== businessId) return;

      let categoryDeleted = dp.isDeleted;
      dp.subCategories.forEach((dpSub) => {
        if (categoryDeleted) return;

        if (dpSub._id === parentId) {
          if (dpSub?.subCategories && dpSub.subCategories.length) {
            const result = checkIfAnyChildPreferenceIsSelected(
              tempB,
              dpSub.subCategories
            );

            if (!result) {
              if (tempB.includes(thirdCategoryMainParentID))
                tempB.splice(tempB.indexOf(thirdCategoryMainParentID), 1);
            }

            setBTLRBeta({
              ...BTLRBeta,
              preferences: {
                ...BTLRBeta?.preferences,
                custom: custom,
                predefined: temp,
              },
            });
          }
        }
      });
    });
  };

  const createPreApprovedCustomPreferenceCopy = (
    { categoryTitle, preferenceTitle, addParentNullCategory },
    businessId,
    predefined
  ) => {
    // First check if this custom category exists or not. If not, create it
    const tempC = [...BTLRBeta?.preferences?.custom];

    // Check if this already exists
    const existsCategory = tempC.filter(
      (c) =>
        c?.title?.toLowerCase() === categoryTitle?.toLowerCase() && !c?.category
    );

    // Check if this custom preference already exists or not
    const existsPreference = tempC.filter(
      (c) =>
        c?.title?.toLowerCase() === preferenceTitle.toLowerCase() &&
        c?.category === categoryTitle
    );

    // Create category first if it doesn't exist
    if (!existsCategory?.length && addParentNullCategory) {
      tempC.push({
        title: categoryTitle,
        category: null,
        parentId: '',
        selected: true,
        linkedBusinesses: [],
      });
      if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
        createCustomPreferenceAPI(
          null,
          categoryTitle,
          [],
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken
        );
    }

    // Now lets add preapproved preferences
    if (preferenceTitle && !existsPreference?.length) {
      const tempLinked =
        businessId === 'All'
          ? []
          : [
              {
                _id: businessId,
                businessId,
                status: customPrefTypes.accepted,
                preApproved: true,
              },
            ];
      const newCustomPreference = {
        title: preferenceTitle,
        category: categoryTitle,
        status: customPrefTypes.accepted,
        linkedBusinesses: tempLinked,
      };

      tempC.push(newCustomPreference);

      if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
        createCustomPreferenceAPI(
          categoryTitle,
          preferenceTitle,
          tempLinked,
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken
        );
    }

    // Save to context
    setBTLRBeta({
      ...BTLRBeta,
      preferences: {
        predefined,
        custom: tempC,
      },
    });
  };

  const selectPreference = (id, businessId, copyCustomCatData) => {
    const temp = { ...BTLRBeta?.preferences?.predefined };
    if (!temp?.[businessId]) temp[businessId] = [];
    const tempB = temp[businessId];

    if (!tempB.includes(id)) {
      tempB.push(id);
      if (thirdCategoryMainParentID) {
        // Check the parent ID too
        if (!tempB.includes(thirdCategoryMainParentID))
          tempB.push(thirdCategoryMainParentID);
      }
      setBTLRBeta({
        ...BTLRBeta,
        preferences: {
          ...BTLRBeta?.preferences,
          predefined: temp,
        },
      });

      createPreApprovedCustomPreferenceCopy(
        copyCustomCatData,
        businessId,
        temp
      );
    } else {
      tempB.splice(tempB.indexOf(id), 1);

      // Remove parent Id if all children removed
      if (thirdCategoryMainParentID) {
        unselectParentPreferenceWithNoChildren(
          thirdCategoryMainParentID,
          {
            predefined: temp,
            custom: [...BTLRBeta.preferences.custom],
          },
          businessId
        );
      } else {
        setBTLRBeta({
          ...BTLRBeta,
          preferences: {
            ...BTLRBeta?.preferences,
            predefined: temp,
          },
        });
      }
    }
  };

  const addNewCustomCategoryFunc = () => {
    const userSelectedBusinessId = selectedPreferenceListID;

    if (!addNewCustomCategory?.title) return;

    const tempC = [...BTLRBeta?.preferences?.custom];

    // Check if this already exists
    const exists = tempC.filter(
      (c) =>
        c?.title?.toLowerCase() === addNewCustomCategory.title.toLowerCase() &&
        !c?.category
    );

    if (exists.length > 0) {
      setEditCustomPreference(null);
      setAddNewPreference({
        _id: addNewCustomCategory?.title,
        title: addNewCustomCategory?.title,
        type: 'customCategory',
        category: addNewCustomCategory?.title,
        ids: [
          {
            businessId: userSelectedBusinessId,
            status: customPrefTypes.pending,
          },
        ],
      });
      return;
    }

    tempC.push({
      title: addNewCustomCategory?.title,
      category: null,
      parentId: '',
      selected: true,
      linkedBusinesses: [],
    });

    if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
      createCustomPreferenceAPI(
        null,
        addNewCustomCategory?.title,
        [],
        checkLoginCookieBeta() ?? BTLRBeta?.loginToken
      );

    setBTLRBeta({
      ...BTLRBeta,
      preferences: {
        ...BTLRBeta?.preferences,
        custom: tempC,
      },
    });

    // setAddNewCustomCategory(false);
    setEditCustomPreference(null);
    setAddNewPreference({
      _id: addNewCustomCategory?.title,
      title: addNewCustomCategory?.title,
      type: 'customCategory',
      category: addNewCustomCategory?.title,
      ids: [
        { businessId: userSelectedBusinessId, status: customPrefTypes.pending },
      ],
    });
  };

  const saveNewPreference = async ({ value, newValue, category, ids }) => {
    // Value is old value, and newValue is newly typed one. Old value used for search for update
    if (!value && !newValue) {
      setSavePreferenceError('Please enter preference title before saving');
      return;
    }

    let businessIds = ids?.length ? ids : []; // Add to all if no business ID is selected

    const tempC = [...BTLRBeta?.preferences?.custom];

    const linkedBusinesses = ids?.length
      ? ids?.[0]?.businessId === 'All'
        ? []
        : [...businessIds].map(
            ({ key: id, status: existingStatus, googlePlaceID, ...rest }) => {
              return {
                _id: !googlePlaceID ? id : undefined,
                googlePlaceID,
                businessId: id,
                status: existingStatus ?? customPrefTypes.pending, // If edited, make it pending again,
                ...rest,
              };
            }
          )
      : [];

    const aNewGoogleBusinessAdded =
      linkedBusinesses.filter((lb) => lb?.googlePlaceID !== undefined).length >
      0;

    if (!editCustomPreference) {
      const existingPreferenceIndex = tempC.findIndex(
        (c) =>
          c?.title?.toLowerCase() === newValue?.toLowerCase() &&
          c?.category === category
      );

      if (existingPreferenceIndex > -1) {
        setSavePreferenceError('This preference already exists');
        return;
      }

      const newCustomPreference = {
        title: newValue,
        category: category,
        status: !linkedBusinesses?.length
          ? customPrefTypes.accepted
          : customPrefTypes.pending,
        preApproved: !linkedBusinesses?.length ? true : false,
        linkedBusinesses,
      };

      tempC.push(newCustomPreference);

      // Show in popup too
      if (
        customCatSubCategoriesPopup?.show &&
        customCatSubCategoriesPopup?.categoryTitle === category
      )
        setCustomCatSubCategoriesPopup({
          ...customCatSubCategoriesPopup,
          list: [...customCatSubCategoriesPopup?.list, newCustomPreference],
        });

      if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
        createCustomPreferenceAPI(
          category,
          newValue,
          linkedBusinesses,
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken
        );

      // If a new google business added

      if (aNewGoogleBusinessAdded) {
        console.log('What to do here?');
      } else {
        setBTLRBeta({
          ...BTLRBeta,
          preferences: {
            ...BTLRBeta?.preferences,
            custom: tempC,
          },
        });
      }
    } else {
      const existingPreferenceIndex = tempC.findIndex(
        (c) =>
          c?.title?.toLowerCase() === value?.toLowerCase() &&
          c?.category === category
      );
      if (existingPreferenceIndex < 0) return;

      console.log(
        'EDITING THIS ONE',
        tempC[existingPreferenceIndex],
        findTotalSubCategoriesOfACustomCat(BTLRBeta?.preferences?.custom, value)
      );

      const childEditUploadFunctions = [];

      // Edit category of children
      const subCats = findTotalSubCategoriesOfACustomCat(
        BTLRBeta?.preferences?.custom,
        value
      );
      subCats.forEach((sc) => {
        const existingSubPreferenceIndex = tempC.findIndex(
          (c) =>
            c?.title?.toLowerCase() === sc?.title?.toLowerCase() &&
            c?.category === sc?.category
        );
        if (existingPreferenceIndex < 0) return;

        const oldCopy = { ...tempC[existingSubPreferenceIndex] };

        tempC[existingSubPreferenceIndex].category = newValue;

        if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
          childEditUploadFunctions.push(() =>
            updateCustomPreferenceAPI(
              oldCopy?.category,
              newValue,
              oldCopy?.title,
              oldCopy?.title,
              oldCopy?.linkedBusinesses,
              checkLoginCookieBeta() ?? BTLRBeta?.loginToken
            )
          );
      });

      // Edit main category
      if (newValue) tempC[existingPreferenceIndex].title = newValue; // Edit title only if edited
      if (newValue)
        tempC[existingPreferenceIndex].status = customPrefTypes.pending; // Edit title only if edited
      // Update linked businesses
      tempC[existingPreferenceIndex].linkedBusinesses = linkedBusinesses;

      if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
        updateCustomPreferenceAPI(
          category,
          category,
          value,
          newValue,
          linkedBusinesses,
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken,
          childEditUploadFunctions
        );

      if (aNewGoogleBusinessAdded) {
        console.log('What to do here?');
      } else {
        setBTLRBeta({
          ...BTLRBeta,
          preferences: { ...BTLRBeta.preferences, custom: tempC },
        });
      }
    }

    setAddNewCustomCategory(null);
    setEditCustomPreference(null);
    setAddNewPreference(null);
  };

  const deleteCustomPreference = () => {
    if (!editCustomPreference || !editCustomPreference.value) return;

    const tempC = [...BTLRBeta?.preferences?.custom];

    let index = tempC
      .map((c) => c?.title?.toLowerCase())
      .indexOf(editCustomPreference.value.toLowerCase());

    if (!tempC[index]) return;

    const deletedCustomPreference = tempC.splice(index, 1);

    // Hide in popup too
    if (customCatSubCategoriesPopup?.list) {
      const temp = customCatSubCategoriesPopup?.list.filter(
        (c) =>
          c?.title?.toLowerCase() !== editCustomPreference.value.toLowerCase()
      );
      setCustomCatSubCategoriesPopup({
        ...customCatSubCategoriesPopup,
        list: temp,
      });
    }

    if (checkLoginCookieBeta() || BTLRBeta?.loggedIn)
      deleteCustomPreferenceAPI(
        deletedCustomPreference?.[0]?.category,
        deletedCustomPreference?.[0]?.title,
        checkLoginCookieBeta() ?? BTLRBeta?.loginToken
      );

    setBTLRBeta({
      ...BTLRBeta,
      preferences: { ...BTLRBeta.preferences, custom: tempC },
    });

    // unselectParentPreferenceWithNoChildren(
    //   deletedCustomPreference[0]?.parentId,
    //   {
    //     predefined: { ...BTLRBeta.preferences.predefined },
    //     custom: tempC,
    //   },
    //   addNewPreference?.businessId
    // );

    setEditCustomPreference(null);
    setAddNewPreference(null);
  };

  const checkPrefBusinessIdLink = (pref, businessId) => {
    const { linkedBusinesses } = pref;

    if (!linkedBusinesses || !linkedBusinesses?.length) return true;

    const found = linkedBusinesses.find(
      (lb) => lb.businessId === businessId || selectedPreferenceListID === 'All'
    );

    return found;
  };

  const findTotalSubCategoriesOfACustomCat = (list, catTitle, bId) => {
    let total = [];

    if (!list?.length) return;

    list.forEach((c) => {
      if (
        c.category &&
        c.category.toLowerCase() === catTitle.toLowerCase() &&
        c.status !== customPrefTypes.rejected &&
        checkPrefBusinessIdLink(c, bId)
      ) {
        total.push(c);
      }
    });
    return total;
  };

  const customCategoryClickAction = (
    customPref, // Parent
    pref_sub, // Itself
    saveOldLevel = false,
    onlyEdit = false
  ) => {
    console.log(customPref, pref_sub);
    if (!onlyEdit) {
      // Finding subcategories
      const totalSubCategoriesSub = findTotalSubCategoriesOfACustomCat(
        BTLRBeta.preferences.custom,
        pref_sub.title,
        selectedPreferenceListID
      );

      // Save old level
      if (saveOldLevel && totalSubCategoriesSub?.length > 0) {
        setCustomCatSubCategoriesPopupPrevLevels([
          ...customCatSubCategoriesPopupPrevLevels,
          { ...customCatSubCategoriesPopup },
        ]);
      }

      if (totalSubCategoriesSub?.length) {
        console.log('Its subcategories', totalSubCategoriesSub);

        setCustomCatSubCategoriesPopup({
          show: true,
          parentCategory: customPref.title,
          categoryTitle: pref_sub.title,
          list: totalSubCategoriesSub,
        });

        return;
      }
    }

    setAddOrEditCustomCategory({
      create: () => {
        console.log('Add new custom preferences to custom category');
        setEditCustomPreference(null);
        const linkedIds = [
          {
            businessId: selectedPreferenceListID,
            status: customPrefTypes.pending,
          },
        ];
        setAddNewPreference({
          _id: pref_sub.title,
          title: pref_sub.title,
          category: pref_sub.title,
          type: 'customCategory',
          ids: linkedIds,
        });
      },
      edit: () => {
        console.log('Edit custom preference of custom category');
        setEditCustomPreference({
          value: pref_sub.title,
        });
        setAddNewPreference({
          title: customPref.title,
          _id: customPref.title,
          category: customPref.title,
          value: pref_sub.title,
          type: 'customCategory',
          ids: pref_sub?.linkedBusinesses,
        });
      },
    });
  };

  // To show deleted preferences (by admin) but selected by a user
  useEffect(() => {
    if (!allPreferencesCategories.length) return;

    let temp = [];

    const checkForDeletedPreferences = (data) => {
      data.forEach((dp) => {
        let categoryDeleted = dp.isDeleted;
        dp.subCategories.forEach((dpSub) => {
          if (
            BTLRBeta?.preferences?.predefined[dpSub?.business]?.includes(
              dpSub._id
            ) &&
            (dpSub.isDeleted || categoryDeleted)
          ) {
            console.log('here');
            temp.push(dpSub);

            if (dpSub?.subCategories?.length) {
              // recursion
              checkForDeletedPreferences(dpSub.subCategories);
            }
          }
        });
      });
    };

    checkForDeletedPreferences(allPreferencesCategories);

    setDeletedSelectedPreferences(temp);
  }, [allPreferencesCategories, BTLRBeta?.preferences?.predefined]);

  useEffect(() => {
    if (
      prevLevelThreeCategory.length > 0 &&
      prevThirdCategoryPopupParams.length > 0
    ) {
      // Pop last elements from both arrays
      const temp = [...prevLevelThreeCategory];
      const temp2 = [...prevThirdCategoryPopupParams];
      const lastLevelThreeCategory = temp.pop();
      const lastThirdCategoryPopupParams = temp2.pop();

      setPrevLevelThreeSubCategoryAction(() => () => {
        setLevelThreeCategory(lastLevelThreeCategory);
        setThirdCategoryPopupParams(lastThirdCategoryPopupParams);

        // Update lists
        setPrevLevelThreeCategory(temp);
        setPrevThirdCategoryPopupParams(temp2);
      });
    } else {
      setPrevLevelThreeSubCategoryAction(null);
      // setThirdCategoryMainParentID(null);
    }
  }, [prevLevelThreeCategory, prevThirdCategoryPopupParams]);

  const openPreferencesOfBusiness = (id = 'All', data = []) => {
    let preferencesData =
      data.length > 0 ? [...data] : [...allPreferencesByBusiness];
    let loadPreferencesArray = [];

    if (id === 'All') {
      preferencesData.forEach((pd) => {
        loadPreferencesArray = [...loadPreferencesArray, ...pd.preferences];
      });
    } else {
      const findArray = preferencesData.filter((pd) => pd.businessId === id);
      if (findArray.length > 0) {
        loadPreferencesArray = [...findArray[0].preferences];
      }
    }

    setSelectedPreferenceID(id);

    // for checking deleted ones
    setAllPreferencesCategories(loadPreferencesArray);

    // Normal flow
    let customList = [...loadPreferencesArray];
    customList = customList.filter((p) => !p.isDeleted);
    customList = customList.sort((p, p1) => p.order - p1.order);

    // Lets merge similar names predefined categories
    let temp = {};
    customList.forEach((p) => {
      if (!temp[p?.title]) {
        temp[p.title] = { ...p, subCategories: [...p?.subCategories] };
      } else {
        temp[p.title].subCategories = [
          ...temp[p.title].subCategories,
          ...p.subCategories,
        ];
      }
    });

    customList = Object.keys(temp).map((key) => temp[key]);

    console.log(customList);

    // Check for custom order
    const customOrder = searchParams.get('order');
    if (!customOrder) {
      setPreferences(customList);
    } else {
      const orderArray = customOrder.split(',');
      for (let i = 0; i < orderArray.length; i++) {
        try {
          const preferenceToSwap =
            loadPreferencesArray[parseInt(orderArray[i])];
          const prefPosition = findIndexOfPreference(
            customList,
            preferenceToSwap
          );
          const swapPosition = i;
          swapElements(customList, prefPosition, swapPosition);
        } catch (error) {
          console.error('Error while changing order', error);
        }
      }
      setPreferences(customList);
    }
  };

  const fetchAllPreferncesList = (loggedIn, BTLRBetaData) => {
    if (!loggedIn) return;

    // Get preferences
    const destroyLoader = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${getAllUserPreferences}`, {
      headers: {
        authorization: `Bearer ${
          checkLoginCookieBeta() ?? BTLRBeta?.loginToken
        }`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setAllPreferencesByBusiness([...data?.preferencesWithBusiness]);

          if (
            BTLRBeta?.preferences?.predefined &&
            BTLRBeta?.preferences?.custom
          ) {
            openPreferencesOfBusiness('All', [
              ...data?.preferencesWithBusiness,
            ]);
            setBTLRBeta({
              ...BTLRBeta,
              b2cNavGoBack: false,
            });
            return;
          }

          // Load selected preferences into context
          let predefinedPrefByBusiness = {};
          let customPrefByBusiness = [];

          data?.preferencesWithBusiness.forEach((pw) => {
            const { businessId, userPreferences } = pw;

            // Default empty arrays
            predefinedPrefByBusiness[businessId] = [];

            // Fill with data if it exists
            if (userPreferences?.predefined)
              predefinedPrefByBusiness[businessId] = [
                ...userPreferences?.predefined,
              ];
            if (userPreferences?.custom)
              userPreferences?.custom.forEach((cp) => {
                if (!cp?.userPreference?.title || cp?.userPreference?.name)
                  return;

                const existingIndex = customPrefByBusiness.findIndex(
                  (elem) =>
                    elem?.userPreference?._id === cp?.userPreference?._id
                );
                if (existingIndex < 0) {
                  customPrefByBusiness.push({
                    ...cp,
                    ...cp.userPreference,
                    linkedBusinesses: [
                      { _id: businessId, businessId, status: cp?.status },
                    ],
                  });
                } else {
                  customPrefByBusiness[existingIndex].linkedBusinesses.push({
                    businessId,
                    status: cp?.status,
                  });
                }
              });
          });

          customPrefByBusiness = [
            ...customPrefByBusiness,
            ...data?.preferencesWithoutBusiness,
          ];
          console.log('Custom pref now', customPrefByBusiness);

          setBTLRBeta({
            ...BTLRBetaData,
            b2cNavGoBack: false,
            businessId: discoverBusinessId,
            invited: discoverBusinessId,
            preferences: {
              predefined: predefinedPrefByBusiness,
              custom: customPrefByBusiness,
            },
          });

          openPreferencesOfBusiness('All', [...data?.preferencesWithBusiness]);
        } else {
          navigate('/personal/edit-preferences');
        }
      })
      .catch((error) => {
        console.error('Error fetching preferences', error);
        // navigate("/login");
      })
      .finally(() => destroyLoader());
  };

  // For saving data to db on any change
  useEffect(() => {
    if (checkLoginCookieBeta() || BTLRBeta?.loggedIn) {
      if (!BTLRBeta?.preferences?.predefined) return;
      console.log(BTLRBeta.preferences);

      let parsedData = [];

      // Set predefined data first
      const businessIds = Object.keys(BTLRBeta?.preferences?.predefined);
      businessIds.forEach((bId) => {
        const businessPreferencesObject = {
          businessId: bId,
          preferences: {
            predefined: [...BTLRBeta?.preferences.predefined[bId]],
          },
        };
        parsedData.push(businessPreferencesObject);
      });

      console.log({
        businessPreferences: parsedData,
        custom: BTLRBeta?.preferences.custom,
      });
      updateUserDataBeta(
        'preferences',
        {
          businessPreferences: parsedData,
          custom: [],
        },
        checkLoginCookieBeta() || BTLRBeta?.loggedIn,
        false,
        () => console.log('Preferences saved')
        // () => fetchAllPreferncesList(BTLRBeta?.loggedIn, { ...BTLRBeta })
      );
    }
  }, [BTLRBeta.preferences, BTLRBeta?.businessId]);

  // Compiling list of businesses available for linking to custom preferences
  useEffect(() => {
    if (!BTLRBeta?.businesses?.length) return;

    // const temp = [{ key: "64e2e38499cbf807fbddfcd1", title: "BTLR" }];
    const temp = [];

    BTLRBeta?.businesses.forEach((b) => {
      const { business: { _id, businessName } = {} } = b;
      if (!_id || !businessName) return;
      temp.push({ key: _id, title: businessName });
    });

    setAvailableBusinessesForCustomPref(temp);
  }, [BTLRBeta?.businesses]);

  useEffect(() => {
    if (!discoverId) setDiscoverBusinessId(null);
    else setDiscoverBusinessId(discoverId);
  }, [discoverId]);

  // Business preview page
  useEffect(() => {
    if (!BTLRBeta?.businessPreview) return;

    const { pageData, preferences: previewPreferences } =
      BTLRBeta?.businessPreview;

    if (!pageData || !previewPreferences) return;

    openPreferencesOfBusiness(pageData.business, [
      {
        businessId: pageData.business,
        preferences: previewPreferences,
      },
    ]);
  }, [BTLRBeta?.businessPreview]);

  useEffect(() => {
    const loginToken = checkLoginCookieBeta() ?? BTLRBeta.loginToken;

    if (loginToken && !BTLRBeta?.loggedIn) {
      try {
        getUserDataBeta(
          setBTLRBeta,
          BTLRBeta?.loginToken,
          fetchAllPreferncesList
        );
      } catch (error) {
        console.error('error with user data', error);
      }
    } else {
      fetchAllPreferncesList(BTLRBeta?.loggedIn, { ...BTLRBeta }, true);
    }
  }, []);

  return (
    <BetaContainer>
      <div
        className={`inner_container inner_container_beta padding_bottom ${
          levelThreeCategory || customCatSubCategoriesPopup?.show
            ? 'two_column'
            : ''
        }`}
        style={{ paddingTop: searchParams.get('hideHeader') ? '30px' : '' }}>
        <div className="left">
          {(BTLRBeta?.invited ||
            !(checkLoginCookieBeta() || BTLRBeta?.loggedIn) ||
            BTLRBeta?.businessPreview) && (
            <div className="top_right_button" style={{ paddingTop: '34px' }}>
              <Button
                type="pill"
                color="blue"
                size="small"
                extraClass="flex"
                callback={() => {
                  if (BTLRBeta?.businessPreview?.pageData?.enableSharePage)
                    navigate(`/personal/share-preferences`);
                  else navigate(`/personal/account`);
                }}>
                Next {right_arrow}
              </Button>
            </div>
          )}
          {BTLRBeta?.loggedIn && !BTLRBeta?.invited && !discoverBusinessId && (
            <div className="top_right_button">
              <Button
                type="pill"
                color="blue"
                size="small"
                extraClass="flex"
                callback={() => setAddNewCustomCategory({ show: true })}>
                {add_blue_without_circle} New
              </Button>
            </div>
          )}

          {BTLRBeta?.loggedIn && !BTLRBeta?.invited && !discoverBusinessId && (
            <div className="search_preferences_input">
              <Input
                name={'search_preferences'}
                type="text"
                label={'Search for preference'}
                defaultValue={searchedText}
                icon={search_magnifier_svg}
                validationCallback={(value) => {
                  if (!value) {
                    setPreferenceSearchResults([]);
                    return;
                  }
                  setSearchedText(value);
                  findPreferenceByText(value);
                }}
                noAnimation={true}
                greyInputs
              />
            </div>
          )}

          {BTLRBeta?.loggedIn && !BTLRBeta?.invited && !discoverBusinessId && (
            <div className="preferences_switcher hide_scrollbar horizontal_thumb">
              <button
                className={selectedPreferenceListID === 'All' ? 'active' : ''}
                onClick={() => openPreferencesOfBusiness()}>
                All
              </button>
              {allPreferencesByBusiness.map((pm, index) => {
                if (!pm?.businessName) return;
                if (pm?.businessId === BTLR_BID) return;
                return (
                  <button
                    key={index}
                    className={
                      selectedPreferenceListID === pm?.businessId
                        ? 'active'
                        : ''
                    }
                    onClick={() => openPreferencesOfBusiness(pm?.businessId)}>
                    {pm?.businessName}
                  </button>
                );
              })}
            </div>
          )}

          {(!BTLRBeta?.loggedIn || BTLRBeta?.invited || discoverBusinessId) && (
            <h1 className="heading basier center_align">
              {BTLRBeta?.businessPreview?.pageData?.heading ??
                `Want to be know everywhere?`}
            </h1>
          )}
          {(!BTLRBeta?.loggedIn || BTLRBeta?.invited || discoverBusinessId) && (
            <p className="inner_small_info light_grey_text center_align">
              {BTLRBeta?.businessPreview?.pageData?.subHeading ??
                'Save your preferences and feel like a VIP wherever you go.'}
            </p>
          )}
          {(!BTLRBeta?.loggedIn || BTLRBeta?.invited || discoverBusinessId) &&
            BTLRBeta?.businessPreview?.pageData?.image && (
              <div className="preferences_cover">
                <img
                  src={
                    BTLRBeta?.businessPreview?.pageData?.image
                      ? `${S3_IMAGE_BASE_URL_BETA}${BTLRBeta?.businessPreview?.pageData?.image}`
                      : preferences_cover // If we ever want to bring back default cover
                  }
                  alt="preferences_cover"
                />
              </div>
            )}

          {/* Personal profile custom prefernces tab */}
          {BTLRBeta?.loggedIn &&
            !discoverBusinessId &&
            BTLRBeta?.preferences?.custom?.length > 0 &&
            BTLRBeta.preferences.custom.map((customPref, index) => {
              if (!customPref?.title) return; // Filter out undefined values added by any error
              // Search results
              if (
                preferenceSearchResults?.length &&
                !preferenceSearchResults.includes(customPref?.title)
              ) {
                return;
              }

              if (
                customPref?.category ||
                customPref?.status === customPrefTypes.rejected
              )
                return; // Do not show sub ones here

              const totalSubCategories = findTotalSubCategoriesOfACustomCat(
                BTLRBeta.preferences.custom,
                customPref.title,
                selectedPreferenceListID
              );

              if (!totalSubCategories?.length) return;

              return (
                <div key={index} className="preferences_display">
                  <div className="preferences_options_header">
                    <p className="pref_heading">{customPref.title}</p>

                    <button
                      onClick={() => {
                        customCategoryClickAction(
                          {
                            title: null,
                          },
                          {
                            title: customPref.title,
                          },
                          false,
                          true
                        );
                      }}>
                      {editCustomCatIcon}
                    </button>
                  </div>
                  <div className="preferences">
                    <div className="preferences_row">
                      {totalSubCategories.map((pref_sub, index) => {
                        if (index > 6 && !seeAllPreferences[customPref?.title])
                          return;
                        if (!pref_sub?.title) return; // Filter out undefined values added by any error
                        if (
                          totalSubCategories > 7 &&
                          !seeAllPreferences[customPref?.title]
                        )
                          return;

                        return (
                          <div
                            key={index}
                            className={`cursor single_preference selected`}
                            onClick={() => {
                              customCategoryClickAction(customPref, pref_sub);
                            }}>
                            {pref_sub?.status === customPrefTypes.pending
                              ? '⏳'
                              : ''}{' '}
                            {pref_sub.title}
                          </div>
                        );
                      })}
                    </div>
                    {totalSubCategories?.length > 7 &&
                      !seeAllPreferences[customPref?.title] && (
                        <p
                          className="text blue_text cursor see_all"
                          onClick={() => {
                            console.log('See all');
                            setSeeAllPreferences({
                              ...seeAllPreferences,
                              [customPref?.title]: true,
                            });
                          }}>
                          See All
                        </p>
                      )}
                  </div>
                </div>
              );
            })}

          {/* Discover tab */}
          {(discoverBusinessId || BTLRBeta?.businessPreview) &&
            preferences.map((pref, index) => {
              if (discoverBusinessId && pref?.business !== discoverBusinessId)
                return;

              const otherCategory = pref.title.toLowerCase() == 'other';
              const mappingCategories = !otherCategory
                ? pref.subCategories.filter((ps) => ps.isDeleted !== true)
                : deletedSelectedPreferences;

              if (otherCategory && !mappingCategories?.length) return;

              const mainPrefBusinessId = pref?.business;

              // Search results
              if (
                preferenceSearchResults?.length &&
                !preferenceSearchResults.includes(pref.title)
              )
                return;

              return (
                <div key={index} className="preferences_display">
                  <div className="preferences_options_header">
                    <span>
                      <p className="pref_heading">{pref.title}</p>
                      <p className="pref_subheading">
                        {pref?.description && pref?.description !== ''
                          ? pref.description
                          : 'Preferences'}
                      </p>
                    </span>
                    {/* <>
                    {!otherCategory && (
                      <p
                        className="text blue_text cursor add_new_preference"
                        onClick={() => {
                          setEditCustomPreference(null);
                          const linkedIds = [
                            {
                              businessId: mainPrefBusinessId,
                              status: customPrefTypes.pending,
                            },
                          ];
                          setAddNewPreference({
                            title: pref.title,
                            category: pref.title,
                            _id: pref._id,
                            ids: linkedIds,
                          });
                        }}
                      >
                        {add_blue_bg}
                        Add
                      </p>
                    )}
                    </> */}
                  </div>
                  <div className="preferences">
                    <div className="preferences_row">
                      {mappingCategories
                        .sort((p, p1) => p.order - p1.order)
                        .map((pref_sub, index) => {
                          if (pref_sub.isDeleted && !otherCategory) return;
                          if (index > 7 && !seeAllPreferences[pref?._id])
                            return;

                          const prefBusinessId = pref_sub?.business;

                          return (
                            <div
                              key={index}
                              className={`cursor single_preference ${
                                BTLRBeta?.preferences?.predefined?.[
                                  prefBusinessId
                                ]?.includes(pref_sub._id) || pref_sub?.selected
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => {
                                if (otherCategory) {
                                  setOpenOtherCategoryDeleteModal(pref_sub._id);

                                  return;
                                }

                                const nonDeletedSubCategories =
                                  pref_sub.subCategories.filter(
                                    (psc) => !psc.isDeleted
                                  );

                                if (
                                  !nonDeletedSubCategories.length ||
                                  !nonDeletedSubCategories[0]?.title
                                ) {
                                  selectPreference(
                                    pref_sub._id,
                                    prefBusinessId,
                                    {
                                      categoryTitle: pref.title,
                                      preferenceTitle: pref_sub.title,
                                      addParentNullCategory: true,
                                    }
                                  );

                                  return;
                                } else {
                                  // If popup is opening
                                  setPrevLevelThreeCategory([]);
                                  selectPreference(
                                    pref_sub._id,
                                    prefBusinessId,
                                    {
                                      categoryTitle: pref.title,
                                      preferenceTitle: pref_sub.title,
                                      addParentNullCategory: true,
                                    }
                                  );
                                }

                                let customSubCat = [...pref_sub.subCategories];
                                customSubCat = customSubCat.sort(
                                  (p, p1) => p.order - p1.order
                                );
                                setLevelThreeCategory(customSubCat);
                                setThirdCategoryPopupParams({
                                  title: pref_sub.title,
                                  description:
                                    pref_sub?.description ?? 'Description',
                                  parentId: pref_sub._id,
                                  businessId: prefBusinessId,
                                });
                                setThirdCategoryMainParentID(pref_sub._id);
                              }}>
                              {pref_sub.title}
                            </div>
                          );
                        })}
                    </div>
                    {mappingCategories.length > 7 &&
                      !seeAllPreferences[pref?._id] && (
                        <p
                          className="text blue_text cursor see_all"
                          onClick={() => {
                            setSeeAllPreferences({
                              ...seeAllPreferences,
                              [pref?._id]: true,
                            });
                          }}>
                          See All
                        </p>
                      )}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="right">
          {/* For showing level three subcategory */}
          <HybridModal
            backgroundColor={'#0d0d0d'}
            dark={true}
            blur={true}
            open={levelThreeCategory}
            opener={() => {
              setThirdCategoryPopupParams(null);
              setLevelThreeCategory(null);
              setThirdCategoryMainParentID(null);
            }}
            autoHeight={true}>
            {levelThreeCategory && (
              <div className="inner_container popup mobile_bottom_padding">
                {prevLevelThreeSubCategoryAction && (
                  <button
                    className="moveToPreviousCategory"
                    onClick={prevLevelThreeSubCategoryAction}>
                    {go_back_grey}{' '}
                    {prevThirdCategoryPopupParams?.length
                      ? prevThirdCategoryPopupParams[
                          prevThirdCategoryPopupParams.length - 1
                        ]?.title
                      : 'Previous Category'}
                  </button>
                )}
                <div className="preferences_display" style={{ marginTop: 0 }}>
                  <div className="preferences_options_header">
                    <span>
                      <h1 className="pref_heading">
                        {thirdCategoryPopupParams?.title ?? 'All'}
                      </h1>
                      <p className="pref_subheading">
                        {thirdCategoryPopupParams?.description ?? 'Description'}
                      </p>
                    </span>
                  </div>
                  <div
                    className="preferences"
                    style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                    <div className="preferences_row">
                      {levelThreeCategory
                        .filter((ltc) => ltc?.isDeleted !== true)
                        .sort((p, p1) => p.order - p1.order)
                        .map((pref, index) => {
                          if (pref?.isDeleted) return;

                          return (
                            <div
                              key={index}
                              className={`cursor single_preference ${
                                BTLRBeta?.preferences?.predefined?.[
                                  pref?.business
                                    ? pref?.business
                                    : thirdCategoryPopupParams?.businessId
                                ]?.includes(pref._id) || pref?.selected
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => {
                                selectPreference(
                                  pref._id,
                                  thirdCategoryPopupParams.businessId,
                                  {
                                    categoryTitle:
                                      thirdCategoryPopupParams?.title,
                                    preferenceTitle: pref.title,
                                  }
                                );
                                // Check next level subcategories here
                                if (
                                  pref.subCategories &&
                                  pref.subCategories.length > 0
                                ) {
                                  setPrevLevelThreeCategory([
                                    ...prevLevelThreeCategory,
                                    [...levelThreeCategory],
                                  ]);
                                  setPrevThirdCategoryPopupParams([
                                    ...prevThirdCategoryPopupParams,
                                    {
                                      ...thirdCategoryPopupParams,
                                    },
                                  ]);
                                  const { title, description, _id } = pref;
                                  setThirdCategoryPopupParams({
                                    title: title,
                                    description: description ?? 'Description',
                                    parentId: _id,
                                    businessId:
                                      thirdCategoryPopupParams.businessId,
                                  });
                                  setLevelThreeCategory(
                                    pref.subCategories.filter(
                                      (ltc) => ltc?.isDeleted !== true
                                    )
                                  );
                                }
                              }}>
                              {pref.title}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </HybridModal>

          {/* For showing children of custom cats */}
          <HybridModal
            backgroundColor={'#0d0d0d'}
            dark={true}
            blur={true}
            open={customCatSubCategoriesPopup?.show}
            opener={() => {
              setCustomCatSubCategoriesPopup({
                show: false,
                categoryTitle: '',
                list: null,
              });
              setCustomCatSubCategoriesPopupPrevLevels([]);
            }}
            // autoHeight={true}
          >
            <div className="inner_container popup mobile_bottom_padding">
              {customCatSubCategoriesPopupPrevLevels?.length > 0 && (
                <button
                  className="moveToPreviousCategory"
                  onClick={() => {
                    const temp = [...customCatSubCategoriesPopupPrevLevels];
                    const previousLevel = temp.pop();
                    setCustomCatSubCategoriesPopup(previousLevel);
                    setCustomCatSubCategoriesPopupPrevLevels(temp);
                  }}>
                  {go_back_grey}{' '}
                  {customCatSubCategoriesPopupPrevLevels?.length
                    ? customCatSubCategoriesPopupPrevLevels[
                        customCatSubCategoriesPopupPrevLevels.length - 1
                      ]?.categoryTitle
                    : 'Previous Category'}
                </button>
              )}
              <div className="preferences_display" style={{ marginTop: 0 }}>
                <div className="preferences_options_header">
                  <span>
                    <h1 className="pref_heading">
                      {customCatSubCategoriesPopup?.categoryTitle ?? 'All'}
                    </h1>
                  </span>
                  <button
                    onClick={() => {
                      customCategoryClickAction(
                        {
                          title: customCatSubCategoriesPopup?.parentCategory,
                        },
                        {
                          title: customCatSubCategoriesPopup?.categoryTitle,
                        },
                        false,
                        true
                      );
                    }}>
                    {editCustomCatIcon}
                  </button>
                </div>
                <div
                  className="preferences"
                  style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                  <div className="preferences_row">
                    {customCatSubCategoriesPopup?.list?.map((pref, index) => {
                      return (
                        <div
                          key={index}
                          className={`cursor single_preference selected`}
                          onClick={() => {
                            customCategoryClickAction(
                              {
                                title:
                                  customCatSubCategoriesPopup?.categoryTitle,
                              },
                              pref,
                              true,
                              false
                            );
                          }}>
                          {pref.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </HybridModal>
        </div>
      </div>

      {/* For adding new custom category */}
      <HybridModal
        backgroundColor={'#0d0d0d'}
        dark={true}
        open={addNewCustomCategory?.show}
        opener={() => {
          setAddNewCustomCategory(null);
        }}
        autoHeight={true}
        hideCloseButton={true}
        blur>
        {addNewCustomCategory?.show && (
          <div className="inner_container popup">
            <div className="save_div">
              <Button
                type={'pill'}
                size={'small'}
                color={'blue_bg_effect'}
                text={'Next'}
                callback={() => {
                  if (!addNewCustomCategory?.title) {
                    setNewCustomCategoryError(true);
                    return;
                  }

                  addNewCustomCategoryFunc();
                }}
              />
            </div>
            <h1 className="pref_heading">Add New Category</h1>
            <p className="pref_subheading" style={{ marginBottom: '20px' }}>
              Name your preference category.
            </p>
            <Input
              name={addNewCustomCategory.title}
              label={'Type Name'}
              defaultValue={addNewCustomCategory?.title}
              validationCallback={(value) => {
                setNewCustomCategoryError(false);
                setAddNewCustomCategory({
                  ...addNewCustomCategory,
                  title: value,
                });
              }}
              enterPressEvent={() => {
                if (!addNewCustomCategory?.title) {
                  setNewCustomCategoryError(
                    'Please enter preference title before saving'
                  );
                  return;
                }

                addNewCustomCategoryFunc();
              }}
              greyInputs
            />
            {newCustomCategoryError && (
              <p className="red_text">{newCustomCategoryError}</p>
            )}

            <span style={{ display: 'block', marginBottom: '80px' }}></span>
          </div>
        )}
      </HybridModal>

      {/* For adding new preference */}
      <HybridModal
        backgroundColor={'#0d0d0d'}
        dark={true}
        open={addNewPreference}
        opener={() => {
          setSavePreferenceError(false);
          setAddNewPreference(null);
        }}
        autoHeight={true}
        hideCloseButton={true}
        blur>
        {addNewPreference && (
          <div className="inner_container popup">
            <div className="save_div">
              <Button
                type={'pill'}
                size={'small'}
                color={'blue_bg_effect'}
                text={'Save'}
                callback={() => saveNewPreference(addNewPreference)}
              />
            </div>
            {addNewCustomCategory?.title && (
              <button
                className="moveToPreviousCategory"
                onClick={() => setAddNewPreference(null)}>
                {go_back_grey}
              </button>
            )}
            <h1 className="pref_heading">
              {!editCustomPreference ? 'New Preference' : 'Edit Preference'}
            </h1>
            <p className="pref_subheading" style={{ marginBottom: '20px' }}>
              {addNewPreference?.title ?? 'Preferences'}
            </p>
            <Input
              name={addNewPreference._id}
              label={'Type Preference'}
              defaultValue={addNewPreference?.value}
              validationCallback={(value) => {
                setSavePreferenceError(false);
                setAddNewPreference({
                  ...addNewPreference,
                  newValue: value,
                });
              }}
              enterPressEvent={() => saveNewPreference(addNewPreference)}
              greyInputs
              autoFocus={true}
            />
            <PillsInput
              label={'Link To Specific Business'}
              prefilledList={availableBusinessesForCustomPref}
              preSelected={addNewPreference?.ids?.map((id) => {
                return {
                  ...id,
                  key: id?.businessId ?? id?.place_id,
                  status: id.status,
                };
              })}
              validationCallback={(ids) => {
                console.log('Val', ids);
                setAddNewPreference({ ...addNewPreference, ids });
              }}
              googleMapSearch={true}
            />
            {savePreferenceError && (
              <p className="red_text">{savePreferenceError}</p>
            )}
            {editCustomPreference && (
              <button
                className="delete_custom_preference"
                onClick={() => setOpenDeleteModal(true)}>
                Delete?
              </button>
            )}
            <span style={{ display: 'block', marginBottom: '80px' }}></span>
          </div>
        )}
      </HybridModal>

      {/* For confirmation of deleting custom preference */}
      <ConfirmationModal
        dark={true}
        open={openDeleteModal}
        opener={setOpenDeleteModal}
        title={'Delete Preference?'}
        info={
          'Confirm you want to permanently delete this preference? This action cannot be undone.'
        }
        confirmButtonText={'Delete Preference'}
        cancelButtonText={'Cancel'}
        confirmButtonColor={'dark_red'}
        confirmButtonCallback={deleteCustomPreference}
      />

      {/* For confirmation of deleting other category preference */}
      <ConfirmationModal
        dark={true}
        open={openOtherCategoryDeleteModal}
        opener={setOpenOtherCategoryDeleteModal}
        title={'Delete Preference?'}
        info={
          'Confirm you want to permanently delete this preference? This action cannot be undone.'
        }
        confirmButtonText={'Delete Preference'}
        cancelButtonText={'Cancel'}
        confirmButtonColor={'dark_red'}
        confirmButtonCallback={() => {
          selectPreference(openOtherCategoryDeleteModal);
          setOpenOtherCategoryDeleteModal(false);
        }}
      />

      {/* For adding sub category or editing custom preference */}
      <ConfirmationModal
        dark={true}
        open={addOrEditCustomCategory}
        opener={setAddOrEditCustomCategory}
        title={'Edit or Add a New Preference'}
        info={
          'By adding a new preference, it will embed it within this category.'
        }
        confirmButtonText={'Edit Preference'}
        cancelButtonText={'New preference'}
        confirmButtonColor={'darkgrey'}
        cancelButtonColor={'blue'}
        confirmButtonCallback={addOrEditCustomCategory?.edit}
        cancelButtonCallback={addOrEditCustomCategory?.create}
      />
    </BetaContainer>
  );
};

export default EditPreferences;
