import React, { useState, useEffect, useRef } from "react";

import { close_thin, go_back_grey } from "../../../assets/svgs/action_icons";
import "./slideInPopup.scss";

const SlideInPopup = ({
  open,
  opener,
  left = false,
  bottom = false,
  blue_bg = false,
  autoHeight = false,
  bottomLeftWidth = 0,
  hideCloseButton = false,
  backgroundColor = "#ffffff",
  dark = false,
  blur = false,
  backButtonAction,
  titleInsteadOfBar,
  children,
}) => {
  const [moveY, setMoveY] = useState(0);
  const [topSwipePosition, setTopSwipePosition] = useState(0);

  useEffect(() => {
    if (open) {
      const scrollPosition = window.scrollY;
      document.body.dataTop = `${scrollPosition}px`;
    } else {
      const scrollY = parseInt(document.body.dataTop);
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, scrollY);
    }
  }, [open]);

  const sliderRef = useRef(null);

  const handleTouchStart = (event) => {
    event.stopPropagation();
    if (!sliderRef?.current) return;

    // Remove transition
    sliderRef.current.style.transition = "none";

    setMoveY(event?.touches[0]?.clientY);
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
    if (!sliderRef?.current) return;

    const difference = moveY - event?.touches[0]?.clientY;
    setMoveY(event?.touches[0]?.clientY);
    setTopSwipePosition(topSwipePosition - difference);
  };

  const handleTouchEnd = (event) => {
    event.stopPropagation();

    if (topSwipePosition > window.innerHeight / 3.5) {
      // Re add transtion
      sliderRef.current.style.transition = "";
      setTopSwipePosition(window.innerHeight);
      setMoveY(0);
      setTimeout(() => {
        opener(false);
        setTopSwipePosition(0);
      }, 100);
      return;
    }

    // Re add transtion
    sliderRef.current.style.transition = "";

    setTopSwipePosition(0);
    setMoveY(0);
  };

  return (
    <div
      className={`slide_in_popup ${left ? "left" : ""} ${
        bottom ? "bottom" : ""
      } ${!open ? "close" : "open"} ${dark ? "dark" : ""}`}
      style={{ backdropFilter: !blur ? "none" : "" }}
      onClick={(e) => {
        if (e.target.classList.contains("slide_in_popup")) opener(false);
      }}
    >
      <div
        ref={sliderRef}
        className={`popup_container ${blue_bg ? "blue_bg" : ""} ${
          autoHeight ? "auto_height" : ""
        }`}
        style={{
          maxWidth: bottomLeftWidth ? `${bottomLeftWidth}px` : "",
          backgroundColor,
          transform:
            topSwipePosition > 0 ? `translateY(${topSwipePosition}px)` : "",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {!bottom && (
          <button className="close_btn" onClick={() => opener(false)}>
            {close_thin}
          </button>
        )}
        {bottom && (
          <div className="bar_container">
            {backButtonAction && (
              <button className="back_btn" onClick={backButtonAction}>
                {go_back_grey}
              </button>
            )}
            {!titleInsteadOfBar && <span className="bar"></span>}
            {titleInsteadOfBar && (
              <span className="bar title">{titleInsteadOfBar}</span>
            )}
            {!hideCloseButton && (
              <button className="close_btn" onClick={() => opener(false)}>
                {close_thin}
              </button>
            )}
          </div>
        )}
        <div className="popup_body">{children}</div>
      </div>
    </div>
  );
};

export default SlideInPopup;
