import React, { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";
import {
  BTLR_BETA_API_BASE,
  betaLoginVerifyEmailURL,
  betaLoginVerifyBusinessEmailURL,
} from "../../api/base";
import {
  setLoginCookieBeta,
  logoutBeta,
  setLoginCookieBetaBusiness,
} from "../../api/helpers/authCookies";

const VerifyBetaB2CLogin = ({ type = "customer" }) => {
  const { token } = useParams();

  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  useEffect(() => {
    if (type === "business") {
      // Business side login
      // console.log("Token", token);
      fetch(BTLR_BETA_API_BASE + betaLoginVerifyBusinessEmailURL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ magic: token }),
      })
        .then((response) => response.json())
        .then((data) => {
          const { status, token: incomingToken, userId } = data;

          if (!status) {
            // logoutBeta();
            toast.error(data?.message ?? "Inavlid login token");
          } else {
            setLoginCookieBeta(incomingToken);
            setLoginCookieBetaBusiness(incomingToken);
            setBTLRBeta({
              ...BTLRBeta,
              loginToken: incomingToken,
              businessLoginToken: incomingToken,
              goToBusiness: true,
            });
            if (userId) navigate("/business/guests/" + userId);
            else navigate("/business");
          }
        })
        .catch((error) => {
          console.error("Unauthorized", error);
          // logoutBeta();
        });
    } else {
      // Customer side login
      if (!token) {
        logoutBeta();
        return;
      }

      fetch(BTLR_BETA_API_BASE + betaLoginVerifyEmailURL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ magic: token }),
      })
        .then((response) => response.json())
        .then((data) => {
          const { status, token: incomingToken } = data;
          if (!status) {
            logoutBeta();
          } else {
            setLoginCookieBeta(incomingToken);
            setLoginCookieBetaBusiness(incomingToken);
            setBTLRBeta({
              ...BTLRBeta,
              loginToken: incomingToken,
              businessLoginToken: incomingToken,
            });
            navigate("/personal/edit-preferences");
          }
        })
        .catch((error) => {
          console.error("Unauthorized", error);
          logoutBeta();
        });
    }
  }, [token]);

  return <>Verifying Login</>;
};

export default VerifyBetaB2CLogin;
