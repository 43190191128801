import React, { useState, createContext } from "react";

export const LanguageContext = createContext();

export const LangugageProvider = ({ children }) => {
  const [language, setLanguage] = useState({
    selected: "en", // en, fr, de
  });

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};
