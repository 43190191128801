import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import Button from "../../../components/common/button/button";

import { BTLR_BETA_API_BASE, betaLoginVerifyEmailURL } from "../../../api/base";
import { setLoginCookieBeta } from "../../../api/helpers/authCookies";
import { buildBasicLoader } from "../../../api/loaders/basic";
import confirmed_img from "../../../assets/images/b2c/beta/confirmed.png";

import "./styles.scss";

const B2C_ConfirmAccount = () => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [checking, setChecking] = useState(true);
  const [accountConfirmed, setAccountConfirmed] = useState(false);

  const navigate = useNavigate();
  const { token, type } = useParams();

  useEffect(() => {
    if (!token) return;

    const destroyLoader = buildBasicLoader();

    fetch(BTLR_BETA_API_BASE + betaLoginVerifyEmailURL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ magic: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        setChecking(false);
        const { status, token: incomingToken } = data;
        if (!status) {
          console.log("Not logging in");
          setAccountConfirmed(false);
        } else {
          setAccountConfirmed(true);
          setLoginCookieBeta(incomingToken);
          setBTLRBeta({ ...BTLRBeta, loginToken: incomingToken });
        }
      })
      .catch((error) => {
        console.error("Unauthorized", error);
        setAccountConfirmed(false);
      })
      .finally(() => {
        setChecking(false);
        destroyLoader();
      });
  }, [token]);

  return (
    <div className={`confirm_b2c_account ${type === "b2b" ? "b2b" : ""}`}>
      {!checking && (
        <>
          <div className="email_confirmed">
            {accountConfirmed && (
              <img src={confirmed_img} alt="BTLR Account Confirmed" />
            )}
            <p
              className="heading offWhite_text center_align basier"
              style={{ transform: "translateY(-10vh)" }}
            >
              {accountConfirmed
                ? "Account Confirmed."
                : "Account not confimed. Try correct login link."}
            </p>
            <div style={{ transform: "translateY(-10vh)" }}>
              <Button
                size="medium"
                type="pill"
                color={"dark_mode_white"}
                centered
                callback={() => navigate("/personal/edit-preferences")}
              >
                Open App &gt;
              </Button>
            </div>
          </div>

          <div className="bottom_btn">
            <p className="text center_align offWhite_text">
              Break The Luxury Rules
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default B2C_ConfirmAccount;
