export const purple_checkmark = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#3F5DFF" />
    <path
      d="M8 14.2105L11.1563 17.3115C11.5454 17.6937 12.1689 17.6937 12.558 17.3115L20 10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const down_arrow = (
  <svg
    width="21"
    height="12"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1L10.5 10L1 1"
      stroke="#9e9d9c"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export const go_back_black = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.75 22.5L12.3107 16.0607C11.7249 15.4749 11.7249 14.5251 12.3107 13.9393L18.75 7.5"
      stroke="#111014"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const go_back_grey = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
  >
    <path
      d="M9 17L2.06066 10.0607C1.47487 9.47487 1.47487 8.52513 2.06066 7.93934L9 1"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const close_blue = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.43934 0.93934C1.02513 0.353553 1.97487 0.353553 2.56066 0.93934L6 4.37868L9.43934 0.93934C10.0251 0.353553 10.9749 0.353553 11.5607 0.93934C12.1464 1.52513 12.1464 2.47487 11.5607 3.06066L8.12132 6.5L11.5607 9.93934C12.1464 10.5251 12.1464 11.4749 11.5607 12.0607C10.9749 12.6464 10.0251 12.6464 9.43934 12.0607L6 8.62132L2.56066 12.0607C1.97487 12.6464 1.02513 12.6464 0.43934 12.0607C-0.146447 11.4749 -0.146447 10.5251 0.43934 9.93934L3.87868 6.5L0.43934 3.06066C-0.146447 2.47487 -0.146447 1.52513 0.43934 0.93934Z"
      fill="#0C5FFD"
    />
  </svg>
);

export const close_thin = (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1.5L11.5 11.5M11.5 11.5L21.5 21.5M11.5 11.5L21.5 1.5M11.5 11.5L1.5 21.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const close_bg_grey = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="14" fill="#7C7D82" fillOpacity="0.1" />
    <path
      opacity="0.3"
      d="M8 8L14 14M14 14L20 20M14 14L20 8M14 14L8 20"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const close_bg_dark = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#7C7D82" fillOpacity="0.1" />
    <path
      opacity="0.3"
      d="M8 8L16 16M16 16L24 24M16 16L24 8M16 16L8 24"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const close_bg_white = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#7C7D82" fillOpacity="0.1" />
    <path
      opacity="0.3"
      d="M8 8L16 16M16 16L24 24M16 16L24 8M16 16L8 24"
      stroke="#0D0D0D"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const icon_delete_grey = (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8.5L6.78693 18.7301C6.90716 20.2931 8.21048 21.5 9.77809 21.5H14.2219C15.7895 21.5 17.0928 20.2931 17.2131 18.7301L18 8.5"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
    />
    <path
      d="M4 6.5H20"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15 5.5V5.5C15 4.39543 14.1046 3.5 13 3.5H11C9.89543 3.5 9 4.39543 9 5.5V5.5"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14 11.5L14 17.5"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 11.5L10 17.5"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const icon_redo_grey = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 21.5063C17.9706 21.5063 22 17.4769 22 12.5063C22 7.53578 17.9706 3.50635 13 3.50635C8.02944 3.50635 4 7.53578 4 12.5063"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13 8.00635L13 13.0921C13 13.3574 13.1054 13.6117 13.2929 13.7992L15.5 16.0063"
      stroke="black"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.32449 15.6288C3.66574 16.0721 4.33426 16.0721 4.67551 15.6288L6.40756 13.3788C6.83908 12.8183 6.43947 12.0063 5.73205 12.0063H2.26795C1.56053 12.0063 1.16092 12.8183 1.59244 13.3788L3.32449 15.6288Z"
      fill="black"
      fillOpacity="0.3"
    />
  </svg>
);

export const right_arrow = (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.9502 1L7.74309 6.7929C8.13361 7.18342 8.13361 7.81659 7.74309 8.20711L1.95019 14"
      stroke="#12131A"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const down_arrow_blue_bg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#0C5FFD" fillOpacity="0.1" />
    <path
      d="M6 15L11.2929 9.70711C11.6834 9.31658 12.3166 9.31658 12.7071 9.70711L18 15"
      stroke="#0C5FFD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const up_arrow_blue_bg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#0C5FFD" fillOpacity="0.1" />
    <path
      d="M6 15L11.2929 9.70711C11.6834 9.31658 12.3166 9.31658 12.7071 9.70711L18 15"
      stroke="#0C5FFD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const logout_icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3H13C9.22876 3 7.34315 3 6.17157 4.17157C5 5.34315 5 7.22876 5 11V13C5 16.7712 5 18.6569 6.17157 19.8284C7.34315 21 9.22876 21 13 21H14"
      stroke="#E54A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11 12H21"
      stroke="#E54A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21 12L17 8"
      stroke="#E54A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21 12L17 16"
      stroke="#E54A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const add_blue_plus = (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29" cy="29" r="29" fill="#0C5FFD" />
    <path d="M18 29H40M29 18L29 40" stroke="white" strokeWidth="2" />
  </svg>
);

export const add_blue_without_circle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M6 11.5V1.5M1 6.5L11 6.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const editCustomCatIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 22C3 21.4477 3.44772 21 4 21H20C20.5523 21 21 21.4477 21 22V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V22Z"
      fill="#3A82F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7071 3.12126C16.3166 2.73074 15.6834 2.73074 15.2929 3.12126L14.4142 3.99991L18 7.5857L18.8787 6.70705C19.2692 6.31652 19.2692 5.68336 18.8787 5.29284L16.7071 3.12126ZM6.91425 11.4999L13 5.41412L16.5858 8.99991L10.5 15.0857L6.91425 11.4999ZM5.50003 12.9141L5 13.4142V16.9999H8.58579L9.08582 16.4999L5.50003 12.9141ZM13.8787 1.70705C15.0503 0.535476 16.9497 0.535476 18.1213 1.70705L20.2929 3.87862C21.4645 5.0502 21.4645 6.94969 20.2929 8.12126L10 18.4142C9.62493 18.7892 9.11622 18.9999 8.58579 18.9999H5C3.89543 18.9999 3 18.1045 3 16.9999V13.4142C3 12.8837 3.21071 12.375 3.58579 11.9999L13.8787 1.70705Z"
      fill="#3A82F7"
    />
  </svg>
);

export const blue_edit_icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.14874 16.2551L2.40054 16.2031L3.14874 16.2551C3.22561 15.1482 3.65119 14.0941 4.36439 13.2442L10.8237 5.54627C11.9775 4.17128 14.0274 3.99193 15.4024 5.14568C16.7774 6.29944 16.9567 8.3494 15.803 9.72439L9.34368 17.4223C8.63047 18.2723 7.66632 18.8744 6.5896 19.1423L4.55209 19.6493C3.7285 19.8542 2.94448 19.1963 3.00328 18.3497L3.14874 16.2551Z"
      stroke="#0C5FFD"
      strokeWidth="1.5"
    />
    <path
      d="M9.72949 7.23901L14.3258 11.0957"
      stroke="#0C5FFD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21 19.5H13"
      stroke="#0C5FFD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const blue_plus_icon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13V1M1 7L13 7"
      stroke="#0C5FFD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const blue_up_arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6 15L11.2929 9.70711C11.6834 9.31658 12.3166 9.31658 12.7071 9.70711L18 15"
      stroke="#0C5FFD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const grey_up_arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M6 15.5L11.2929 10.2071C11.6834 9.81658 12.3166 9.81658 12.7071 10.2071L18 15.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const arrow_right_grey_bg = (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18.5" r="18" fill="#E7E8EA" />
    <path
      d="M15 12.5L21 18.5L15 24.5"
      stroke="black"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
  </svg>
);

export const white_edit_icon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.14874 16.7551L2.40054 16.7031L3.14874 16.7551C3.22561 15.6482 3.65119 14.5941 4.36439 13.7442L10.8237 6.04627C11.9775 4.67128 14.0274 4.49193 15.4024 5.64568C16.7774 6.79944 16.9567 8.8494 15.803 10.2244L9.34368 17.9223C8.63047 18.7723 7.66632 19.3744 6.5896 19.6423L4.55209 20.1493C3.7285 20.3542 2.94448 19.6963 3.00328 18.8497L3.14874 16.7551Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M9.72949 7.73926L14.3258 11.596"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21 20H13"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const white_copy_icon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.25H17C19.8995 10.25 22.25 12.6005 22.25 15.5C22.25 18.3995 19.8995 20.75 17 20.75H12C9.10051 20.75 6.75 18.3995 6.75 15.5C6.75 12.6005 9.10051 10.25 12 10.25Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7 6H12C13.4046 6 14.6588 6.6435 15.4841 7.65177C15.6559 7.86171 15.9046 8.00132 16.1758 8.00353C16.7755 8.00842 17.1667 7.38958 16.8075 6.90937C15.7133 5.44671 13.9672 4.5 12 4.5H7C3.68629 4.5 1 7.18629 1 10.5C1 12.5017 1.98027 14.2745 3.48695 15.3645C3.94918 15.6989 4.54419 15.3434 4.57196 14.7735C4.5861 14.4833 4.43948 14.2124 4.21156 14.0322C3.16901 13.208 2.5 11.9321 2.5 10.5C2.5 8.01472 4.51472 6 7 6Z"
      fill="white"
    />
    <path
      d="M12 15H9.85887C9.41926 15 9.0739 15.3764 9.11164 15.8144C9.14505 16.2022 9.46961 16.5 9.85887 16.5H12C14.2006 16.5 16.1244 15.3153 17.1686 13.549C17.4425 13.0858 17.0849 12.5442 16.5473 12.5216C16.234 12.5085 15.9476 12.6845 15.7768 12.9475C14.9746 14.1829 13.5827 15 12 15Z"
      fill="white"
    />
  </svg>
);
