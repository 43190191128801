import React, { useEffect, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { LoggedDataContext } from "./contexts/loggedData.context";

import { betaLocationCheck } from "./assets/scripts/location";
import { checkLoginCookie } from "./api/helpers/authCookies";
import { getUserDataWithAuthToken } from "./api/b2b/signUpIn.service";

import CookieBanner from "./components/common/cookieBanner/cookieBanner";
import PaymenSuccessful from "./pages/paymentSuccessful/PaymentSuccessful";

// B2B Pages
import Signup from "./pages/b2b/signup/signup";

// B2B Preference page
import VerifyPreference from "./pages/b2b/businessbeta/userPreferences";
import BusinessPreferenceLinks from "./pages/b2b/businessbeta/preferenceLinks";
import UnlinkedGuests from "./pages/b2b/businessbeta/unlinkedGuests";
import TeamMembers from "./pages/b2b/businessbeta/teamMembers";
import BusinessAccount from "./pages/b2b/businessbeta/businessAccount";
import BusinessPreferences from "./pages/b2b/businessbeta/businessPreferences";

// B2C Pages
import B2C_Signup from "./pages/b2c/signup/signup";
import EditPreferences from "./pages/b2c/editPreferences";
import SharePreferences from "./pages/b2c/sharePreferences";
import B2CAccount from "./pages/b2c/account";
import B2C_ConfirmAccount from "./pages/b2c/confirmAccount";
import VerifyBetaB2CLogin from "./pages/b2c/verifyLogin";
import LoadBusinessPreviewDetails from "./pages/b2c/businessPreview";
import {
  AddBusinessEmail,
  InternalBusinessAccounts,
  InternalGPTPromptEditor,
  InternalPersonalAccounts,
  InternalPreferences,
  InternalPreferenceLinks,
} from "./pages/internal";

// Styles
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const [, setLoggedData] = useContext(LoggedDataContext);

  const betaPage = betaLocationCheck(location.pathname);

  if (betaPage) document.body.classList.add("beta");
  else document.body.classList.remove("beta");

  useEffect(() => {
    const cookieToken = checkLoginCookie();
    if (cookieToken) {
      getUserDataWithAuthToken(null, setLoggedData);
    }
  }, []);

  return (
    <>
      <div className="container">
        <Routes>
          {/* B2B Routes */}
          <Route path="/login/business/:showForm" element={<Signup />}></Route>
          <Route path="/login/business" element={<Signup />}></Route>
          <Route
            path="/business/unlinked_guests"
            element={<UnlinkedGuests />}
          />
          <Route
            path="/business/preference_links"
            element={<BusinessPreferenceLinks />}
          />
          <Route path="/business/share_preferences" element={<TeamMembers />} />
          <Route path="/business/account" element={<BusinessAccount />} />
          <Route
            path="/business/preferences"
            element={<BusinessPreferences />}
          />
          <Route
            path="/business/guests/:user_id"
            element={<VerifyPreference />}
          />
          <Route path="/business/guests" element={<VerifyPreference />} />
          <Route path="/business/" element={<VerifyPreference />} />

          {/* Beta B2C Routes */}
          <Route path="/login/:showForm" element={<B2C_Signup />}></Route>
          <Route path="/login" element={<B2C_Signup />}></Route>
          <Route
            path="/personal/edit-preferences"
            element={<EditPreferences />}
          ></Route>
          <Route
            path="/personal/discover/:discoverId"
            element={<EditPreferences />}
          ></Route>
          <Route path="/:url" element={<LoadBusinessPreviewDetails />}></Route>
          <Route
            path="/personal/share-preferences"
            element={<SharePreferences />}
          ></Route>
          <Route
            path="/personal/share-preferences/:show"
            element={<SharePreferences />}
          ></Route>
          <Route path="/personal/account" element={<B2CAccount />}></Route>
          <Route
            path="/beta/confirm-account/:token/:type"
            element={<B2C_ConfirmAccount />}
          ></Route>
          <Route
            path="/beta/confirm-account/:token"
            element={<B2C_ConfirmAccount />}
          ></Route>
          <Route
            path="/beta/verify-beta-b2c-email/:token"
            element={<VerifyBetaB2CLogin />}
          ></Route>
          <Route
            path="/beta/beta-business-login/:token"
            element={<VerifyBetaB2CLogin type={"business"} />}
          ></Route>
          <Route path="/personal" element={<EditPreferences />}></Route>

          {/* Internal routes */}
          <Route
            path="/internal/business-accounts"
            element={<InternalBusinessAccounts />}
          />
          <Route
            path="/internal/gpt-prompt"
            element={<InternalGPTPromptEditor />}
          />
          <Route
            path="/internal/personal-accounts"
            element={<InternalPersonalAccounts />}
          />
          <Route
            path="/internal/preferences"
            element={<InternalPreferences />}
          />
          <Route
            path="/internal/preference-links"
            element={<InternalPreferenceLinks />}
          />
          <Route
            path="/personal/business-email/:id"
            element={<AddBusinessEmail />}
          />
          <Route path="/internal" element={<InternalBusinessAccounts />} />

          {/* Pages Routes */}
          <Route
            path="/payment-successful"
            element={<PaymenSuccessful />}
          ></Route>

          <Route path="/" element={<B2C_Signup />}></Route>

          {/* If path not found */}
          <Route path="*" element={<Navigate replace to="/" />} />
          {/* If path not found */}
        </Routes>
        <CookieBanner />
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
}

export default App;
