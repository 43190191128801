import React, { useState, useEffect, useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import Form from "../../../components/common/form/form";
import HybridModal from "../../../components/common/hybridModal/hybridModal";
import ConfirmationModal from "../../../components/common/confirmationModal/confirmationModal";

import { getSalutations } from "../../b2b/signup/signup.service";

import { b2cAccountInputsForPopup } from "../../b2c/account/service";

import { close_bg_white } from "../../../assets/svgs/action_icons";

const PersonalAccountModal = ({
  userData,
  updateUserDetails,
  deleteUser,
  ...props
}) => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [updatedSignUpInputs, setUpdatedSignUPInputs] = useState([
    ...b2cAccountInputsForPopup,
  ]);
  const [prefilledValues, setPrefilledValues] = useState({});
  const [deleteUserConfirm, setDeleteUserConfirm] = useState(false);

  const { personalAccountModal } = BTLRBeta;

  useEffect(() => {
    if (!userData) return;

    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      let temp = [...updatedSignUpInputs];

      temp[0].options = parsedSalutationsData;
      setUpdatedSignUPInputs(temp);
    });
  }, [userData]);

  useEffect(() => {
    if (!userData) return;
    // prepopulate
    const prepopulatedData = {
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      language: "",
      dob: "",
    };

    Object.keys(prepopulatedData).forEach((key) => {
      if (userData[key]) prepopulatedData[key] = userData[key];
    });

    setPrefilledValues({ ...prepopulatedData });
  }, [userData]);

  return (
    <>
      <HybridModal
        open={personalAccountModal}
        opener={() => setBTLRBeta({ ...BTLRBeta, personalAccountModal: false })}
        backgroundColor={"#ffffff"}
        blur
        {...props}
      >
        <div className="b2c_account_modal">
          <div
            className="beta_modal_top_area white_bg"
            style={{ marginBottom: 0 }}
          >
            <h1
              className="heading basier black_text"
              style={{ marginBottom: 0 }}
            >
              Account
            </h1>
            <button
              className="close_thin"
              onClick={() =>
                setBTLRBeta({ ...BTLRBeta, personalAccountModal: false })
              }
            >
              {close_bg_white}
            </button>
          </div>
          <p className="text medium manrope light_black_text manrope ">
            All Account details are managed here.
          </p>

          <span className="vertical_space"></span>
          <Form
            inputs={updatedSignUpInputs}
            prefilledValues={prefilledValues}
            transparentInputs={false}
            submit_text={"Update"}
            custom_submit_2={true}
            size="small"
            submitForm={(values) => updateUserDetails(userData._id, values)}
          />
          <span className="vertical_space"></span>
          <button
            className="text red_text"
            style={{ background: "transparent" }}
            onClick={() => setDeleteUserConfirm(true)}
          >
            Delete Account?
          </button>
          <span className="vertical_space"></span>
        </div>
      </HybridModal>

      <ConfirmationModal
        open={deleteUserConfirm}
        opener={setDeleteUserConfirm}
        title={"Delete Account??"}
        info={
          "Confirm you want to delete this account? This action cannot be undone."
        }
        confirmButtonText={"Delete Account"}
        cancelButtonText={"Cancel"}
        confirmButtonColor={"dark_red"}
        confirmButtonCallback={() => {
          deleteUser(userData?._id);
          setDeleteUserConfirm(false);
        }}
      />
    </>
  );
};

export default PersonalAccountModal;
