import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";
import { toast } from "react-toastify";

import HybridModal from "../../components/common/hybridModal/hybridModal";
import Button from "../../components/common/button/button";
import Form from "../../components/common/form/form";
import Input from "../../components/common/input/input";
import B2BBottomNav from "../b2b/businessbeta/components/b2bBottomNav";

import { logoutBeta, logoutBetaBusiness } from "../../api/helpers/authCookies";
import { shareBTLRNavigator } from "./b2cUtil";
import {
  b2bSignupInputsBusinessOnly,
  addBusinessAPI,
  addPlaceDetailsToSignupObject,
} from "../b2b/signup/signup.service";
import { joinBusinessWithOTP } from "./account/service";
import { checkLoginCookieBetaBusiness } from "../../api/helpers/authCookies";
import { getUserDataBeta } from "./account/service";
import { updateBusinessTypesOptionsInList } from "../b2b/businessbeta/data.service";

import {
  btlr_preference_svg,
  share_pref_svg,
  share_preferences_svg,
  my_account_icon_svg,
  discover_svg,
  right_grey_arrow,
  logout_red_svg,
  share_icon_blue_svg,
  guests_icon,
  preferences_icon,
  pref_link_icon,
  hamburger_bars,
} from "../../assets/svgs/icons";

import { purple_checkmark } from "../../assets/svgs/action_icons";
import btlr_logo from "../../assets/images/global/logo_white.svg";

const B2CNav = () => {
  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [nav, openNav] = useState(false);
  const [businessSignupModal, setBusinessSignupModal] = useState(false);
  const [joinBusinessModal, setJoinBusinessModal] = useState(false);
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState();
  const [businessSide, setBusinessSide] = useState(false);
  const [b2bMobileMenuOpen, setB2BMobileMenuOpen] = useState(false);
  const [updatedBusinessCreateInputs, setUpdatedBusinessCreateInputs] =
    useState([...b2bSignupInputsBusinessOnly]);
  const { guestBusinessAdmin } = BTLRBeta;

  const notificationsStatus =
    BTLRBeta?.notifications?.[BTLRBeta?.selectedBusiness?._id];

  const businessLoginToken =
    checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken;

  const handleJoinBusiness = (value) => {
    if (!value || value.length !== 6) {
      setOtpError("Please enter OTP correctly.");
      return;
    }

    joinBusinessWithOTP(value, businessLoginToken, true, (data) => {
      const { status, message } = data;

      if (status) {
        toast.success(message ?? "Business joined", { theme: "dark" });
        setJoinBusinessModal(false);
        setOtp();
        handlePostSignup(data);
      } else {
        setOtpError(message ?? "Unable to join business");
        setOtp();
        // toast.error(message ?? "Unable to join business", { theme: "dark" });
      }
    });
  };

  const handlePostSignup = (data) => {
    console.log("Business added", data);
    if (data?.status) {
      toast.success("New business added", { theme: "dark" });
      getUserDataBeta(setBTLRBeta, businessLoginToken, (ignore, data) => {
        const lastAddedBusiness =
          data?.ownedBusinesses[data?.ownedBusinesses?.length - 1];
        setBTLRBeta({
          ...data,
          selectedBusiness: lastAddedBusiness?.business,
        });
        setBusinessSignupModal(false);
        navigate("/business/guests");
      });
    } else {
      toast.error(data?.message ?? "Unable to add business", { theme: "dark" });
    }
  };

  const handleAddBusiness = (values) => {
    if (values?.address?.place_id) {
      addPlaceDetailsToSignupObject(
        {
          ...values,
          address: values?.address?.description,
          googlePlaceID: values?.address?.place_id,
        },
        values?.address?.place_id,
        ({ status, data }) => {
          if (status) {
            addBusinessAPI(data, handlePostSignup, businessLoginToken);
          } else {
            toast.error(
              "Unable to verify business location details. Try again.",
              {
                theme: "dark",
              }
            );
          }
        }
      );
    } else {
      addBusinessAPI(values, handlePostSignup, businessLoginToken);
    }
  };

  const handleGuestAdminNavigation = (e) => {
    if (guestBusinessAdmin) {
      e.preventDefault();
      toast.info("You must create a business first", {
        theme: "dark",
      });
      setBusinessSignupModal(true);
      return;
    }
  };

  useEffect(() => {
    const showOTP = Cookies.get("showOTP");
    if (!showOTP) return;

    setJoinBusinessModal(true);
    Cookies.remove("showOTP");
  }, []);

  // Fetch and update business types from API
  useEffect(() => {
    updateBusinessTypesOptionsInList(
      1,
      updatedBusinessCreateInputs,
      setUpdatedBusinessCreateInputs
    );
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("/business")) setBusinessSide(true);
    else setBusinessSide(false);
  }, []);

  return (
    <>
      <div className="b2c_sidebar_nav">
        {!BTLRBeta?.loggedIn &&
          !BTLRBeta?.b2cNavGoBack &&
          !businessLoginToken && (
            <Link to={"/personal/edit-preferences"}>
              <img src={btlr_logo} alt="BTLR" />
            </Link>
          )}
        {!BTLRBeta?.loggedIn && BTLRBeta?.b2cNavGoBack && (
          <>
            <Link to={"/personal/edit-preferences"}>
              <img src={btlr_logo} alt="BTLR" />
            </Link>
          </>
        )}

        {BTLRBeta?.loggedIn && !businessSide && (
          <>
            <div className="desktop">
              <div className="user-icon purple" onClick={() => openNav(!nav)}>
                {BTLRBeta?.firstName?.charAt(0) ?? "H"}
              </div>

              <NavLink
                to={"/personal/edit-preferences"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <span className="nav_icon">{btlr_preference_svg}</span>
                <p>Preferences</p>
              </NavLink>
              <NavLink
                to={"/personal/discover/64e2e38499cbf807fbddfcd1"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <span className="nav_icon">{discover_svg}</span>
                <p>Discover</p>
              </NavLink>
              <NavLink
                to={"/personal/share-preferences"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <span className="nav_icon">{share_preferences_svg}</span>
                <p>Share</p>
              </NavLink>
              <NavLink
                to={"/personal/account"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <span className="nav_icon">{my_account_icon_svg}</span>
                <p>Account</p>
              </NavLink>
            </div>
            <div className="mobile">
              <span className="bg"></span>
              {!BTLRBeta?.b2cNavGoBack && (
                <div className="user-icon" onClick={() => openNav(!nav)}>
                  {BTLRBeta?.firstName?.charAt(0) ?? "H"}
                </div>
              )}
              <div className="mobile_bottom_nav">
                <NavLink
                  to={"/personal/edit-preferences"}
                  className={({ isActive }) =>
                    isActive ? "nav_link active" : "nav_link"
                  }
                >
                  <span className="nav_icon">{btlr_preference_svg}</span>
                  {/* <p>Preferences</p> */}
                </NavLink>
                <NavLink
                  to={"/personal/discover/64e2e38499cbf807fbddfcd1"}
                  className={({ isActive }) =>
                    isActive ? "nav_link active" : "nav_link"
                  }
                >
                  <span className="nav_icon">{discover_svg}</span>
                  {/* <p>Discover</p> */}
                </NavLink>
                <NavLink
                  to={"/personal/share-preferences"}
                  className={({ isActive }) =>
                    isActive ? "nav_link active" : "nav_link"
                  }
                >
                  <span className="nav_icon">{share_pref_svg}</span>
                  {/* <p>Share</p> */}
                </NavLink>
                <NavLink
                  to={"/personal/account"}
                  className={({ isActive }) =>
                    isActive ? "nav_link active" : "nav_link"
                  }
                >
                  <span className="nav_icon">{my_account_icon_svg}</span>
                  {/* <p>Account</p> */}
                </NavLink>
              </div>
            </div>
          </>
        )}
        {businessLoginToken && businessSide && (
          <>
            <div className="desktop">
              <div className="user-icon purple" onClick={() => openNav(!nav)}>
                {BTLRBeta?.selectedBusiness?.businessName?.charAt(0) ?? "B"}
              </div>

              <NavLink
                to={"/business/unlinked_guests"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
                onClick={handleGuestAdminNavigation}
              >
                <span
                  className={`nav_icon ${
                    BTLRBeta?.unlinkedUsers ? "notification" : ""
                  }`}
                >
                  {guests_icon}
                </span>
                <p>Unlinked guests</p>
              </NavLink>
              <NavLink
                to={"/business/guests"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <span
                  className={`nav_icon ${
                    notificationsStatus?.pendingGuests ? "notification" : ""
                  }`}
                >
                  {guests_icon}
                </span>
                <p>My Guests</p>
              </NavLink>
              <NavLink
                to={"/business/share_preferences"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
                onClick={handleGuestAdminNavigation}
              >
                <span
                  className={`nav_icon ${
                    notificationsStatus?.pendingBusinessUsers
                      ? "notification"
                      : ""
                  }`}
                >
                  {share_pref_svg}
                </span>
                <p>Share</p>
              </NavLink>
              <NavLink
                to={"/business/preferences"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
                onClick={handleGuestAdminNavigation}
              >
                <span
                  className={`nav_icon ${
                    notificationsStatus?.pendingCustomPreferences
                      ? "notification"
                      : ""
                  }`}
                >
                  {preferences_icon}
                </span>
                <p>Preferences</p>
              </NavLink>
              <NavLink
                to={"/business/preference_links"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
                onClick={handleGuestAdminNavigation}
              >
                <span className={`nav_icon`}>{pref_link_icon}</span>
                <p>Preference Link</p>
              </NavLink>
              <NavLink
                to={"/business/account"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
                onClick={handleGuestAdminNavigation}
              >
                <span
                  className={`nav_icon ${
                    !notificationsStatus?.isSubscriptionActive
                      ? "notification"
                      : ""
                  }`}
                >
                  {my_account_icon_svg}
                </span>
                <p>Account</p>
              </NavLink>

              <div className="vertical_space line"></div>

              <NavLink
                to={"/faq"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <p>FAQ</p>
              </NavLink>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  logoutBetaBusiness();
                }}
                to={"/logout"}
                className={({ isActive }) =>
                  isActive ? "nav_link active" : "nav_link"
                }
              >
                <p>Logout</p>
              </NavLink>
            </div>
            <div className="mobile">
              <span className="bg"></span>
              <div className="user-icon" onClick={() => openNav(!nav)}>
                {BTLRBeta?.firstName?.charAt(0) ?? "H"}
              </div>
              <button
                className="b2b_mobile_nav_open"
                onClick={() => {
                  if (guestBusinessAdmin) {
                    toast.info("You must create a business first", {
                      theme: "dark",
                    });
                    return;
                  }
                  setB2BMobileMenuOpen(true);
                }}
              >
                {hamburger_bars}
              </button>
            </div>
          </>
        )}
      </div>

      {/* Nav */}
      <HybridModal
        open={nav}
        opener={openNav}
        bottom={true}
        autoHeight={false}
        dark={true}
        backgroundColor={"#0d0d0d"}
        blur
      >
        <>
          <div className="b2c_nav_top_btns">
            <Button
              text="Setup A Business"
              color={"dark_mode_grey_light"}
              size={"medium"}
              type={"roundedSquare"}
              callback={() => {
                openNav(false);
                setBusinessSignupModal(true);
              }}
            />
            <Button
              text="Join A Business"
              color={"blue"}
              size={"medium"}
              type={"roundedSquare"}
              callback={() => {
                openNav(false);
                setJoinBusinessModal(true);
              }}
            />
          </div>
          <div
            className="nav_box"
            onClick={() => {
              setBTLRBeta({ ...BTLRBeta, selectedBusiness: null });
              navigate("/personal/edit-preferences");
            }}
          >
            <span className="nav_link_pref">
              <div className="nav_left_side">
                <span className="user-icon">
                  {BTLRBeta?.firstName?.charAt(0) ?? "H"}
                </span>
                <p>
                  {BTLRBeta?.firstName} {BTLRBeta?.lastName}{" "}
                  <span>{BTLRBeta?.email}</span>
                </p>
              </div>
              {!BTLRBeta?.selectedBusiness && (
                <div className="nav_right_side full_opacity">
                  {purple_checkmark}
                </div>
              )}
            </span>
          </div>
          {BTLRBeta?.ownedBusinesses?.map((ob, index) => {
            const {
              accessType,
              business: { _id, businessName },
            } = ob;

            const showMainNotification =
              BTLRBeta?.notifications?.[`${_id}`]?.mainNotification ?? false;

            return (
              <div
                key={index}
                className="nav_box"
                onClick={() => {
                  setBTLRBeta({
                    ...BTLRBeta,
                    selectedBusiness: { accessType, ...ob?.business },
                  });
                  localStorage.setItem("lastVisitedBusinessId", _id);
                  openNav(false);
                  navigate("/business/guests");
                }}
              >
                <span className="nav_link_pref">
                  <div className="nav_left_side">
                    <span
                      className={`user-icon ${
                        showMainNotification ? "notification" : ""
                      }`}
                    >
                      {businessName?.charAt(0)}
                    </span>
                    <p>{businessName}</p>
                  </div>
                  {BTLRBeta?.selectedBusiness?._id === _id && (
                    <div className="nav_right_side full_opacity">
                      {purple_checkmark}
                    </div>
                  )}
                </span>
              </div>
            );
          })}
          <div
            className="nav_box"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <a
              href={"#logout"}
              className="nav_link_pref"
              onClick={(e) => {
                e.preventDefault();
                logoutBeta();
              }}
            >
              <div className="nav_left_side">
                <span className="nav_icon">{logout_red_svg}</span>
                <p style={{ color: "#E54A4A" }}>Logout</p>
              </div>
              <div className="nav_right_side">{right_grey_arrow}</div>
            </a>
          </div>

          <Button
            text={"Invite Friends"}
            type={"pill"}
            color={"blue_bg_effect"}
            size={"medium"}
            extraClass="flex"
            centered
            callback={shareBTLRNavigator}
          >
            {share_icon_blue_svg}
          </Button>
          <p
            className="text center_align dark_grey_text"
            style={{ marginTop: "6px" }}
          >
            Break The Luxury Rules
          </p>
        </>
      </HybridModal>

      {/* Join Business */}
      <HybridModal
        dark={true}
        open={joinBusinessModal}
        opener={() => setJoinBusinessModal(false)}
        backgroundColor={"#0d0d0d"}
        bottom
        autoHeight
        blur
      >
        <div className="business_signup_popup">
          {joinBusinessModal && (
            <>
              <div className="b_heading white_text center_align">
                Join A Business
              </div>

              <Input
                name={"join_business_otp"}
                type="otp"
                label={"Join A Business"}
                defaultValue={otp}
                validationCallback={(value) => {
                  setOtpError("");
                  setOtp(value);
                  if (!value) return;

                  if (value.length === 6) {
                    handleJoinBusiness(value);
                  }
                }}
              />
              {otpError && (
                <p className="text red_text center_align">{otpError}</p>
              )}
              <p className="text light_grey_text center_align">
                Enter the verification code that was sent to you. If you have
                not received it, please ask your business to resend it.
              </p>
              <Button
                text="Authenticate Code"
                type={"roundedSquare"}
                color={"blue"}
                size={"medium"}
                extraClass={"fullWidth"}
                callback={() => handleJoinBusiness(otp)}
              />
            </>
          )}

          <span className="vertical_space"></span>
        </div>
      </HybridModal>

      {/* Add Business */}
      <HybridModal
        dark={true}
        open={businessSignupModal}
        opener={() => setBusinessSignupModal(false)}
        backgroundColor={"#0d0d0d"}
        bottom
        autoHeight
        blur
      >
        <div className="business_signup_popup">
          {businessSignupModal && (
            <>
              <div className="b_heading white_text">BTLR Business</div>
              <p className="text light_grey_text">
                Setup your business account and get started.
              </p>
              <Form
                inputs={updatedBusinessCreateInputs}
                greyInputs={true}
                submit_text={"Save"}
                custom_submit_2={true}
                size="small"
                submitForm={(values) => {
                  handleAddBusiness({
                    ...values,
                    businessType:
                      values?.businessType?.title ?? values?.businessType,
                  });
                }}
              />
            </>
          )}

          <span className="vertical_space"></span>
        </div>
      </HybridModal>

      {/* B2B Mobile Nav */}
      <B2BBottomNav nav={b2bMobileMenuOpen} openNav={setB2BMobileMenuOpen} />
    </>
  );
};

export default B2CNav;
