import {
  BTLR_BETA_API_BASE,
  business,
  inviteBusinessLoggedURL,
} from "../../../api/base";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../api/loaders/basic";
import { checkLoginCookieBeta } from "../../../api/helpers/authCookies";

export const occasions = [
  {
    key: "🏖️ Vacation/holiday",
    title: "🏖️ Vacation/holiday",
  },
  {
    key: "👔 Business trip",
    title: "👔 Business trip",
  },
  {
    key: "👨‍👩‍👧‍👦 Family visit/reunion",
    title: "👨‍👩‍👧‍👦 Family visit/reunion",
  },
  {
    key: "💍 Wedding/celebration",
    title: "💍 Wedding/celebration",
  },
  {
    key: "⛰️ Adventure/exploration",
    title: "⛰️ Adventure/exploration",
  },
  {
    key: "💑 Honeymoon/romantic getaway",
    title: "💑 Honeymoon/romantic getaway",
  },
  {
    key: "🌍 Cultural exchange/experience",
    title: "🌍 Cultural exchange/experience",
  },
  {
    key: "🎓 Education/study abroad",
    title: "🎓 Education/study abroad",
  },
  {
    key: "🧘‍♀️ Wellness retreat/spa getaway",
    title: "🧘‍♀️ Wellness retreat/spa getaway",
  },
  {
    key: "🏆 Sports event/tournament",
    title: "🏆 Sports event/tournament",
  },
  {
    key: "🎵 Concert/music festival",
    title: "🎵 Concert/music festival",
  },
  {
    key: "🍽️ Food/culinary exploration",
    title: "🍽️ Food/culinary exploration",
  },
  {
    key: "🛍️ Shopping/retail therapy",
    title: "🛍️ Shopping/retail therapy",
  },
  {
    key: "⛪ Religious pilgrimage",
    title: "⛪ Religious pilgrimage",
  },
  {
    key: "🙋‍♂️ Volunteer work/community service",
    title: "🙋‍♂️ Volunteer work/community service",
  },
  {
    key: "🏥 Health/medical tourism",
    title: "🏥 Health/medical tourism",
  },
  {
    key: "🌿 Nature/eco-tourism",
    title: "🌿 Nature/eco-tourism",
  },
  {
    key: "🎨 Art/cultural event",
    title: "🎨 Art/cultural event",
  },
  {
    key: "🎮 Gaming/convention",
    title: "🎮 Gaming/convention",
  },
  {
    key: "🏞️ Outdoor activities/recreation",
    title: "🏞️ Outdoor activities/recreation",
  },
];

export const getBusinessDetailsById = (id) => {
  if (!id) return;
  return fetch(`${BTLR_BETA_API_BASE}${business}/${id}`);
};

export const saveInvitedBusinessForLoggedInUser = (data, token, callback) => {
  const destroyLoader = buildBasicLoader();
  fetch(`${BTLR_BETA_API_BASE}${inviteBusinessLoggedURL}`, {
    method: "PUT",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data?.status) {
        if (callback) callback();
      } else {
        toast.error(
          data?.message ?? "Error saving preferences invited business",
          { theme: "dark" }
        );
      }
    })
    .catch((error) => {
      console.error("Error saving preferences invited business", error);
      // navigate("/login");
    })
    .finally(() => destroyLoader());
};
