import countriesList from "../../../data/countriesList";
import languagesList from "../../../data/languagesList";
import {
  BTLR_BETA_API_BASE,
  newBetaSignupURL,
  newBetaSignupWithBusinessURL,
  getUserDetailsURL,
  updateUserDetailsURL,
  joinBusinessWithOTPURL,
  businessNotifications,
  customPreferenceAPIURL,
} from "../../../api/base";

import {
  checkLoginCookieBeta,
  logoutBeta,
} from "../../../api/helpers/authCookies";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../api/loaders/basic";

export const b2cAccountInputs = [
  {
    name: "salutation",
    label: "salutation",
    type: "selectWithPopup",
    options: [
      { key: "Mr", title: "Mr" },
      { key: "Mrs", title: "Mrs" },
      { key: "King", title: "King" },
      { key: "Prince", title: "Prince" },
    ],
  },
  { name: "firstName", label: "first name", type: "text" },
  { name: "lastName", label: "last name", type: "text" },
  { name: "email", label: "email", type: "email" },
  {
    name: "country",
    label: "country",
    type: "selectWithPopup",
    options: [...countriesList],
  },
  {
    name: "language",
    label: "language",
    type: "selectWithPopup",
    options: [...languagesList],
  },
  { name: "dob", label: "date of birth", type: "date", datePickerType: "dob" },
  {
    type: "heading",
    label: (
      <span className="form_privacy_notice">
        By proceeding, I accept the BTLR Service and confirm that I have read{" "}
        <a
          href={
            "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
          }
          target="_blank"
          className="dull_blue_text"
        >
          BTLR's Privacy Notice
        </a>
        .
      </span>
    ),
  },
];

export const b2cAccountInputsForPopup = [
  {
    name: "salutation",
    label: "salutation",
    type: "select",
    options: [
      { key: "Mr", title: "Mr" },
      { key: "Mrs", title: "Mrs" },
      { key: "King", title: "King" },
      { key: "Prince", title: "Prince" },
    ],
  },
  { name: "firstName", label: "first name", type: "text" },
  { name: "lastName", label: "last name", type: "text" },
  { name: "email", label: "email", type: "email" },
  {
    name: "country",
    label: "country",
    type: "select",
    options: [...countriesList],
  },
  {
    name: "language",
    label: "language",
    type: "select",
    options: [...languagesList],
  },
  { name: "dob", label: "date of birth", type: "date", datePickerType: "dob" },
  {
    type: "heading",
    label: (
      <span className="form_privacy_notice">
        By proceeding, I accept the BTLR Service and confirm that I have read{" "}
        <a
          href={
            "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
          }
          target="_blank"
          className="dull_blue_text"
        >
          BTLR's Privacy Notice
        </a>
        .
      </span>
    ),
  },
];

export const betaSignup = (BTLRBeta, callback) => {
  console.log("Signup post data", BTLRBeta);
  const destroyLoader = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + newBetaSignupWithBusinessURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(BTLRBeta),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Signup result", data);
      if (data?.status) {
        if (callback) callback();
      } else {
        toast.error(
          data?.message ?? "Error occured while signing up. Try again.",
          { theme: "dark" }
        );
      }
    })
    .finally(() => destroyLoader());
};

export const getUserDataBeta = (setBTLRBeta, token, callback) => {
  const destroyLoader = buildBasicLoader();

  fetch(BTLR_BETA_API_BASE + getUserDetailsURL, {
    method: "GET",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log("Logged in user data", data);

      if (data?.status) {
        const updateObject = {
          ...data.userData,
          ownedBusinesses: data?.ownedBusinesses,
          businesses: data.businesses,
          guestBusinessAdmin: data?.ownedBusinesses?.length === 0,
          loggedIn: true,
        };

        getBusinessNotifications(
          "all-notifications",
          checkLoginCookieBeta() ?? token,
          (data) => {
            const { status, notifications, unlinkedUsers } = data;
            if (!status) return;

            // parse data per business in key-value format
            const temp = {};

            notifications.forEach((n) => {
              const {
                isSubscriptionActive,
                pendingBusinessUsers,
                pendingGuests,
                pendingCustomPreferences,
                _id,
              } = n;

              temp[_id] = {
                isSubscriptionActive,
                pendingBusinessUsers,
                pendingCustomPreferences,
                pendingGuests,
                mainNotification:
                  !isSubscriptionActive ||
                  pendingBusinessUsers ||
                  pendingGuests ||
                  pendingCustomPreferences ||
                  unlinkedUsers?.length
                    ? true
                    : false,
              };
            });

            setBTLRBeta({
              ...updateObject,
              notifications: temp,
              unlinkedUsers: unlinkedUsers?.length,
              unlinkedGuestList: unlinkedUsers,
            });

            if (callback)
              callback(true, {
                ...updateObject,
                notifications: temp,
                unlinkedUsers: unlinkedUsers?.length,
                unlinkedGuestList: unlinkedUsers,
              });
          }
        );
      } else {
        if (callback) callback(false);
      }
    })
    .catch((error) => {
      window.location.href = "/login";
      console.error("Unauthorized", error);
    })
    .finally(() => destroyLoader());
};

export const updateUserDataBeta = (
  type = "", // business | preferences
  BTLRBeta,
  token,
  showLoader = true,
  callback
) => {
  let destroyLoader = () => {};
  if (showLoader) destroyLoader = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + updateUserDetailsURL + "/" + type, {
    method: "PUT",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(BTLRBeta),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Update user data result", data);
      // if (!data.status) toast.error(data.message);
      if (data?.status) {
        if (callback) callback();
      } else {
        toast.error(
          data?.message ?? "Error occured while updating. Try again.",
          {
            theme: "dark",
          }
        );
      }
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      // logoutBeta();
    })
    .finally(() => destroyLoader());
};

export const createCustomPreferenceAPI = (
  category,
  title,
  linkedBusinesses,
  token
) => {
  fetch(BTLR_BETA_API_BASE + customPreferenceAPIURL, {
    method: "POST",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({ custom: { category, title, linkedBusinesses } }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Create custom preference result", data);
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      // logoutBeta();
    });
};

export const updateCustomPreferenceAPI = (
  category,
  newCategory,
  title,
  newTitle,
  linkedBusinesses,
  token,
  childEditUploadFunctions = []
) => {
  fetch(BTLR_BETA_API_BASE + customPreferenceAPIURL, {
    method: "PUT",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      custom: {
        category,
        newCategory: newCategory ?? category,
        title,
        newTitle: newTitle ?? title,
        linkedBusinesses,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Update custom preference result", data);
      if (data?.status && childEditUploadFunctions.length) {
        childEditUploadFunctions.forEach((f) => f());
      }
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      // logoutBeta();
    });
};

export const deleteCustomPreferenceAPI = (category, title, token) => {
  fetch(BTLR_BETA_API_BASE + customPreferenceAPIURL, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      category,
      title,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Delete custom preference result", data);
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      // logoutBeta();
    });
};

export const joinBusinessWithOTP = (
  otp,
  token,
  showLoader = true,
  callback
) => {
  let destroyLoader = () => {};
  if (showLoader) destroyLoader = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + joinBusinessWithOTPURL, {
    method: "POST",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({ otp }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Update user data result", data);

      if (callback) callback(data);
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      logoutBeta();
    })
    .finally(() => destroyLoader());
};

const getBusinessNotifications = (businessId, token, callback) => {
  fetch(BTLR_BETA_API_BASE + businessNotifications + businessId, {
    method: "GET",
    headers: {
      authorization: `Bearer ${checkLoginCookieBeta() ?? token}`,
      "content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (callback) callback(data);
    })
    .catch((error) => {
      console.error("Unauthorized", error);

      // logoutBeta();
    });
};
