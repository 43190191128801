export default [
  {
    key: "am",
    title: "Amharic",
  },
  {
    key: "ar",
    title: "Arabic",
  },
  {
    key: "eu",
    title: "Basque",
  },
  {
    key: "bn",
    title: "Bengali",
  },
  {
    key: "en-GB",
    title: "English (UK)",
  },
  {
    key: "bg",
    title: "Bulgarian",
  },
  {
    key: "ca",
    title: "Catalan",
  },
  {
    key: "chr",
    title: "Cherokee",
  },
  {
    key: "hr",
    title: "Croatian",
  },
  {
    key: "cs",
    title: "Czech",
  },
  {
    key: "da",
    title: "Danish",
  },
  {
    key: "nl",
    title: "Dutch",
  },
  {
    key: "en",
    title: "English (US)",
  },
  {
    key: "et",
    title: "Estonian",
  },
  {
    key: "fil",
    title: "Filipino",
  },
  {
    key: "fi",
    title: "Finnish",
  },
  {
    key: "fr",
    title: "French",
  },
  {
    key: "de",
    title: "German",
  },
  {
    key: "el",
    title: "Greek",
  },
  {
    key: "gu",
    title: "Gujarati",
  },
  {
    key: "iw",
    title: "Hebrew",
  },
  {
    key: "hi",
    title: "Hindi",
  },
  {
    key: "hu",
    title: "Hungarian",
  },
  {
    key: "is",
    title: "Icelandic",
  },
  {
    key: "id",
    title: "Indonesian",
  },
  {
    key: "it",
    title: "Italian",
  },
  {
    key: "ja",
    title: "Japanese",
  },
  {
    key: "kn",
    title: "Kannada",
  },
  {
    key: "ko",
    title: "Korean",
  },
  {
    key: "lv",
    title: "Latvian",
  },
  {
    key: "lt",
    title: "Lithuanian",
  },
  {
    key: "ms",
    title: "Malay",
  },
  {
    key: "ml",
    title: "Malayalam",
  },
  {
    key: "mr",
    title: "Marathi",
  },
  {
    key: "no",
    title: "Norwegian",
  },
  {
    key: "pl",
    title: "Polish",
  },
  {
    key: "pt-PT",
    title: "Portuguese",
  },
  {
    key: "ro",
    title: "Romanian",
  },
  {
    key: "ru",
    title: "Russian",
  },
  {
    key: "sr",
    title: "Serbian",
  },
  {
    key: "zh-CN",
    title: "Chinese",
  },
  {
    key: "sk",
    title: "Slovak",
  },
  {
    key: "sl",
    title: "Slovenian",
  },
  {
    key: "es",
    title: "Spanish",
  },
  {
    key: "sw",
    title: "Swahili",
  },
  {
    key: "sv",
    title: "Swedish",
  },
  {
    key: "ta",
    title: "Tamil",
  },
  {
    key: "te",
    title: "Telugu",
  },
  {
    key: "th",
    title: "Thai",
  },
  {
    key: "tr",
    title: "Turkish",
  },
  {
    key: "ur",
    title: "Urdu",
  },
  {
    key: "uk",
    title: "Ukrainian",
  },
  {
    key: "vi",
    title: "Vietnamese",
  },
  {
    key: "cy",
    title: "Welsh",
  },
];
