import React from "react";

import B2CNav from "./b2cNav";

const BetaContainer = ({ children }) => {
  return (
    <div className="beta_container">
      <div className="bg_container">
        <B2CNav />
      </div>
      <div className="beta_app">{children}</div>
    </div>
  );
};

export default BetaContainer;
