export default [
  {
    id: 10,
    salutation: "Baroness",
  },
  {
    id: 20,
    salutation: "Barrister",
  },
  {
    id: 30,
    salutation: "Captain",
  },
  {
    id: 40,
    salutation: "Chancellor",
  },
  {
    id: 50,
    salutation: "Chief",
  },
  {
    id: 60,
    salutation: "Colonel",
  },
  {
    id: 70,
    salutation: "Commodore",
  },
  {
    id: 80,
    salutation: "Constable",
  },
  {
    id: 90,
    salutation: "Count",
  },
  {
    id: 100,
    salutation: "Countess",
  },
  {
    id: 110,
    salutation: "Deacon",
  },
  {
    id: 120,
    salutation: "Dean",
  },
  {
    id: 130,
    salutation: "Doctor",
  },
  {
    id: 140,
    salutation: "Duchess",
  },
  {
    id: 150,
    salutation: "Duke",
  },
  {
    id: 160,
    salutation: "Earl",
  },
  {
    id: 170,
    salutation: "Emperor",
  },
  {
    id: 180,
    salutation: "Empress",
  },
  {
    id: 190,
    salutation: "Governor",
  },
  {
    id: 200,
    salutation: "Judge",
  },
  {
    id: 210,
    salutation: "King",
  },
  {
    id: 220,
    salutation: "Lord",
  },
  {
    id: 230,
    salutation: "Maharajah",
  },
  {
    id: 240,
    salutation: "Major",
  },
  {
    id: 250,
    salutation: "Marshal",
  },
  {
    id: 260,
    salutation: "Master",
  },
  {
    id: 270,
    salutation: "Mayor",
  },
  {
    id: 280,
    salutation: "Minister",
  },
  {
    id: 290,
    salutation: "Ms.",
  },
  {
    id: 300,
    salutation: "Mrs.",
  },
  {
    id: 310,
    salutation: "Mr.",
  },
  {
    id: 320,
    salutation: "Mx.",
  },
  {
    id: 330,
    salutation: "Officer",
  },
  {
    id: 340,
    salutation: "Patron",
  },
  {
    id: 350,
    salutation: "Premier",
  },
  {
    id: 360,
    salutation: "President",
  },
  {
    id: 370,
    salutation: "Prince",
  },
  {
    id: 380,
    salutation: "Princess",
  },
  {
    id: 390,
    salutation: "Professor",
  },
  {
    id: 400,
    salutation: "Queen",
  },
  {
    id: 410,
    salutation: "Senator",
  },
  {
    id: 420,
    salutation: "Sergeant",
  },
  {
    id: 430,
    salutation: "Sheikh",
  },
  {
    id: 440,
    salutation: "Sir",
  },
  {
    id: 450,
    salutation: "Sultan",
  },
];
