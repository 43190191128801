import {
  BTLR_BETA_API_BASE,
  loginUserBetaBusinessURL,
  generateGPTPreferencePromptURL,
  checkUserPreferencesAccessURL,
  sharePreferenceWithAnotherBusinessURL,
  allUsersSharedWithBusiness,
  betaGetBusinessProfile,
  betaUpdateBusinessProfile,
  betaStripeCheckoutURL,
  betaStripeManageSubscriptionURL,
  betaBusinessGetMembersURL,
  betaBusinessInviteTeamMemberURL,
  betaBusinessUpdateMemberRoleURL,
  betaBusinessApproveMemberURL,
  betaBusinessRejectMemberURL,
  getBusinessTypesURL,
} from "../../../api/base";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../api/loaders/basic";
import {
  checkLoginCookieBetaBusiness,
  logoutBetaBusiness,
} from "../../../api/helpers/authCookies";

const fetchBusinessTypesFromAPI = (callback) => {
  fetch(BTLR_BETA_API_BASE + getBusinessTypesURL, {
    method: "GET",
    headers: { "content-type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data?.businessTypes ?? []);
    });
};

export const updateBusinessTypesOptionsInList = (index, inputs, setInputs) => {
  fetchBusinessTypesFromAPI((data) => {
    if (!data || !data?.length) return;
    const temp = [];
    data.forEach((item) => {
      if (!item?.name) return;
      temp.push({ key: item?.name, title: item?.name });
    });
    let updatedInputs = [...inputs];
    updatedInputs[index].filterOptions = temp;
    setInputs(updatedInputs);
  });
};

export const preferenceInputs = [
  {
    name: "businessType",
    label: "Business Type",
    type: "filterOptions",
    filterOptions: [
      { key: "hotel", title: "Hotel" },
      { key: "cafe", title: "Cafe" },
    ],
  },
  {
    name: "question",
    label: "Describe Activity, Situation or Role",
    type: "text",
  },
];

export const loginInputs = [
  { name: "email", label: "Business Email", type: "email" },
  {
    name: "consent",
    label: "Please tick this box to confirm that we can contact you.",
    type: "checkbox",
  },
];

export const sharePreferenceInputs = [
  { name: "email", label: "Email", type: "email" },
  {
    name: "accessType",
    label: "type",
    type: "select",
    options: [
      { key: "MEMBER", title: "team" },
      { key: "ADMIN", title: "admin" },
    ],
  },
];

export const examples = [
  { key: "hotel", title: "Hotel" },
  // { key: "cafe", title: "Cafe" },
  // { key: "restaurant", title: "Restaurant" },
  // { key: "dhaba", title: "Dhaba" },
  // { key: "icecream", title: "Icecream" },
];

export const examplesData = {
  hotel: {
    businessType: "Hotel",
    question: "Breakfast recommendation?",
    result:
      "For breakfast, then guest might prefer healthy whole food options, such as wholesome grains and vegetables. The guest might also prefer a cold orange juice, or hot drinks such as a latte or rooibos tea. Additionally offer them honey as a sweetener.",
  },
};

export const preferenceLinksFormInputs = [
  {
    name: "heading",
    label: "heading",
    type: "text",
  },
  {
    name: "subHeading",
    label: "subheading",
    type: "text",
  },
  {
    name: "url",
    label: "URL www.beta.btlr.vip/",
    type: "text",
  },
  {
    name: "image",
    label: "image",
    type: "image",
    notRequired: true,
  },
  {
    name: "enableSharePage",
    label: "Show preference Share page",
    type: "switch",
    notRequired: true,
  },
];

export const businessBetaLogin = (email, user_id, callback, failCallback) => {
  fetch(BTLR_BETA_API_BASE + loginUserBetaBusinessURL, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ email, userId: user_id }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        callback();
      } else {
        if (failCallback) failCallback();
        // toast.error(data?.message ?? "Unable to submit. Try again", {
        //   theme: "dark",
        // });
      }
    });
};

export const updateBetaBusinessProfile = (
  values,
  token,
  callback,
  businessId
) => {
  const destroy = buildBasicLoader();

  fetch(BTLR_BETA_API_BASE + betaUpdateBusinessProfile + businessId, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        toast.success("Profile updated!", {
          theme: "dark",
        });
        if (callback) callback();
      } else {
        toast.error(data?.message ?? "Unable to load profile. Try again", {
          theme: "dark",
        });
      }
    })
    .finally(() => destroy());
};

export const generatePreferencesOfUser = (
  businessId,
  userId,
  businessType,
  question,
  language,
  callback,
  failCallback,
  token
) => {
  fetch(BTLR_BETA_API_BASE + generateGPTPreferencePromptURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({
      businessId,
      userId,
      businessType,
      question,
      language,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (!data?.preferenceString) {
        failCallback();
        toast.error(
          data?.message ??
            "This user has revoked your business from accessing their preferences",
          {
            theme: "dark",
          }
        );
        callback(data);
        return;
      }
      if (data?.status) {
        callback(data);
        try {
          localStorage.setItem(
            "preferenceValues",
            JSON.stringify({ businessType, question, language })
          );
        } catch (error) {
          console.error(
            "Error while storing preference form inputs in local storage",
            error
          );
        }
      } else {
        toast.error(data?.message ?? "Unable to generate prompt. Login again", {
          theme: "dark",
        });
        // logoutBetaBusiness();
        // window.location.reload();
      }
    });
};

export const checkBusinessPreferenceAccess = (userId, token) => {
  fetch(BTLR_BETA_API_BASE + checkUserPreferencesAccessURL + userId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data?.status) {
        toast.error(
          data?.message ??
            "This user has revoked your business from accessing their preferences",
          {
            theme: "dark",
          }
        );
      }
    });
};

export const sharePreferenceWithAnotherBusiness = (
  userId,
  email,
  callback,
  token
) => {
  fetch(BTLR_BETA_API_BASE + sharePreferenceWithAnotherBusinessURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({ userId, email }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        toast.success("Preference Shared.", {
          theme: "dark",
        });
      } else {
        toast.error("Unable to share preference.", {
          theme: "dark",
        });
      }
    })
    .finally(() => callback());
};

export const getAllUsersSharedWithBusiness = (businessId, callback, token) => {
  fetch(BTLR_BETA_API_BASE + allUsersSharedWithBusiness + businessId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data?.users ?? []);
    })
    .catch((e) => {
      console.error(e);
      console.log("Unable to load users list.", {
        theme: "dark",
      });
    });
};

export const generateCheckoutURLBeta = (callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaStripeCheckoutURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({ businessId }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const generateStripeManagementURLBeta = (callback, token) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaStripeManageSubscriptionURL, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const getBusinessMembersBeta = (callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaBusinessGetMembersURL + businessId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data?.users);
      else console.log(data?.message ?? "Unable to load team members");
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const inviteBusinessMemberBeta = (
  values,
  callback,
  token,
  businessId
) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaBusinessInviteTeamMemberURL + businessId, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) {
        callback();
        toast.success("Invite sent!", { theme: "dark" });
      } else
        toast.error(data?.message ?? "Contact admin to add team member", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessAcceptMember = (_id, callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaBusinessApproveMemberURL, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({ approve: true, businessId, userId: _id }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.message ?? "Unable to load team members", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessRejectMember = (_id, callback, token, businessId) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaBusinessRejectMemberURL, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({ approve: false, businessId, userId: _id }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.message ?? "Unable to load team members", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};

export const betaBusinessUpdateMemberRole = (
  _id,
  accessType,
  callback,
  token,
  businessId
) => {
  const destroy = buildBasicLoader();
  fetch(BTLR_BETA_API_BASE + betaBusinessUpdateMemberRoleURL, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${checkLoginCookieBetaBusiness() ?? token}`,
    },
    body: JSON.stringify({ _id, accessType, businessId }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status) callback(data);
      else
        toast.error(data?.message ?? "Unable to update role of team member", {
          theme: "dark",
        });
    })
    .catch(() =>
      toast.error("Unable to process your request. Try again.", {
        theme: "dark",
      })
    )
    .finally(destroy);
};
