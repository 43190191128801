import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../../../contexts/btlrBeta.context";

import Button from "../../../../components/common/button/button";
import Form from "../../../../components/common/form/form";

import {
  b2bAccountInputs,
  b2bAccountInputsNonAdmin,
  b2bSignupInputsBusinessOnly,
  getSalutations,
} from "../../signup/signup.service";
import {
  generateCheckoutURLBeta,
  generateStripeManagementURLBeta,
  updateBetaBusinessProfile,
} from "../data.service";
import { addPlaceDetailsToSignupObject } from "../../signup/signup.service";

const B2BAccountModal = ({ businessLoginToken, businessData }) => {
  const navigate = useNavigate();
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [updatedSignUpInputs, setUpdatedSignUPInputs] = useState([]);
  const [prefilledValues, setPrefilledValues] = useState({});
  const [availableFreeTokens, setAvailableFreeTokens] = useState(0);
  const [usedTokens, setUsedTokens] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);

  const parseValuesForUpdate = (values) => {
    if (values?.address?.place_id) {
      addPlaceDetailsToSignupObject(
        {
          ...values,
          address: values?.address?.description,
          googlePlaceID: values?.address?.place_id,
        },
        values?.address?.place_id,
        ({ status, data }) => {
          if (status) {
            updateBetaBusinessProfile(
              data,
              businessLoginToken,
              () => {
                navigate("/businessbeta");
              },
              BTLRBeta?.selectedBusiness?._id
            );
            console.log("Update data", data);
          } else {
            toast.error(
              "Unable to verify business location details. Try again.",
              {
                theme: "dark",
              }
            );
          }
        }
      );
    } else {
      const updateData = { ...values, ...businessData };
      console.log("Update data", updateData);
      updateBetaBusinessProfile(
        updateData,
        businessLoginToken,
        () => {
          // navigate("/businessbeta");
        },
        BTLRBeta?.selectedBusiness?._id
      );
    }
  };

  useEffect(() => {
    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      let temp = [];

      temp = b2bSignupInputsBusinessOnly;
      // businessData?.accessType === "MEMBER"
      //   ? b2bAccountInputsNonAdmin
      //   : b2bAccountInputsNonAdmin;

      temp[0].options = parsedSalutationsData;
      setUpdatedSignUPInputs(temp);
    });
  }, [businessData]);

  useEffect(() => {
    if (!businessData?._id) return;

    // prepopulate
    const prepopulatedData = {
      businessName: "",
      businessType: "",
      address: "",
    };

    Object.keys(prepopulatedData).forEach((key) => {
      if (businessData[key]) prepopulatedData[key] = businessData[key];
    });

    setUsedTokens(businessData?.consumedPreferenceView ?? 0);
    setAvailableFreeTokens(businessData?.allocatedPreferenceView ?? 7);
    setActiveSubscription(businessData?.isSubscriptionActive);

    setPrefilledValues({ ...prepopulatedData });
  }, [businessData]);

  console.log(businessData);

  return (
    <div
      style={{
        background: "#000000",
        padding: "20px 40px",
        borderRadius: "12px",
      }}
    >
      {(businessData?.accessType === "ADMIN" ||
        businessData?.accessType === "GUEST") && (
        <div className="stripe_box">
          <div className="box_header">
            <div className="left">
              {!activeSubscription && (
                <p className="inner_heading white_text basier_medium">
                  {usedTokens}
                  <span style={{ color: "rgba(124, 125, 130, 0.6)" }}>
                    /{availableFreeTokens}
                  </span>
                </p>
              )}
              {businessData?.accessType === "ADMIN" && activeSubscription && (
                <p className="inner_heading white_text basier_medium">
                  £49,99 P/M
                </p>
              )}
              {!activeSubscription && (
                <p className="text white_text">Preference Views Remaining</p>
              )}
              {businessData?.accessType === "ADMIN" && activeSubscription && (
                <p className="text white_text">
                  <span style={{ color: "rgba(124, 125, 130, 0.6)" }}>
                    Billed Monthly
                  </span>{" "}
                  Active
                </p>
              )}
            </div>
            <div className="right">
              {!activeSubscription && businessData?.accessType === "ADMIN" && (
                <Button
                  text="Upgrade now"
                  type={"roundedSquare"}
                  size={"medium"}
                  color={"blue"}
                  callback={() => {
                    generateCheckoutURLBeta(
                      (data) => {
                        if (data?.checkoutURL)
                          window.location.href = data.checkoutURL;
                        else
                          toast.error(
                            "Unable to load checkout URL. Try again.",
                            {
                              theme: "dark",
                            }
                          );
                      },
                      businessLoginToken,
                      BTLRBeta?.selectedBusiness?._id
                    );
                  }}
                />
              )}
              <span className="vertical_space"></span>
              {!activeSubscription && businessData?.accessType === "GUEST" && (
                <Button
                  text="Sign Up Now"
                  type={"roundedSquare"}
                  size={"medium"}
                  color={"blue"}
                  callback={() => {
                    setBTLRBeta({
                      ...BTLRBeta,
                      b2bAccountModal: true,
                    });
                  }}
                />
              )}
              {activeSubscription && (
                <Button
                  text="Manage Billing"
                  type={"roundedSquare"}
                  size={"medium"}
                  color={"blue"}
                  callback={() => {
                    generateStripeManagementURLBeta((data) => {
                      if (data?.manageSubURL)
                        window.location.href = data.manageSubURL;
                      else
                        toast.error(
                          "Unable to load management URL. Try again.",
                          {
                            theme: "dark",
                          }
                        );
                    }, businessLoginToken);
                  }}
                />
              )}
            </div>
          </div>

          {!activeSubscription && businessData?.accessType === "ADMIN" && (
            <>
              <p className="inner_heading white_text basier_medium">
                £49,99 P/M
              </p>
              <p className="text light_grey_text">🌱 BTLR Basic</p>

              <div className="perks">
                <p className="text light_grey_text">
                  Everything thats included:
                </p>
                <ul>
                  <li>Unlimited Preference Views</li>
                  <li>Share Preferences</li>
                  <li>50 Team Members</li>
                </ul>
              </div>
            </>
          )}

          <span className="vertical_space"></span>

          <p className="text light_grey_text m">
            Need help?{" "}
            <a href="mailto:info@btlr.vip" className="blue_text">
              Contact Us
            </a>
          </p>
        </div>
      )}

      <Form
        inputs={updatedSignUpInputs}
        prefilledValues={prefilledValues}
        transparentInputs={true}
        submit_text={"Update"}
        custom_submit_2={true}
        size="small"
        submitForm={(values) =>
          parseValuesForUpdate({
            ...values,
            businessType: values?.businessType?.title ?? values?.businessType,
          })
        }
      />
      <span className="vertical_space"></span>
      {/* <button
          className="text red_text"
          style={{ background: "transparent" }}
          onClick={logoutBetaBusiness}
        >
          Logout?
        </button> */}
      <span className="vertical_space"></span>
    </div>
  );
};

export default B2BAccountModal;
