export const base_url = "https://xu89-g7gw-gqap.n7.xano.io/";

// // B2B
export const b2b_signin_url = "api:PWQgFQYl/b2b_signin";
export const b2b_signup_url = "api:PWQgFQYl/b2bsignup";
export const b2b_magic_login_url = "api:2uJTbIR9/auth/magic-login";
export const b2b_get_user_info = "api:2uJTbIR9/auth/me";

// Global
export const BTLR_Invite = "api:c8RPq3KH/btlrinvite";

export const S3_IMAGE_BASE_URL =
  "https://btlr-images.s3.eu-west-2.amazonaws.com/profile_picture/";

// New API Endpoints
export const AWS_DEV_API_BASE = "https://api-dev.aws.btlr.vip/v1/";
export const getSalutationsURL = "salutations";
export const getSubscriptionsData = "user_subscription";
export const generateCheckoutURL = "generateCheckoutURL";
export const generateStripePortalURL = "generateManageSubscriptionURL";

// Make.com endpoints
export const signUpMake =
  "https://hook.eu1.make.com/pk1q5aeb78tdtaiwtfl6uss1iywhw33p";
export const requestDemoMake =
  "https://hook.eu1.make.com/glsk1mii5q6rc5rcvsg7kn6g599b291d";
export const contactFormMake =
  "https://hook.eu1.make.com/u6ouj855t8p9ebu7jpi76xkrd9mvjfcf";

// BTLR Beta endpoints
// export const BTLR_BETA_API_BASE = 'http://localhost:4040/api/';
export const BTLR_BETA_API_BASE = "https://btlr-beta-api.aws.btlr.vip/api/";
export const S3_IMAGE_BASE_URL_BETA =
  "https://btlr-images.s3.eu-west-2.amazonaws.com/";

export const BTLR_BID = "64e2e38499cbf807fbddfcd1";
export const getBusinessPreferencesURL = "public/business-preferences/";
export const getPlacesPredictionsURL = "public/get-maps-predictions/";
export const getPlaceDetailsURL = "public/get-place-details/";
export const getBusinessTypesURL = "public/business-types";
export const newBetaSignupURL = "auth/user";
export const newBetaSignupWithBusinessURL = "auth/signup-with-business";
export const inviteBusinessLoggedURL = "users/preferences-with-business";
export const resendVerifyEmailURL = "auth/resend-confirm-email";
export const betaLoginVerifyEmailURL = "auth/verify-email";
export const betaLoginVerifyBusinessEmailURL = "auth/business-magic";
export const getUserDetailsURL = "users";
export const updateUserDetailsURL = "users";
export const getAllUserPreferences = "users/preferences";
export const customPreferenceAPIURL = "users/custom-preference";
export const loginUserBetaURL = "auth/user/login";
export const loginUserWithBusinessURL = "auth/login-with-business";
export const loginUserBetaBusinessURL = "auth/business";
export const signupUserBetaBusinessURL = "auth/business/signup";
export const addBusinessURL = "business";
export const generateGPTPreferencePromptURL = "business/preference-text";
export const checkUserPreferencesAccessURL =
  "business/user-preferences-for-business/";
export const sharePreferenceWithAnotherBusinessURL =
  "business/share-preference-b2b";
export const allUsersSharedWithBusiness = "/business/users/";
export const adminPreference = "admin/preferences/" + BTLR_BID;
export const saveAdminPreferences = "admin/preferences/" + BTLR_BID;
export const adminPreferenceLinksAPIURL = "admin/preference-page/";
export const adminGetImageSignedURL = "business/sign-image-url";
export const postBusinessEmail = "admin/business-email-update";
export const business = "public/business";
export const betaAdminLoginURL = "auth/admin";
export const betaGetBusinessProfile = "business/profile";
export const betaUpdateBusinessProfile = "business/profile/";
export const betaGetBusinessesList = "admin/business?filter=";
export const betaBusinessEmailUpdate = "admin/business-email-update";
export const betaPersonalAccountsCount = "admin/users-count";
export const betaBusinessDelete = "admin/business/";
export const betaAdminGetBusinessDetails = "admin/business/";
export const betaAdminUpdateBusinessDetails = "admin/business/";
export const betaAdminInviteTeamMemberURL = "admin/invite-user-to-business";
export const betaAdminAcceptOrRejectTeamMemberURL =
  "admin/handle-business-joining-request";
export const betaAdminUpdateTeamMemberRoleURL = "admin/business-user-role";
export const betaAdminUpdateTokens = "admin/update-preference-view-token";
export const betaAdminManuallyVerifyBusiness = "admin/manually-verify-business";
export const removeBusinessAccess = "users/disable-business";
export const getGPTPromptURL = "admin/prompt";
export const updateGPTPromptURL = "admin/prompt";
export const adminGetUserDetails = "admin/user/";
export const adminUpdateUserDetails = "admin/user/";
export const adminDeleteUser = "admin/user/";
export const adminPreferenceLinkAPIURL = "admin/preference-page/";
export const betaStripeCheckoutURL = "stripe/checkout";
export const betaStripeManageSubscriptionURL = "stripe/manage-subscription";
export const betaBusinessGetMembersURL = "business/members/";
export const betaBusinessInviteTeamMemberURL = "business/invite-user/";
export const betaBusinessUpdateMemberRoleURL = "business/user-role/";
export const betaBusinessApproveMemberURL = "business/handle-joining-request/";
export const betaBusinessRejectMemberURL = "business/reject-user/";
export const joinBusinessWithOTPURL = "business/join-business-with-otp";
export const businessPreferenceLinksAPIURL = "business/preference-page/";
export const businessGetImageSignedURL = "business/sign-image-url";
export const businessNotifications = "business/dashboard-details/";
export const businessPreferencesAPIURL = "business/preferences/";
export const linkGuestToBusinessAPIURL = "business/email-guests";
export const handleCustomPreferenceStatusAPIURL =
  "business/handle-user-custom-preference";
export const getBusinessPreviewPageDetailsAPIURL = "public/page-data-with-url/";
