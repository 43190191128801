import React, { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";
import {
  BTLR_BETA_API_BASE,
  getBusinessPreviewPageDetailsAPIURL,
} from "../../api/base";
import { buildBasicLoader } from "../../api/loaders/basic";

const LoadBusinessPreviewDetails = () => {
  const { url } = useParams();

  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  useEffect(() => {
    if (!url) {
      navigate("/");
      return;
    }

    const destroyLoader = buildBasicLoader();

    fetch(BTLR_BETA_API_BASE + getBusinessPreviewPageDetailsAPIURL + url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, pageData, preferences } = data;
        if (!status || !pageData || !preferences) {
          navigate("/");
          return;
        } else {
          console.log(data);
          setBTLRBeta({
            ...BTLRBeta,
            businessPreview: { pageData, preferences },
            businessId: pageData?.business,
            preferences: {
              custom: BTLRBeta?.preferences?.custom ?? [],
              predefined: BTLRBeta?.preferences?.prededined ?? [],
            },
          });
          navigate("/personal/edit-preferences");
        }
      })
      .catch((error) => {
        console.error("Unauthorized", error);
      })
      .finally(() => destroyLoader());
  }, [url]);

  return <>Loading business details</>;
};

export default LoadBusinessPreviewDetails;
