import React, { useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BusinessDashboardContainer from "./components/businessContainer";
import AdminEditPreferencesMain from "../../internal/components/adminEditPreferencesMain";

import { checkLoginCookieBetaBusiness } from "../../../api/helpers/authCookies";

import {
  BTLR_BETA_API_BASE,
  businessPreferencesAPIURL,
} from "../../../api/base";

const BusinessPreferences = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  return (
    <BusinessDashboardContainer>
      <section id="view-preference" className="internal_dashboard">
        {BTLRBeta?.selectedBusiness?._id && (
          <AdminEditPreferencesMain
            API_URL={
              BTLR_BETA_API_BASE +
              businessPreferencesAPIURL +
              BTLRBeta?.selectedBusiness?._id
            }
            businessId={BTLRBeta?.selectedBusiness?._id}
            token={
              checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken
            }
            theme={"dark"}
          />
        )}
      </section>
    </BusinessDashboardContainer>
  );
};

export default BusinessPreferences;
