import React, { useState, useEffect } from "react";

import { close_blue } from "../../../assets/svgs/action_icons";
import "./pillsInput.scss";
import { BTLR_BETA_API_BASE, getPlacesPredictionsURL } from "../../../api/base";
import { addPlaceDetailsToSignupObject } from "../../../pages/b2b/signup/signup.service";
import { customPrefTypes } from "../../../pages/b2c/editPreferences/pref_util";

const demoList = [
  { title: "Hotel" },
  { title: "Hostel" },
  { title: "Cafe" },
  { title: "Dhaba" },
];

let controller;
let signal;

const PillsInput = ({
  label,
  prefilledList = demoList,
  validationCallback,
  preSelected,
  googleMapSearch,
}) => {
  const [pills, setPills] = useState([]);
  const [typingPill, setTypingPill] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [focused, setFocused] = useState(false);
  const [locationSearchOptions, setLocationSearchOptions] = useState([]);

  const addPills = (pills, setPills, newPill) => {
    if (!newPill?.place_id) {
      const findExisting = pills.filter((pill) => pill.key === newPill.key);
      if (!findExisting.length) setPills([...pills, newPill]);
    } else {
      addPlaceDetailsToSignupObject(
        {
          ...newPill,
          key: newPill?.place_id,
          businessName: newPill?.description?.split(",")[0],
          address: newPill?.description,
          googlePlaceID: newPill?.place_id,
        },
        newPill.place_id,
        (placeDetails) => {
          setPills([
            ...pills,
            {
              ...placeDetails?.data,
              status: customPrefTypes.pending,
            },
          ]);
        }
      );
    }
  };

  const removePills = (pills, setPills, removeTag) => {
    const index = pills.indexOf(removeTag);
    const tempCopy = [...pills];
    tempCopy.splice(index, 1);
    setPills(tempCopy);
  };

  const filterList = (userInput) => {
    if (!userInput) {
      setFilteredList([]);
      return;
    }

    const filteredListResult = prefilledList.filter((pl) =>
      pl.title.toLowerCase().includes(userInput.toLowerCase())
    );
    setFilteredList(filteredListResult);

    const userInputExists = prefilledList.filter(
      (pl) => pl.title.toLowerCase() === userInput.toLowerCase()
    );
    if (!userInputExists.length && googleMapSearch) {
      searchForLocationOnMaps(userInput);
    } else {
      setLocationSearchOptions([]);
    }
  };

  const searchForLocationOnMaps = (searchText) => {
    if (!searchText) return;

    // Using abort controller so only last keystroke request gets processed
    controller?.abort();
    controller = new AbortController();
    signal = controller.signal;

    fetch(`${BTLR_BETA_API_BASE}${getPlacesPredictionsURL}${searchText}`, {
      method: "get",
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, predictions } = data;

        if (status === "OK") {
          setLocationSearchOptions(
            predictions.map((p) => ({
              ...p,
              add: true,
              key: p?.place_id,
              title: p.description,
            }))
          );
        } else {
          console.error("Error while searching Place on Google Maps");
        }
      })
      .catch((error) => {
        console.error("Error while searching Place on Google Maps", error);
      });
  };

  useEffect(() => {
    if (validationCallback) validationCallback(pills);
  }, [pills]);

  useEffect(() => {
    if (!preSelected || !preSelected?.length) return;

    console.log("Preselected", preSelected, prefilledList);

    let setPillsList = [];

    preSelected.forEach((ps) => {
      const findObject = prefilledList.filter((pl) => pl.key === ps.key);
      if (!findObject?.length) return;

      setPillsList.push({ ...findObject[0], status: ps?.status });
    });

    if (setPillsList.length) setPills(setPillsList);
  }, [preSelected]);

  return (
    <div className="pills_input_Wrapper">
      <div
        className={`pills_input ${
          focused || pills.length > 0 ? "focused" : ""
        }`}
      >
        {label && <span className="label">{label}</span>}
        <div className="selected_pills editable">
          {pills.map((pill, index) => {
            return (
              <div
                key={index}
                className={`selected_pill ${pill?.status?.toLowerCase()}`}
                onClick={() => {
                  removePills(pills, setPills, pill);
                }}
              >
                {pill.title} {close_blue}
              </div>
            );
          })}

          <input
            type={"text"}
            className="hidden_input"
            value={typingPill}
            onChange={(e) => {
              setTypingPill(e.target.value);
              filterList(e.target.value);
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     e.preventDefault();
            //     addPills(pills, setPills, typingPill);
            //     setTypingPill("");
            //   }
            // }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        </div>
      </div>
      {[...filteredList, ...locationSearchOptions].length > 0 && (
        <div className="filter_list">
          {[...filteredList, ...locationSearchOptions].map((item, index) => {
            return (
              <p
                key={index}
                className={`filter_item ${item.add ? "add" : ""}`}
                onClick={() => {
                  addPills(pills, setPills, item);
                  setTypingPill("");
                  setFilteredList([]);
                  setLocationSearchOptions([]);
                }}
              >
                {item.add ? "+ Add " : ""}
                {item.title}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PillsInput;
