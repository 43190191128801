import React, { useState, createContext } from "react";

export const ActionsContext = createContext();

export const ActionsProvider = ({ children }) => {
  const [actions, setActions] = useState({
    helpPopUpOpen: false,
    inviteTeamPopUpOpen: false,
    manageDepartmentsPopupOpen: false,
    addOrEditExperienceStepPopupOpen: false,
  });

  return (
    <ActionsContext.Provider value={[actions, setActions]}>
      {children}
    </ActionsContext.Provider>
  );
};
