import React from "react";

import { close_thin } from "../../../assets/svgs/action_icons";

import "./modal.scss";

const Modal = ({
  children,
  open,
  opener,
  backgroundColor,
  blur = false,
  biggerSize = false,
}) => {
  const closeModal = (e) => {
    if (e.target.classList.contains("modal_popup")) opener(!open);
  };

  return (
    <div
      className={`modal_popup ${!open ? "close" : "open"} ${
        biggerSize ? "bigger_size" : ""
      }`}
      style={{ backdropFilter: !blur ? "none" : "" }}
      onClick={(e) => {
        e.stopPropagation();
        closeModal(e);
      }}
    >
      <button className="close_btn_modal" onClick={() => opener(!open)}>
        {close_thin}
      </button>
      <div
        className={"popup_container"}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
