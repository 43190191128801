import React, { useContext } from "react";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";

import PreferenceLinkComp from "../b2b/businessbeta/components/preferenceLinksComp";

import InternalDashboardContainer from "./components/container";

import {
  BTLR_BETA_API_BASE,
  BTLR_BID,
  adminPreferenceLinkAPIURL,
} from "../../api/base";

const InternalPreferenceLinks = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);

  return (
    <InternalDashboardContainer>
      <PreferenceLinkComp
        theme="light"
        businessId={BTLR_BID}
        token={BTLRBeta?.betaAdminToken}
        API_URL={BTLR_BETA_API_BASE + adminPreferenceLinkAPIURL}
      />
    </InternalDashboardContainer>
  );
};

export default InternalPreferenceLinks;
