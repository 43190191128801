import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../../api/loaders/basic";

import HybridModal from "../../../../components/common/hybridModal/hybridModal";
import Form from "../../../../components/common/form/form";
import Button from "../../../../components/common/button/button";

import { preferenceLinksFormInputs } from "../data.service";
import {
  BTLR_BETA_API_BASE,
  S3_IMAGE_BASE_URL_BETA,
  businessGetImageSignedURL,
} from "../../../../api/base";

import {
  white_edit_icon,
  white_copy_icon,
} from "../../../../assets/svgs/action_icons";

import demo_preview_img from "../../../../assets/images/b2c/preferences/cover.png";

const PreferenceLinkComp = ({ theme = "dark", businessId, token, API_URL }) => {
  const [preferenceLinks, setPreferenceLinks] = useState([]);
  const [prefLinkForm, showPrefLinkForm] = useState(false);
  const [prefilledValues, setPrefilledValues] = useState({});
  const [editPreferenceLinkID, setEditPreferenceLinkID] = useState(undefined); // For editing preference link
  const [previewValues, setPreviewValues] = useState({});
  const [urlError, setURLError] = useState(false);

  // Save/Edit preference link
  const saveNewPreferenceLink = (values) => {
    const destroyLoader = buildBasicLoader();
    const postFinalData = (data) => {
      fetch(API_URL + businessId, {
        method: editPreferenceLinkID ? "PUT" : "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...data,
          enableSharePage: values?.enableSharePage ?? false,
          _id: editPreferenceLinkID ?? undefined, // To differentiate between new and update
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data?.status) {
            toast.error(data?.message ?? "Error saving the Preference Link");
            return;
          }
          console.log(data);
          getAllPreferenceLinks();
          setPreviewValues({});
          setPrefilledValues({});
          showPrefLinkForm(false);
        })
        .finally(destroyLoader);
    };

    if (values?.image && typeof values?.image !== "string") {
      fetch(BTLR_BETA_API_BASE + businessGetImageSignedURL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          type: values?.image?.type,
          name: `-${businessId}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const { finalName, url } = data;

          if (!finalName || !url) {
            return;
          }

          // Upload image to S3
          fetch(url, {
            method: "PUT",
            headers: {
              "content-type": values?.image?.type,
            },
            body: values?.image,
          })
            .then((response) => response.text())
            .then((data) => {
              console.log(data);

              postFinalData({
                ...values,
                image: finalName,
              });
            });
        });
    } else {
      postFinalData({ ...values, image: prefilledValues?.image ?? "" });
    }
  };

  // Get all existing prefernece links
  const getAllPreferenceLinks = () => {
    fetch(API_URL + businessId, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, preferencePages } = data;

        if (!status) {
          return;
        }

        setPreferenceLinks(preferencePages);
      });
  };

  // For testing valid URL
  useEffect(() => {
    const regex = /^[A-Za-z0-9_-]+$/;

    if (previewValues?.url && !regex.test(previewValues?.url)) {
      setURLError(true);
    } else {
      setURLError(false);
    }
  }, [previewValues]);

  // Initial fetch
  useEffect(() => {
    getAllPreferenceLinks();
  }, []);

  return (
    <div className={"pref-link-comp-" + theme}>
      <h1 className="heading basier">Preference Link</h1>
      <p className="text light_grey_text manrope">
        Setup a link for customers to select your business preferences.
      </p>

      <Button
        text="+ Add"
        type={"roundedSquare"}
        size={"medium"}
        color={"dark_mode_grey_light"}
        callback={() => {
          setEditPreferenceLinkID(undefined);
          setPreviewValues({});
          setPrefilledValues({});
          showPrefLinkForm(true);
        }}
      />

      <span className="vertical_space"></span>

      <div className="preference_links">
        {preferenceLinks.map((preferenceLink, index) => {
          const { _id, heading, subHeading, url, image, enableSharePage } =
            preferenceLink;
          return (
            <div key={index} className="link">
              <div className="titles">
                <h2 className="sub_heading manrope">{heading}</h2>
                <p className="text light_grey_text manrope">{subHeading}</p>
              </div>
              <div className="actions">
                <Button
                  extraClass="bg_transparent no_padding"
                  callback={() => {
                    setEditPreferenceLinkID(_id);
                    setPreviewValues({});
                    setPrefilledValues({
                      heading,
                      subHeading,
                      url,
                      image,
                      enableSharePage,
                    });
                    showPrefLinkForm(true);
                  }}
                >
                  {white_edit_icon}
                </Button>
                <Button
                  text={"Copy Link"}
                  extraClass="flex bg_transparent no_padding"
                  callback={() => {
                    copy(`https://www.beta.btlr.vip/${url}`);
                  }}
                >
                  {white_copy_icon}
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <HybridModal
        open={prefLinkForm}
        opener={() => showPrefLinkForm(false)}
        backgroundColor={theme === "dark" ? "#000000" : "#ffffff"}
        dark={theme === "dark"}
        blur
      >
        {showPrefLinkForm && (
          <div className="pref_link_form">
            <div className="form">
              <Form
                inputs={preferenceLinksFormInputs}
                greyInputs={theme === "dark"}
                prefilledValues={prefilledValues}
                continuousSubmit={(values) => setPreviewValues(values)}
                submit_text={"Save"}
                submitForm={(values) => saveNewPreferenceLink(values)}
              />
              {urlError && (
                <p
                  className="text red_text manrope"
                  style={{ marginTop: "6px" }}
                >
                  Only - and _ are allowed special characters in URL
                </p>
              )}
            </div>
            <div className="preview">
              <h2 className="sub_heading semi manrope center_align">
                {previewValues?.heading}
              </h2>
              <p className="text light_grey_text manrope center_align">
                {previewValues?.subHeading}
              </p>
              {previewValues?.image && (
                <>
                  <img
                    src={
                      !previewValues?.image
                        ? demo_preview_img // If we ever want to bring default preview back
                        : typeof previewValues?.image === "string"
                        ? S3_IMAGE_BASE_URL_BETA + previewValues?.image
                        : URL.createObjectURL(previewValues?.image)
                    }
                    alt="Preview of cover image"
                    className="preview_cover"
                  />
                  <span className="vertical_space"></span>
                </>
              )}
              <p className="text blue_text manrope">
                www.beta.btlr.vip/{previewValues?.url}
              </p>
              <Button extraClass="flex bg_transparent no_padding">
                {white_copy_icon} Copy Page Link
              </Button>
            </div>
          </div>
        )}
      </HybridModal>
    </div>
  );
};

export default PreferenceLinkComp;
