import React, { useState, useEffect, useContext } from "react";
import { BTLRBetaContext } from "../../contexts/btlrBeta.context";
import { toast } from "react-toastify";
import { buildBasicLoader } from "../../../src/api/loaders/basic";

import { format } from "date-fns";
import InternalDashboardContainer from "./components/container";
import PersonalAccountModal from "./components/personalAccountEditForm";

import { BTLR_BETA_API_BASE, betaPersonalAccountsCount } from "../../api/base";

import { getUserDetails, updateUserDetails, deleteUser } from "./service";

import { logoutBetaAdmin } from "../../api/helpers/authCookies";

import { arrow_right_grey_bg } from "../../assets/svgs/action_icons";

const InternalPersonalAccounts = () => {
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState();

  const getAllUsers = () => {
    const destroy = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${betaPersonalAccountsCount}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.usersCount) {
          setCount(data?.usersCount);
          setUsers(data?.users);
        } else {
          toast.error(
            data?.message ?? "Could not load the count. Try reloading the page."
          );
          logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(destroy);
  };

  useEffect(() => {
    if (!BTLRBeta?.internalPagesUnlocked) return;
    getAllUsers();
  }, [BTLRBeta?.internalPagesUnlocked]);

  return (
    <InternalDashboardContainer>
      <h2 className="heading black_text basier_medium">Personal Accounts</h2>
      <p className="sub_heading semi manrope lightBlack fourHundred">
        Manage personal Accounts
      </p>

      <div className="white_box">
        <h2 className="heading medium black_text basier_medium">{count}</h2>
        <p className="text manrope light_black_text">Total Accounts</p>
      </div>
      <div className="business_list" style={{ marginTop: 10 }}>
        {users.map(({ salutation, lastName, email, createdAt, _id }) => (
          <div key={_id} className="business_item">
            <div className="left">
              {
                <p className=" medium black_text basier_medium">
                  {salutation} {lastName}
                </p>
              }
              <p className="details">
                {new Date(createdAt)
                  ? format(new Date(createdAt), "dd.MM.yyyy")
                  : createdAt}{" "}
                | {email} <br />
              </p>
            </div>
            <div className="right">
              <button
                className="edit_email"
                onClick={() =>
                  getUserDetails(
                    _id,
                    (user) => {
                      setEditUser(user);
                      setBTLRBeta({ ...BTLRBeta, personalAccountModal: true });
                    },
                    BTLRBeta?.betaAdminToken
                  )
                }
              >
                {arrow_right_grey_bg}
              </button>
            </div>
          </div>
        ))}
      </div>

      <PersonalAccountModal
        userData={editUser}
        updateUserDetails={(id, values) => {
          updateUserDetails(
            id,
            values,
            () => {
              setEditUser(null);
              setBTLRBeta({ ...BTLRBeta, personalAccountModal: false });
              getAllUsers();
            },
            BTLRBeta?.betaAdminToken
          );
        }}
        deleteUser={(id) => {
          deleteUser(
            id,
            () => {
              setEditUser(null);
              setBTLRBeta({ ...BTLRBeta, personalAccountModal: false });
              getAllUsers();
            },
            BTLRBeta?.betaAdminToken
          );
        }}
      />
    </InternalDashboardContainer>
  );
};

export default InternalPersonalAccounts;
