import {
  BTLR_BETA_API_BASE,
  loginUserBetaURL,
  loginUserWithBusinessURL,
  newBetaSignupURL,
  resendVerifyEmailURL,
} from "../../../api/base";
import { buildBasicLoader } from "../../../api/loaders/basic";
import { toast } from "react-toastify";

export const signInUPInputs = [
  { name: "email", label: "email", type: "email" },
  {
    name: "privacy",
    type: "heading",
    label: (
      <span className="form_privacy_notice">
        By proceeding, I accept the BTLR Service and confirm that I have read{" "}
        <a
          href={
            "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
          }
          target="_blank"
        >
          BTLR's Privacy Notice
        </a>
      </span>
    ),
  },
];

export const signUPInputs = [
  { name: "salutation", label: "salutation", type: "text" },
  { name: "lname", label: "last name", type: "text" },
];

export const sendOTPInputs = [
  { name: "send_otp", label: "phone number", type: "tel" },
];

export const verifyOTPInputs = [
  { name: "verify_otp", label: "One Time Pin", type: "text" },
];

export const loginUserBeta = (email, callback, setEmailNotFoundError) => {
  const destroyLoader = buildBasicLoader();

  fetch(`${BTLR_BETA_API_BASE}${loginUserBetaURL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const { status, message } = data;

      if (status) callback();
      else {
        if (message.toLowerCase() === "user not found") {
          if (setEmailNotFoundError) setEmailNotFoundError(true);
        }
      }
    })
    .catch((error) => {
      console.error("Unauthorized", error);
    })
    .finally(() => destroyLoader());
};

export const loginUserBetaWithBusiness = (
  data,
  callback,
  setEmailNotFoundError
) => {
  const destroyLoader = buildBasicLoader();

  fetch(`${BTLR_BETA_API_BASE}${loginUserWithBusinessURL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const { status, message } = data;

      if (status) callback();
      else {
        if (message.toLowerCase() === "user not found") {
          if (setEmailNotFoundError) setEmailNotFoundError(true);
        }
      }
    })
    .catch((error) => {
      console.error("Unauthorized", error);
    })
    .finally(() => destroyLoader());
};

export const signupUserBeta = (values, callback) => {
  const destroyLoader = buildBasicLoader();

  fetch(`${BTLR_BETA_API_BASE}${newBetaSignupURL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const { status, message } = data;

      if (status) callback && callback();
      else {
        toast.error(message ?? "Signup failed", { theme: "dark" });
      }
    })
    .catch((error) => {
      console.error("Unauthorized", error);
    })
    .finally(() => destroyLoader());
};

export const resendConfirmationEmail = (values, callback) => {
  const destroyLoader = buildBasicLoader();

  fetch(`${BTLR_BETA_API_BASE}${resendVerifyEmailURL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const { status, message } = data;

      if (status) callback && callback();
      else {
        toast.error(message ?? "Signup failed", { theme: "dark" });
      }
    })
    .catch((error) => {
      console.error("Failed", error);
    })
    .finally(() => destroyLoader());
};
