import Cookies from "js-cookie";

export const setLoginCookie = (token) => {
  const authToken = Cookies.set("authToken", token, { expires: 30 });
  return authToken;
};

export const checkLoginCookie = () => {
  const authToken = Cookies.get("authToken");
  return authToken;
};

export const setLoginCookieBeta = (token) => {
  const authTokenBeta = Cookies.set("authTokenBeta", token, { expires: 30 });
  return authTokenBeta;
};

export const checkLoginCookieBeta = () => {
  const authTokenBeta =
    Cookies.get("authTokenBeta") ?? Cookies.get("authTokenBetaBusiness");
  return authTokenBeta;
};

export const setLoginCookieBetaBusiness = (token) => {
  const authTokenBetaBusiness = Cookies.set("authTokenBetaBusiness", token, {
    expires: 30,
  });
  return authTokenBetaBusiness;
};

export const checkLoginCookieBetaBusiness = () => {
  const authTokenBetaBusiness =
    Cookies.get("authTokenBetaBusiness") ?? Cookies.get("authTokenBeta");
  return authTokenBetaBusiness;
};

export const logoutBetaBusiness = () => {
  Cookies.remove("authTokenBeta");
  Cookies.remove("authTokenBetaBusiness");
  window.location.href = "/login/business";
};

export const setLoginCookieBetaAdmin = (token) => {
  const authTokenBetaAdmin = Cookies.set("authTokenBetaAdmin", token, {
    expires: 30,
  });
  return authTokenBetaAdmin;
};

export const checkLoginCookieBetaAdmin = () => {
  const authTokenBetaAdmin = Cookies.get("authTokenBetaAdmin");
  return authTokenBetaAdmin;
};

export const logoutBetaAdmin = (BTLRBeta, setBTLRBeta) => {
  Cookies.remove("authTokenBetaAdmin");
  setBTLRBeta({ ...BTLRBeta, internalPagesUnlocked: false });
};

export const logoutBeta = () => {
  Cookies.remove("authTokenBeta");
  Cookies.remove("authTokenBetaBusiness");
  window.location.href = "/login";
};

export const logout = (setLoggedData) => {
  // setLoggedData({});
  Cookies.remove("authToken");
};
