import React, { useState } from "react";

import { sortPreferences } from "../service";

import {
  grey_up_arrow,
  blue_edit_icon,
  blue_plus_icon,
  blue_up_arrow,
} from "../../../assets/svgs/action_icons";

const AdminEditPreference = ({
  index,
  showInActiveCategories,
  preference,
  preferences,
  setPreferences,
  editPreferenceTitle,
  addPreference,
  movePreference,
  deletePreference,
  orderPreferences,
  preferencesAreEdited,
  setNewTitleModal,
  setEditPreferenceModal,
  setExistingTitleError,
}) => {
  const [togglePreference, setTogglePreference] = useState(
    preference?.subCategories?.length === 0
  );

  if (preference) {
    const { _id, title, description, subCategories, isDeleted } = preference;

    let showSubCategories = [0];
    if (!showInActiveCategories)
      showSubCategories = subCategories.filter((sc) => !sc?.isDeleted);

    const sortedSubCategories = sortPreferences(subCategories);

    if (isDeleted && !showInActiveCategories) return;

    return (
      <div
        className={`preference ${
          togglePreference || showSubCategories?.length === 0 ? "open" : "close"
        }`}
      >
        <div className="preference_data">
          <div className="left">
            {showSubCategories?.length > 0 && (
              <span
                className="toggle"
                onClick={() => setTogglePreference(!togglePreference)}
              >
                {grey_up_arrow}
              </span>
            )}
            <p className="index">{index + 1}</p>

            <p className="title">
              {title}{" "}
              <span className="text small light_black_text">{description}</span>
            </p>
          </div>
          <div className="right">
            {!isDeleted && (
              <>
                <button
                  className="edit"
                  onClick={() => {
                    setExistingTitleError(false);
                    setEditPreferenceModal({
                      originalTitle: title,
                      originalDescription: description,
                      updatedTitle: title,
                      updatedDescription: description,
                      editAction: (updatedValues) => {
                        let adminPreferencesCopy = [...preferences];
                        const result = editPreferenceTitle(
                          adminPreferencesCopy,
                          _id,
                          updatedValues?.updatedTitle,
                          updatedValues?.updatedDescription,
                          setExistingTitleError
                        );
                        if (!result) return;
                        setPreferences([...adminPreferencesCopy]);
                        preferencesAreEdited([...adminPreferencesCopy]);
                        setEditPreferenceModal();
                      },
                      deleteAction: () => {
                        let adminPreferencesCopy = [...preferences];
                        deletePreference(adminPreferencesCopy, _id);
                        setPreferences([...adminPreferencesCopy]);
                        preferencesAreEdited([...adminPreferencesCopy]);
                        setEditPreferenceModal();
                      },
                    });
                  }}
                >
                  {blue_edit_icon}
                </button>

                <button
                  className="add"
                  onClick={() => {
                    let adminPreferencesCopy = [...preferences];
                    setNewTitleModal({
                      data: adminPreferencesCopy,
                      parentId: _id,
                      categoryTitle: title,
                    });
                  }}
                >
                  {blue_plus_icon}
                </button>
                <div className="move">
                  <button
                    className="up"
                    onClick={() => {
                      let adminPreferencesCopy = [...preferences];
                      movePreference(adminPreferencesCopy, _id, false);
                      orderPreferences(adminPreferencesCopy);
                      setPreferences([...adminPreferencesCopy]);
                      preferencesAreEdited([...adminPreferencesCopy]);
                    }}
                  >
                    {blue_up_arrow}
                  </button>
                  <button
                    className="down"
                    onClick={() => {
                      let adminPreferencesCopy = [...preferences];
                      movePreference(adminPreferencesCopy, _id, true);
                      orderPreferences(adminPreferencesCopy);
                      setPreferences([...adminPreferencesCopy]);
                      preferencesAreEdited([...adminPreferencesCopy]);
                    }}
                  >
                    {blue_up_arrow}
                  </button>
                </div>
              </>
            )}
            {isDeleted && (
              <button
                className="add undelete"
                onClick={() => {
                  let adminPreferencesCopy = [...preferences];
                  deletePreference(adminPreferencesCopy, _id, false);
                  setPreferences([...adminPreferencesCopy]);
                  preferencesAreEdited([...adminPreferencesCopy]);
                }}
              >
                Move to Active
              </button>
            )}
          </div>
        </div>
        {showSubCategories?.length > 0 && (
          <div
            className="sub_categories"
            style={{
              opacity: isDeleted ? 0.2 : 1,
              pointerEvents: isDeleted ? "none" : "unset",
            }}
          >
            {sortedSubCategories.map((sub, index) => {
              return (
                <AdminEditPreference
                  key={sub?._id ?? index}
                  index={index}
                  showInActiveCategories={showInActiveCategories}
                  preference={sub}
                  preferences={preferences}
                  setPreferences={setPreferences}
                  editPreferenceTitle={editPreferenceTitle}
                  deletePreference={deletePreference}
                  addPreference={addPreference}
                  movePreference={movePreference}
                  orderPreferences={orderPreferences}
                  preferencesAreEdited={preferencesAreEdited}
                  setNewTitleModal={setNewTitleModal}
                  setEditPreferenceModal={setEditPreferenceModal}
                  setExistingTitleError={setExistingTitleError}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
};

export default AdminEditPreference;
