import React, { useState, useEffect, useContext } from "react";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";
import { format } from "date-fns";

import BusinessDashboardContainer from "./components/businessContainer";

import Input from "../../../components/common/input/input";

import {
  BTLR_BETA_API_BASE,
  linkGuestToBusinessAPIURL,
} from "../../../api/base";
import { checkLoginCookieBetaBusiness } from "../../../api/helpers/authCookies";

import "./styles.scss";

const UnlinkedGuests = () => {
  const [BTLRBeta] = useContext(BTLRBetaContext);
  const [guestsList, setGuestsList] = useState([]);
  const [updatedGuests, setUpdatedGuests] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  const { unlinkedGuestList, ownedBusinesses } = BTLRBeta;

  useEffect(() => {
    if (!unlinkedGuestList?.length) return;

    const sharedUsersWithDates = {};
    let usersWithoutArrivalDate = [];

    unlinkedGuestList.forEach((su) => {
      const temp = {
        key: su?.user?._id,
        title: `${su?.user?.firstName} ${su?.user?.lastName}`,
        ...su,
      };

      temp?.visits?.forEach((visit) => {
        if (!visit?.arrivalDate) {
          usersWithoutArrivalDate.push({ ...temp, ...visit });
          return;
        }
        if (!sharedUsersWithDates[visit?.arrivalDate])
          sharedUsersWithDates[visit?.arrivalDate] = [];
        sharedUsersWithDates[visit?.arrivalDate].push({ ...temp, ...visit });
      });
    });

    sharedUsersWithDates["default"] = usersWithoutArrivalDate;

    // Sort by date
    const sortedKeys = Object.keys(sharedUsersWithDates).sort();
    const temp2 = {};
    sortedKeys.forEach((sk) => {
      temp2[sk] = sharedUsersWithDates[sk];
    });

    setGuestsList(temp2);
  }, []);

  useEffect(() => {
    if (!ownedBusinesses?.length) return;

    const parsedList = ownedBusinesses.map((ob) => {
      const { business: { _id, businessName } = {} } = ob;
      return { key: _id, title: businessName };
    });

    setBusinesses(parsedList);
  }, []);

  const handleLinkGuest = (userId, businessId) => {
    const token =
      checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken;

    if (!token) return;

    fetch(BTLR_BETA_API_BASE + linkGuestToBusinessAPIURL, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        businessId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setUpdatedGuests([...updatedGuests, userId]);
      });
  };

  return (
    <BusinessDashboardContainer>
      <section id="view-preference">
        <h1 className="heading basier">Link</h1>
        <p className="text light_grey_text manrope">
          Link guest to one of your businesses.
        </p>
        <div
          className="container rounded_box no_border"
          style={{ minHeight: "auto", padding: "22px", borderRadius: "24px" }}
        >
          <div className="unlinked_guests">
            {Object.keys(guestsList).map((key, index) => {
              if (!guestsList[key]?.length) return;
              const todayDate = new Date() === new Date(key);

              const headingText = todayDate
                ? "Today"
                : key === "default"
                ? "No Arrival Date"
                : format(new Date(key), "dd.MM.yyyy");

              return (
                <span key={key + index}>
                  <p className={`upcoming ${!todayDate ? "past" : ""}`}>
                    {headingText}
                  </p>
                  {guestsList[key].map((guest, index) => {
                    if (updatedGuests.includes(guest?.user?._id)) return;

                    return (
                      <div className="guest">
                        <div key={index} className="guest_details">
                          <h2 className="sub_heading semi manrope">
                            {guest?.title?.includes("undefined") ||
                            !guest?.title
                              ? guest?.user?.email
                              : guest?.title}
                          </h2>
                          <p className="text light_grey_text manrope">
                            {guest?.occasion} | {guest?._id}
                          </p>
                        </div>

                        <Input
                          type="select"
                          label={"Business"}
                          options={businesses}
                          validationCallback={(businessId) =>
                            handleLinkGuest(guest?.key, businessId)
                          }
                          greyInputs={true}
                        />
                      </div>
                    );
                  })}
                </span>
              );
            })}
          </div>
        </div>
      </section>
    </BusinessDashboardContainer>
  );
};

export default UnlinkedGuests;
