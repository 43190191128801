import React, { useState, createContext } from "react";

export const LoggedDataContext = createContext();

export const LoggedDataProvider = ({ children }) => {
  const [loggedData, setLoggedData] = useState({
    user: {
      email: "loading...",
      salutation: "",
      subscription: [],
      userId: "",
      userName: "loading...",
    },
    businesses: [],
  });

  return (
    <LoggedDataContext.Provider value={[loggedData, setLoggedData]}>
      {children}
    </LoggedDataContext.Provider>
  );
};
