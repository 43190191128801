import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";
import { buildBasicLoader } from "../../../api/loaders/basic";

import HybridModal from "../../../components/common/hybridModal/hybridModal";
import Modal from "../../../components/common/modal/modal";
import Button from "../../../components/common/button/button";
import Input from "../../../components/common/input/input";
import Form from "../../../components/common/form/form";
import ConfirmationModal from "../../../components/common/confirmationModal/confirmationModal";

import {
  BTLR_BETA_API_BASE,
  betaAdminUpdateTokens,
  betaAdminUpdateBusinessDetails,
  betaBusinessDelete,
} from "../../../api/base";

import {
  b2bAccountInputs,
  b2bAccountInputsBusinessOnly,
  getSalutations,
} from "../../b2b/signup/signup.service";

// import {
//   generateCheckoutURLBeta,
//   generateStripeManagementURLBeta,
// } from "../../b2b/verifyPreference/data.service";
import { addPlaceDetailsToSignupObject } from "../../b2b/signup/signup.service";
import { updateUserDetails } from "../service";

import {
  close_bg_white,
  blue_edit_icon,
} from "../../../assets/svgs/action_icons";

const AccountDetailsModal = ({ data, refresh, resetData }) => {
  // Types: verify | business-details
  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [updatedSignUpInputs, setUpdatedSignUPInputs] = useState([]);
  const [prefilledValues, setPrefilledValues] = useState({});
  const [availableFreeTokens, setAvailableFreeTokens] = useState(0);
  const [usedTokens, setUsedTokens] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateTokens, setUpdateTokens] = useState(false);
  const [updatedTokens, setUpdatedTokens] = useState(
    data?.allocatedPreferenceView ?? 0
  );

  const { AccountDetailsModalAdmin, betaAdminToken } = BTLRBeta;

  const { type, businessData, userData } = data;

  const updateBusinessDetails = (values, betaAdminToken, callback) => {
    const destroy = buildBasicLoader();
    console.log("In update function", values);
    fetch(
      `${BTLR_BETA_API_BASE}${betaAdminUpdateBusinessDetails}${
        businessData?._id ?? userData?._id
      }`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${betaAdminToken}`,
        },
        body: JSON.stringify(values),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status) {
          if (type === "verify") {
            updateUserDetails(
              userData?.user_id,
              values,
              () => {
                toast.success("Business user details updated successfully");
                setBTLRBeta({
                  ...BTLRBeta,
                  AccountDetailsModalAdmin: false,
                });
                if (refresh) refresh();
              },
              BTLRBeta?.betaAdminToken
            );
          } else if (refresh) {
            toast.success("Business details updated successfully");
            setBTLRBeta({
              ...BTLRBeta,
              AccountDetailsModalAdmin: false,
            });
            refresh();
          }
        } else {
          toast.error(data?.message ?? "Failed to update business. Try again.");
        }

        if (callback) callback();
      })
      .finally(destroy);
  };

  const deleteBusinssUser = () => {
    const destroy = buildBasicLoader();
    fetch(
      `${BTLR_BETA_API_BASE}${betaBusinessDelete}${
        businessData?._id ?? userData?._id
      }`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${betaAdminToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status) {
          toast.success("Business deleted successfully");
          if (refresh) {
            setBTLRBeta({
              ...BTLRBeta,
              AccountDetailsModalAdmin: false,
            });
            refresh();
          }
        } else {
          toast.error(data?.message ?? "Failed to delete business. Try again.");
        }
      })
      .finally(destroy);
  };

  const parseValuesForUpdate = (values) => {
    console.log("updates values", values);
    if (values?.address?.place_id) {
      addPlaceDetailsToSignupObject(
        {
          ...values,
          address: values?.address?.description,
          googlePlaceID: values?.address?.place_id,
        },
        values?.address?.place_id,
        ({ status, data }) => {
          if (status) {
            updateBusinessDetails(data, betaAdminToken, () =>
              setPrefilledValues({})
            );
            console.log("Update data", data);
          } else {
            toast.error(
              "Unable to verify business location details. Try again.",
              {
                theme: "dark",
              }
            );
          }
        }
      );
    } else {
      if (businessData?.address && businessData?.googlePlaceID) {
        // If old data available
        parseValuesForUpdate({
          ...values,
          address: {
            place_id: businessData?.googlePlaceID,
            description: businessData?.address,
            googlePlaceID: businessData?.googlePlaceID,
          },
        });
      } else {
        console.log("Do nothing for now if as it is free form", values, data);
        updateBusinessDetails(values, betaAdminToken, () =>
          setPrefilledValues({})
        );
        // toast.info("Please update the business location first.");
      }
    }
  };

  const updateBusinessTokens = () => {
    const destroy = buildBasicLoader();
    fetch(`${BTLR_BETA_API_BASE}${betaAdminUpdateTokens}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${BTLRBeta?.betaAdminToken}`,
      },
      body: JSON.stringify({
        id: businessData._id,
        additionalPreferenceCount: updatedTokens,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status) {
          setAvailableFreeTokens(updatedTokens);
          toast.success("Tokens updated successfully");
          setUpdateTokens(false);
          setUpdatedTokens(0);
        } else {
          toast.error(data?.message ?? "Failed to update tokens. Try again.");
          // logoutBetaAdmin(BTLRBeta, setBTLRBeta);
        }
      })
      .finally(destroy);
  };

  useEffect(() => {
    getSalutations((data) => {
      const parsedSalutationsData = data.salutations.map((s) => ({
        key: s.salutation,
        title: s.salutation,
      }));

      let temp = [];

      let tempB2bAccountInputs = [...b2bAccountInputs];
      tempB2bAccountInputs.pop();

      temp =
        type === "verify" ? tempB2bAccountInputs : b2bAccountInputsBusinessOnly;

      if (type !== "business-details") temp[0].options = parsedSalutationsData;
      setUpdatedSignUPInputs(temp);
    });
  }, [userData, businessData]);

  useEffect(() => {
    if (type !== "business-details") {
      return;
    }

    if (!businessData?._id) return;

    // prepopulate
    const prepopulatedData = {
      businessName: "",
      businessType: "",
      address: "",
      validityHours: 0,
    };

    Object.keys(prepopulatedData).forEach((key) => {
      if (businessData[key]) prepopulatedData[key] = businessData[key];
    });

    setUsedTokens(businessData.consumedPreferenceView ?? 0);
    setAvailableFreeTokens(businessData.allocatedPreferenceView ?? 0);
    setActiveSubscription(businessData.isSubscriptionActive);
    setPrefilledValues({ ...prepopulatedData });
  }, [businessData]);

  console.log("Prefilled values", prefilledValues);

  useEffect(() => {
    if (type === "business-details") {
      return;
    }

    if (!userData) return;

    // prepopulate
    const prepopulatedData = {
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      language: "",
      dob: "",
      businessName: "",
      businessType: "",
    };

    Object.keys(prepopulatedData).forEach((key) => {
      if (userData[key]) prepopulatedData[key] = userData[key];
    });

    setPrefilledValues({ ...prepopulatedData });
  }, [userData]);

  return (
    <>
      <HybridModal
        open={AccountDetailsModalAdmin}
        opener={() => {
          resetData(null);
          setBTLRBeta({ ...BTLRBeta, AccountDetailsModalAdmin: false });
        }}
        backgroundColor={"#ffffff"}
        blur
      >
        <div className="b2b_account_modal">
          <div className="beta_modal_top_area white_bg" style={{ margin: 0 }}>
            <h1
              className="heading basier black_text"
              style={{ marginBottom: 0 }}
            >
              Account
            </h1>
            <button
              className="close_thin"
              onClick={() =>
                setBTLRBeta({ ...BTLRBeta, AccountDetailsModalAdmin: false })
              }
            >
              {close_bg_white}
            </button>
          </div>
          <p className="text medium manrope light_black_text manrope ">
            All Account details are managed here.
          </p>

          {type === "business-details" && (
            <div className="stripe_box white">
              <div className="box_header">
                <div className="left">
                  {!activeSubscription && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "6px",
                      }}
                    >
                      <p
                        className="inner_heading black_text basier_medium"
                        style={{ margin: 0 }}
                      >
                        {usedTokens}
                        <span style={{ color: "rgba(124, 125, 130, 0.6)" }}>
                          /{availableFreeTokens}
                        </span>
                      </p>

                      <button
                        className="light_blue_btn"
                        onClick={() => setUpdateTokens(true)}
                      >
                        {blue_edit_icon} Edit
                      </button>
                    </div>
                  )}
                  {activeSubscription && (
                    <p className="inner_heading black_text basier_medium">
                      £49,99 P/M
                    </p>
                  )}
                  {!activeSubscription && (
                    <p className="text black_text">
                      Preference Views Remaining
                    </p>
                  )}
                  {activeSubscription && (
                    <p className="text black_text">
                      <span style={{ color: "rgba(124, 125, 130, 0.6)" }}>
                        Billed Monthly
                      </span>{" "}
                      Active
                    </p>
                  )}
                </div>
                {/* <div className="right">
                  {!activeSubscription && (
                    <Button
                      text="Upgrade now"
                      type={"roundedSquare"}
                      size={"medium"}
                      color={"blue"}
                      callback={() => {
                        generateCheckoutURLBeta((data) => {
                          if (data?.checkoutURL)
                            window.location.href = data.checkoutURL;
                          else
                            toast.error(
                              "Unable to load checkout URL. Try again.",
                              {
                                theme: "dark",
                              }
                            );
                        }, betaAdminToken);
                      }}
                    />
                  )}
                  {activeSubscription && (
                    <Button
                      text="Manage Billing"
                      type={"roundedSquare"}
                      size={"medium"}
                      color={"blue"}
                      callback={() => {
                        generateStripeManagementURLBeta((data) => {
                          console.log(data);
                          if (data?.manageSubURL)
                            window.location.href = data.manageSubURL;
                          else
                            toast.error(
                              "Unable to load management URL. Try again.",
                              {
                                theme: "dark",
                              }
                            );
                        }, betaAdminToken);
                      }}
                    />
                  )}
                </div> */}
              </div>

              {!activeSubscription && (
                <>
                  <p className="inner_heading black_text basier_medium">
                    £49,99 P/M
                  </p>
                  <p className="text light_black_text">🌱 BTLR Basic</p>

                  <div className="perks">
                    <p className="text light_black_text">
                      Everything thats included:
                    </p>
                    <ul>
                      <li>Unlimited Preference Views</li>
                      <li>Share Preferences</li>
                      <li>50 Team Members</li>
                    </ul>
                  </div>
                </>
              )}

              <span className="vertical_space"></span>

              <p className="text light_black_text">
                Need help?{" "}
                <a href="mailto:info@btlr.vip" className="blue_text">
                  Contact Us
                </a>
              </p>
            </div>
          )}
          {Object.keys(prefilledValues)?.length > 0 && (
            <Form
              inputs={updatedSignUpInputs}
              prefilledValues={prefilledValues}
              transparentInputs={false}
              submit_text={"Update"}
              custom_submit_2={true}
              size="small"
              submitForm={parseValuesForUpdate}
            />
          )}
          <span className="vertical_space"></span>
          <button
            className="text red_text"
            style={{ background: "transparent" }}
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            Delete Account?
          </button>

          <span className="vertical_space"></span>
        </div>
      </HybridModal>

      <ConfirmationModal
        open={confirmDelete}
        opener={setConfirmDelete}
        title={"Delete Account?"}
        info={
          "Confirm you want to delete this account? This action cannot be undone."
        }
        confirmButtonText={"Delete Account"}
        cancelButtonText={"Cancel"}
        confirmButtonColor={"dark_red"}
        confirmButtonCallback={() => {
          console.log("Delete this account");
          deleteBusinssUser();
        }}
      />

      <Modal
        open={updateTokens}
        opener={setUpdateTokens}
        backgroundColor={"transparent"}
        noBlur={true}
      >
        <div className="white_box">
          <h1 className="heading medium basier black_text">
            Update business trial
          </h1>
          <p className="text medium manrope manrope black_text">
            update the number of free preference views a business has
          </p>

          <Input
            type="text"
            label={"Business Tokens"}
            defaultValue={businessData?.allocatedPreferenceView ?? 0}
            value={updatedTokens}
            validationCallback={(value) => setUpdatedTokens(value)}
            enterPressEvent={() => {
              updateBusinessTokens();
            }}
          />
          <div className="update_business_btns">
            <Button
              type={"roundedSquare"}
              size={"medium"}
              color={"blue"}
              text={"Update & Send"}
              callback={() => {
                updateBusinessTokens();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountDetailsModal;
