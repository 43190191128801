import { toast } from "react-toastify";
import { loginOrSignUp, signUp } from "../../../api/b2b/signUpIn.service";
import {
  BTLR_BETA_API_BASE,
  signupUserBetaBusinessURL,
  addBusinessURL,
  getPlaceDetailsURL,
} from "../../../api/base";
import { buildBasicLoader } from "../../../api/loaders/basic";
import salutations from "../../../data/salutations";
import countriesList from "../../../data/countriesList";
import languagesList from "../../../data/languagesList";
import {
  checkLoginCookie,
  checkLoginCookieBeta,
} from "../../../api/helpers/authCookies";

export const SignInInputs = [{ name: "email", label: "email", type: "email" }];

export const verifyLoginInputs = [
  {
    name: "verification_code",
    label: "Enter Code",
    type: "text",
  },
];

export const signUPInputs = [
  { name: "email", label: "email", type: "email" },
  {
    name: "salutationName",
    label: "salutation",
    type: "select",
    options: [
      { key: "Mr", title: "Mr" },
      { key: "Mrs", title: "Mrs" },
      { key: "King", title: "King" },
      { key: "Prince", title: "Prince" },
    ],
  },
  { name: "lName", label: "last name", type: "text" },
];

export const sendOTPInputs = [
  { name: "send_otp", label: "phone number", type: "tel" },
];

export const verifyOTPInputs = [
  { name: "verify_otp", label: "One Time Pin", type: "text" },
];

export const signUpInFormSubmit = (e, fail, success) => {
  e.preventDefault();
  const email = e.target.email.value;
  loginOrSignUp(email, fail, success);
};

export const signUpFormSubmit = (e, fail = null) => {
  e.preventDefault();

  const formData = new FormData();

  for (let i = 0; i < e.target.elements.length; i++) {
    const targetInput = e.target.elements[i];

    if (targetInput.type === "submit") continue;

    if (targetInput.type === "file") {
      formData.append(targetInput.name, targetInput.files[0]);
    } else {
      formData.append(targetInput.name, targetInput.value);
    }
  }

  signUp(formData, fail);
};

export const b2bAccountInputs = [
  {
    name: "salutation",
    label: "salutation",
    type: "select",
    options: [
      { key: "Mr", title: "Mr" },
      { key: "Mrs", title: "Mrs" },
      { key: "King", title: "King" },
      { key: "Prince", title: "Prince" },
    ],
  },
  { name: "firstName", label: "first name", type: "text" },
  { name: "lastName", label: "last name", type: "text" },
  { name: "email", label: "email", type: "email" },
  {
    name: "country",
    label: "country",
    type: "select",
    options: [...countriesList],
  },
  {
    name: "language",
    label: "language",
    type: "select",
    options: [...languagesList],
  },
  { name: "dob", label: "date of birth", type: "date" },
  {
    name: "businessName",
    label: "business name",
    type: "text",
  },
  {
    name: "businessType",
    label: "business type",
    type: "filterOptions",
    filterOptions: [
      { key: "hotel", title: "Hotel" },
      { key: "cafe", title: "Cafe" },
    ],
  },
  {
    name: "address",
    label: "Business Address (If Applicable)",
    type: "location",
    notRequired: true,
  },
  {
    type: "heading",
    label: (
      <span className="form_privacy_notice">
        By proceeding, I accept the BTLR Service and confirm that I have read{" "}
        <a
          href={
            "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
          }
          target="_blank"
          className="dull_blue_text"
        >
          BTLR's Privacy Notice
        </a>
        .
      </span>
    ),
  },
];

export const b2bSignupInputsBusinessOnly = [
  {
    name: "businessName",
    label: "business name",
    type: "text",
  },
  {
    name: "businessType",
    label: "business type",
    type: "filterOptions",
    filterOptions: [
      { key: "hotel", title: "Hotel" },
      { key: "cafe", title: "Cafe" },
    ],
  },
  {
    name: "address",
    label: "Business Address (If Applicable)",
    type: "location",
    notRequired: true,
  },
];

export const b2bAccountInputsNonAdmin = [
  {
    name: "salutation",
    label: "salutation",
    type: "select",
    options: [
      { key: "Mr", title: "Mr" },
      { key: "Mrs", title: "Mrs" },
      { key: "King", title: "King" },
      { key: "Prince", title: "Prince" },
    ],
  },
  { name: "firstName", label: "first name", type: "text" },
  { name: "lastName", label: "last name", type: "text" },
  { name: "email", label: "email", type: "email" },
  {
    name: "country",
    label: "country",
    type: "select",
    options: [...countriesList],
  },
  {
    name: "language",
    label: "language",
    type: "select",
    options: [...languagesList],
  },
  { name: "dob", label: "date of birth", type: "date" },
  {
    type: "heading",
    label: (
      <span
        className="b2c_account_notice"
        style={{ color: "rgba(255, 255, 255, 0.65)" }}
      >
        Without a business location your will need manual verification by our
        team. By proceeding, I accept the BTLR Service and confirm that I have
        read{" "}
        <a
          href={
            "https://btlr.notion.site/Privacy-Notice-4c7be718c00340bd8ebefe847565e886"
          }
          target="_blank"
          className="dull_blue_text"
        >
          BTLR's Privacy Notice
        </a>
      </span>
    ),
  },
];

export const b2bAccountInputsBusinessOnly = [
  {
    name: "businessName",
    label: "business name",
    type: "text",
    notRequired: true,
  },
  {
    name: "businessType",
    label: "business type",
    type: "filterOptions",
    filterOptions: [
      { key: "hotel", title: "Hotel" },
      { key: "cafe", title: "Cafe" },
    ],
    notRequired: true,
  },
  {
    name: "address",
    label: "Business Address (If Applicable)",
    type: "location",
    notRequired: true,
  },
  {
    name: "validityHours",
    label: "validity of preference access hours",
    type: "text",
    notRequired: true,
  },
];

export const getSalutations = (callback) => {
  // fetch(AWS_DEV_API_BASE + getSalutationsURL)
  //   .then((response) =>
  //     response.json().then((data) => {
  //       callback(data);
  //     })
  //   )
  //   .catch((error) => console.error(error));
  callback({ salutations });
};

export const businessBetaSignup = (values, callback) => {
  fetch(BTLR_BETA_API_BASE + signupUserBetaBusinessURL, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...values, signupType: "b2b" }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback({ ...data, ...values });
    });
};

export const addBusinessAPI = (values, callback, token) => {
  fetch(BTLR_BETA_API_BASE + addBusinessURL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${token ?? checkLoginCookieBeta()}`,
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    });
};

export const getPlaceDetails = (place_id) => {
  if (!place_id) return;
  return fetch(`${BTLR_BETA_API_BASE}${getPlaceDetailsURL}${place_id}`);
};

export const addPlaceDetailsToSignupObject = async (
  values,
  place_id,
  callback
) => {
  const destroyLoader = buildBasicLoader();
  try {
    await getPlaceDetails(place_id)
      .then((response) => response.json())
      .then((data) => {
        const { result, status } = data;

        const businessData = { ...values };

        if (status === "OK") {
          const { website, geometry } = result;
          const { location } = geometry;
          businessData.lat = location.lat.toString();
          businessData.long = location.lng.toString();
          businessData.websiteURL = website;

          callback({ status: 1, data: businessData });
        }
      });
    destroyLoader();
  } catch (error) {
    callback({ status: 0 });
    destroyLoader();
    console.error("Unable to fetch place details", error);
  }
};
