import React, { useState, useEffect, createRef } from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import SlideInPopup from "../slideInPopup/slideInPopup";
import Button from "../button/button";
import Switch from "../switch/switch";

import "react-phone-input-2/lib/style.css";
import "react-day-picker/dist/style.css";
import "./input.scss";
import { purple_checkmark } from "../../../assets/svgs/action_icons";
import { search_magnifier_white_svg } from "../../../assets/svgs/icons";

import { BTLR_BETA_API_BASE, getPlacesPredictionsURL } from "../../../api/base";
import { Hours, Minutes } from "./input.defaults";

let controller;
let signal;

const Input = ({
  name,
  label,
  type = "text",
  options,
  validationCallback = null,
  defaultValue = "",
  disableInput = false,
  transparentInputs = false,
  greyInputs = false,
  icon = null,
  noAnimation = false,
  refVar = null,
  autoFocus = false,
  enterPressEvent,
  filterOptions = [],
  noFilterOptionText,
  datePickerType = "default", // default, dob
  hideSelectLabel = false,
  selectPopupAutoOpenOptions,
  selectPopupBackAction,
  selectPopupNextButtonText,
  selectPopupNextAction,
}) => {
  const [value, setValue] = useState("");
  const [imageSizeError, setImageSizeError] = useState(false);
  const [phoneInputFocused, setPhoneInputFocused] = useState(false);
  const [selectOptionSelected, setSelectOptionSelected] = useState(false);
  const [selectWithPopupOpen, setSelectWithPopupOpen] = useState(false);
  const [popupOptions, setPopupOptions] = useState(options);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [datePickerDefaultValue, setDatePickerDefaultValue] = useState(
    new Date()
  );
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [popupOptionSearchFilter, setPopupOptionsSearchFilter] = useState("");
  const [locationSearchOptions, setLocationSearchOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filterInputSearchText, setFilterInputSearchText] = useState();

  const imageInputRef = createRef();

  const searchForLocationOnMaps = (searchText) => {
    if (!searchText) return;

    // Using abort controller so only last keystroke request gets processed
    controller?.abort();
    controller = new AbortController();
    signal = controller.signal;

    fetch(`${BTLR_BETA_API_BASE}${getPlacesPredictionsURL}${searchText}`, {
      method: "get",
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, predictions } = data;

        if (status === "OK") {
          setLocationSearchOptions(predictions);
        } else {
          console.error("Error while searching Place on Google Maps");
        }
      })
      .catch((error) => {
        console.error("Error while searching Place on Google Maps", error);
      });
  };

  const filterOptionsFunc = (value) => {
    if (value === "") {
      setFilteredOptions([]);
      return;
    }

    const temp = [...filterOptions].filter((option) =>
      option.title.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(temp);
  };

  useEffect(() => {
    try {
      if (type === "time") {
        setHour(defaultValue?.hour);
        setMinute(defaultValue?.minute);

        if (defaultValue?.hour) setSelectOptionSelected(true);
        return;
      }
      setValue(defaultValue);
      if (defaultValue) setSelectOptionSelected(true);

      if (type === "date") {
        try {
          setDatePickerDefaultValue(new Date(defaultValue));
          setValue(format(new Date(defaultValue), "dd.MM.yyyy"));
        } catch (error) {
          setDatePickerDefaultValue(new Date(defaultValue));
          setValue(defaultValue);
        }
      }
    } catch (error) {
      console.error(
        "error setting default value of ",
        name,
        defaultValue,
        error
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) setSelectOptionSelected(true);
  }, [value]);

  useEffect(() => {
    setPopupOptions(options);
  }, [options]);

  useEffect(() => {
    if (type !== "selectWithPopup") return;

    if (!popupOptionSearchFilter) {
      setPopupOptions(options);
    } else {
      const temp = [...options].filter((o) =>
        o.title.toLowerCase().includes(popupOptionSearchFilter.toLowerCase())
      );

      setPopupOptions(temp);
    }
  }, [popupOptionSearchFilter]);

  useEffect(() => {
    setSelectWithPopupOpen(selectPopupAutoOpenOptions);
  }, [selectPopupAutoOpenOptions]);

  return (
    <>
      {type === "tel" && (
        <label
          htmlFor={name}
          className={`input ${disableInput ? "disable" : ""} 
          ${value || phoneInputFocused ? "phone_input_focused" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
            greyInputs ? "grey" : ""
          }`}
          onFocus={() => {
            if (!value) setPhoneInputFocused(true);
          }}
          onBlur={() => {
            if (!value) setPhoneInputFocused(false);
          }}
        >
          <PhoneInput
            enableSearch={true}
            disableSearchIcon={true}
            placeholder={label}
            value={value}
            onChange={(e) => {
              if (disableInput) return;
              setValue(e);
              if (validationCallback) validationCallback(e);
            }}
          />
          <span className="label phoneType">{label}</span>
        </label>
      )}
      {type === "date" && (
        <>
          <label
            htmlFor={name}
            className={`input date-input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
              greyInputs ? "grey" : ""
            }`}
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            <div style={{ pointerEvents: "none" }}>
              <input
                type={"text"}
                name={name}
                defaultValue={value}
                placeholder={"DD.MM.YYYY"}
                onChange={() => {
                  if (validationCallback) validationCallback(true);
                }}
              />
              <span className={`label ${icon ? "icon" : ""}`}>
                {icon ? icon : ""}
                {label}
              </span>
            </div>
          </label>
          {openDatePicker && (
            <div
              className="rdp_bg"
              onClick={(e) => {
                if (e.target.className === "rdp_bg") setOpenDatePicker(false);
              }}
            >
              <DayPicker
                showOutsideDays
                mode={"single"}
                captionLayout={"dropdown-buttons"}
                fromYear={1930}
                toYear={2099}
                defaultMonth={
                  defaultValue
                    ? new Date(defaultValue)
                    : datePickerType === "dob"
                    ? new Date(
                        new Date().setFullYear(new Date().getFullYear() - 10)
                      )
                    : new Date()
                }
                selected={datePickerDefaultValue}
                onSelect={(date) => {
                  setValue(format(date, "dd.MM.yyyy"));
                  setDatePickerDefaultValue(date);
                  setOpenDatePicker(false);
                  if (validationCallback) validationCallback(date);
                }}
              />
            </div>
          )}
        </>
      )}
      {type === "time" && (
        <div className="time-input">
          {/* Hour */}
          <label
            htmlFor={name + "-hour"}
            className={`input select_input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
              greyInputs ? "grey" : ""
            } ${selectOptionSelected ? "select-padding" : ""}`}
          >
            <select
              className={selectOptionSelected ? "selected" : ""}
              name={name + "-hour"}
              value={hour}
              onChange={(e) => {
                if (disableInput) return;
                setHour(e.target.value);
                if (e.target.value) setSelectOptionSelected(true);
                else setSelectOptionSelected(false);
                if (validationCallback)
                  validationCallback({
                    hour: e.target.value,
                    minute: minute ?? 0,
                  });
              }}
            >
              <option value="" disabled>
                Hour
              </option>
              {Hours.map((h, index) => {
                return (
                  <option key={index} value={h}>
                    {h}
                  </option>
                );
              })}
            </select>
            {!hideSelectLabel && (
              <span
                className={`label ${icon ? "icon" : ""} ${
                  selectOptionSelected ? "move-label-up" : ""
                }`}
              >
                {icon ? icon : ""}
                Hour
              </span>
            )}
          </label>

          {/* Minute */}
          <label
            htmlFor={name + "-minute"}
            className={`input select_input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
              greyInputs ? "grey" : ""
            } ${selectOptionSelected ? "select-padding" : ""}`}
          >
            <select
              className={selectOptionSelected ? "selected" : ""}
              name={name + "-minute"}
              value={minute}
              onChange={(e) => {
                if (disableInput) return;
                setMinute(e.target.value);
                if (e.target.value) setSelectOptionSelected(true);
                else setSelectOptionSelected(false);
                if (validationCallback)
                  validationCallback({
                    hour: hour ?? "00:00",
                    minute: e.target.value,
                  });
              }}
            >
              <option value="" disabled>
                Minute
              </option>
              {Minutes.map((m, index) => {
                return (
                  <option key={index} value={m}>
                    {m}
                  </option>
                );
              })}
            </select>
            {!hideSelectLabel && (
              <span
                className={`label ${icon ? "icon" : ""} ${
                  selectOptionSelected ? "move-label-up" : ""
                }`}
              >
                {icon ? icon : ""}
                Minute
              </span>
            )}
          </label>
        </div>
      )}
      {["text", "email"].includes(type) && (
        <label
          htmlFor={name}
          className={`input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
            greyInputs ? "grey" : ""
          } ${noAnimation ? "no-animation" : ""}`}
        >
          <input
            ref={refVar}
            type={type}
            name={name}
            value={value}
            placeholder={"temp"}
            onChange={(e) => {
              if (disableInput) return;
              setValue(e.target.value);
              if (validationCallback) validationCallback(e.target.value);
            }}
            onKeyDown={(e) => {
              if (enterPressEvent && e.key === "Enter") enterPressEvent(e);
            }}
            autoFocus={autoFocus}
          />
          <span className={`label ${icon ? "icon" : ""}`}>
            {icon ? icon : ""}
            {noAnimation && value ? "" : label}
          </span>
          {/* <span className="input_border"></span> */}
        </label>
      )}
      {type === "textarea" && (
        <label
          htmlFor={name}
          className={`input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
            greyInputs ? "grey" : ""
          }`}
        >
          <textarea
            name={name}
            value={value}
            placeholder={"temp"}
            onChange={(e) => {
              if (disableInput) return;
              setValue(e.target.value);
              if (validationCallback) validationCallback(e.target.value);
            }}
            // cols="30"
            rows="5"
          ></textarea>

          <span className="label">{label}</span>
        </label>
      )}
      {type === "checkbox" && (
        <label
          htmlFor={name}
          className={`checkbox_input ${disableInput ? "disable" : ""} ${
            transparentInputs ? "transparent" : ""
          } ${greyInputs ? "grey" : ""}`}
        >
          <input
            id={name}
            type={"checkbox"}
            name={name}
            value={value}
            checked={value}
            onChange={(e) => {
              if (disableInput) return;
              setValue(e.target.checked);
              if (validationCallback) validationCallback(e.target.checked);
            }}
          />
          <span className="checkmark"></span>
          <span className="label">{label}</span>
        </label>
      )}
      {type === "switch" && (
        <label
          htmlFor={name}
          className={`checkbox_input type_switch ${
            disableInput ? "disable" : ""
          } ${transparentInputs ? "transparent" : ""} ${
            greyInputs ? "grey" : ""
          }`}
        >
          <span className="label">{label}</span>
          <Switch
            defaultValue={value}
            callback={(updatedValue) => {
              setValue(updatedValue);
              if (validationCallback) validationCallback(updatedValue);
            }}
          />
        </label>
      )}
      {type === "select" && (
        <label
          htmlFor={name}
          className={`input select_input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
            greyInputs ? "grey" : ""
          } ${selectOptionSelected ? "select-padding" : ""}`}
        >
          <select
            className={selectOptionSelected ? "selected" : ""}
            name={name}
            value={value}
            onChange={(e) => {
              if (disableInput) return;
              setValue(e.target.value);
              if (e.target.value) setSelectOptionSelected(true);
              else setSelectOptionSelected(false);
              if (validationCallback) validationCallback(e.target.value);
            }}
          >
            <option value="" disabled>
              {label}
            </option>
            {options.map(({ key, title }, index) => {
              return (
                <option key={index} value={key}>
                  {title}
                </option>
              );
            })}
          </select>
          {!hideSelectLabel && (
            <span
              className={`label ${icon ? "icon" : ""} ${
                selectOptionSelected ? "move-label-up" : ""
              }`}
            >
              {icon ? icon : ""}
              {label}
            </span>
          )}
        </label>
      )}
      {type === "selectWithPopup" && (
        <>
          <div
            className="select-with-popup"
            onClick={() => setSelectWithPopupOpen(true)}
          >
            <span style={{ pointerEvents: "none" }}>
              <label
                htmlFor={name}
                className={`input select_input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
                  greyInputs ? "grey" : ""
                } ${selectOptionSelected ? "select-padding" : ""}`}
              >
                <select
                  className={selectOptionSelected ? "selected" : ""}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    const selectedOption = popupOptions.filter(
                      (po) => po.key === e.target.value
                    );
                    if (selectedOption[0]) {
                      setValue(selectedOption[0].key);
                      if (validationCallback)
                        validationCallback(selectedOption[0].key);
                    }
                  }}
                >
                  <option value="" disabled></option>
                  {options.map(({ key, title }, index) => {
                    return (
                      <option key={index} value={key}>
                        {title}
                      </option>
                    );
                  })}
                </select>
                <span
                  className={`label ${
                    selectOptionSelected ? "move-label-up" : ""
                  }`}
                >
                  {label}
                </span>
              </label>
            </span>
          </div>
          <SlideInPopup
            titleInsteadOfBar={label}
            open={selectWithPopupOpen}
            opener={setSelectWithPopupOpen}
            bottom={true}
            bottomLeftWidth={450}
            backButtonAction={selectPopupBackAction}
            dark={greyInputs}
            backgroundColor={greyInputs ? "#0d0d0d" : "#ffffff"}
          >
            <div className="select_popup">
              {/* Search filter */}
              <label
                htmlFor={"filter_search_" + label}
                className="input no-animation grey"
              >
                <input
                  type="text"
                  name={"filter_search_" + label}
                  placeholder="temp"
                  value={popupOptionSearchFilter}
                  onChange={(e) => {
                    setPopupOptionsSearchFilter(e.target.value);
                  }}
                />
                <span className="label icon">{search_magnifier_white_svg}</span>
              </label>

              <div className={"dropdown"}>
                {popupOptions.map((option, index) => {
                  const { key, title, selected } = option;

                  return (
                    <div
                      key={index}
                      className={"dropdown_option"}
                      onClick={() => {
                        const temp = options.map((op) => {
                          if (op.key === key) {
                            return { ...op, selected: true };
                          } else {
                            return { ...op, selected: false };
                          }
                        });
                        setPopupOptions(temp);
                        setValue(key);
                        if (validationCallback) validationCallback(key);
                        setSelectOptionSelected(true);
                        setSelectWithPopupOpen(false);
                      }}
                    >
                      <div className="option_details">
                        <span className={"label"}>{title}</span>
                      </div>
                      <div className="selected_checkmark">
                        {selected && purple_checkmark}
                      </div>
                    </div>
                  );
                })}
              </div>
              <span className="close_popup_btn_bg"></span>
              <Button
                type={"roundedSquare"}
                color={"grey_light"}
                text={selectPopupNextButtonText ?? "Close"}
                size={"medium"}
                extraClass="close_popup"
                callback={
                  selectPopupNextAction
                    ? selectPopupNextAction
                    : () => setSelectWithPopupOpen(false)
                }
              />
            </div>
          </SlideInPopup>
        </>
      )}
      {type === "image" && (
        <>
          <div className="input image_input">
            <Button
              text={"Upload Image"}
              type={"roundedSquare"}
              size={"small"}
              color={"dark_mode_white"}
              callback={() => imageInputRef?.current?.click()}
            />
            <Button
              text={"Delete Image"}
              type={"roundedSquare"}
              size={"small"}
              color={"red"}
              callback={() => {
                if (validationCallback) validationCallback(null);
              }}
            />
          </div>
          {imageSizeError && (
            <p className="red_text">Max image size allowed is 5MB</p>
          )}
          <input
            name={name}
            type="file"
            hidden
            accept="image/*"
            ref={imageInputRef}
            onChange={(e) => {
              if (e.target.files.length > 0) {
                setImageSizeError(false);
                const targetFile = e.target.files[0];
                if (!targetFile.type.includes("image")) return;
                if (targetFile.size > 5000000) {
                  setImageSizeError(true);
                  return;
                } // 5MB size limit
                if (validationCallback) validationCallback(targetFile);
              } else {
                if (validationCallback) validationCallback(null);
              }
            }}
          />
        </>
      )}
      {type === "location" && (
        <div className="filtered_input static">
          <label
            htmlFor={name}
            className={`input ${disableInput ? "disable" : ""} 
         ${transparentInputs ? "transparent" : ""} ${
              greyInputs ? "grey" : ""
            } ${noAnimation ? "no-animation" : ""}`}
          >
            <input
              type={"text"}
              name={name}
              value={value}
              placeholder={"location"}
              autoComplete="new-password"
              onCut={(e) => {
                e.preventDefault();
              }}
              onCopy={(e) => {
                e.preventDefault();
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
              onChange={(e) => {
                if (disableInput) return;
                if (!e.target.value) {
                  setLocationSearchOptions([]);
                  setValue("");
                  if (validationCallback) validationCallback(null);
                  return;
                }
                setValue(e.target.value);
                searchForLocationOnMaps(value);
              }}
            />
            <span className={`label ${icon ? "icon" : ""}`}>
              {icon ? icon : ""}
              {noAnimation && value ? "" : label}
            </span>
          </label>

          {value && locationSearchOptions.length > 0 && (
            <div className="filter_options hide_scrollbar">
              {locationSearchOptions.map((mp, index) => {
                const { description } = mp;
                return (
                  <p
                    key={index}
                    className="filtered_option"
                    onClick={() => {
                      if (validationCallback) validationCallback(mp);
                      setValue(description);
                      setLocationSearchOptions([]);
                    }}
                  >
                    {description}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      )}
      {type === "filterOptions" && (
        <div className="filtered_input static">
          <label
            htmlFor={name}
            className={`input ${disableInput ? "disable" : ""} 
          ${transparentInputs ? "transparent" : ""} ${
              greyInputs ? "grey" : ""
            } ${noAnimation ? "no-animation" : ""}`}
          >
            <input
              ref={refVar}
              type={type}
              name={name}
              value={value}
              placeholder={label}
              onChange={(e) => {
                if (disableInput) return;
                setFilterInputSearchText(e.target.value);
                setValue(e.target.value);
                filterOptionsFunc(e.target.value);
                if (validationCallback) validationCallback(e.target.value);
              }}
            />
            <span className={`label ${icon ? "icon" : ""}`}>
              {icon ? icon : ""}
              {label}
            </span>
          </label>
          {filteredOptions.length > 0 && (
            <div className="filter_options hide_scrollbar">
              {filteredOptions.map((fo, index) => {
                return (
                  <p
                    key={index}
                    className="filtered_option"
                    onClick={() => {
                      if (validationCallback) validationCallback(fo);
                      setValue(fo.title);
                      setFilterInputSearchText("");
                      setFilteredOptions([]);
                    }}
                  >
                    {fo.title}
                  </p>
                );
              })}
            </div>
          )}
          {/* {filteredOptions.length ===0 && value && filterInputSearchText && (
            <div className="filter_options hide_scrollbar">
              <p className="filtered_option">
                {noFilterOptionText ?? "No result found"}
              </p>
            </div>
          )} */}
        </div>
      )}
      {type === "otp" && (
        <label
          htmlFor={name}
          className={`otp-input ${disableInput ? "disable" : ""}`}
        >
          <OtpInput
            value={value}
            onChange={(otp) => {
              setValue(otp);
              if (validationCallback) validationCallback(otp);
            }}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
          />
        </label>
      )}
    </>
  );
};

export default Input;
