import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { BTLRBetaContext } from "../../../contexts/btlrBeta.context";

import BusinessDashboardContainer from "./components/businessContainer";
import Form from "../../../components/common/form/form";
import Input from "../../../components/common/input/input";
import Button from "../../../components/common/button/button";
import SlideInPopup from "../../../components/common/slideInPopup/slideInPopup";

import { updateBusinessTypesOptionsInList } from "./data.service";

import languagesList from "../../../data/languagesList";

import {
  preferenceInputs,
  generatePreferencesOfUser,
  checkBusinessPreferenceAccess,
  getAllUsersSharedWithBusiness,
  examples,
  examplesData,
} from "./data.service";

import { checkLoginCookieBetaBusiness } from "../../../api/helpers/authCookies";
import { search_magnifier_svg } from "../../../assets/svgs/icons";
import { right_arrow_dark_bg } from "../../../assets/svgs/icons";

import "./styles.scss";

const UserPreferences = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();

  const [BTLRBeta, setBTLRBeta] = useContext(BTLRBetaContext);

  const [updatedPreferenceInputs, setUpdatedPreferenceInputs] = useState([
    ...preferenceInputs,
  ]);
  const [sharedUsers, setSharedUsers] = useState([]); // Set via API call
  const [sharedUsersByDates, setSharedUsersByDates] = useState([]); // Set via API call
  const [selectedUser, setSelectedUser] = useState({
    key: user_id,
    title: "",
  });

  const [loadingPreference, setLoadingPreference] = useState(false);
  const [userPreferenceText, setUserPreferenceText] = useState("");
  const [preferenceLanguage, setPreferenceLanguage] = useState("en-GB");
  const [preferenceExample, setPreferenceExample] = useState("");
  const [preferenceExampleValues, setPreferenceExampleValues] = useState();

  console.log(selectedUser);

  // Fetch and update business types from API
  useEffect(() => {
    updateBusinessTypesOptionsInList(
      0,
      preferenceInputs,
      setUpdatedPreferenceInputs
    );
  }, []);

  useEffect(() => {
    if (user_id) {
      setBTLRBeta({ ...BTLRBeta, userIdBusiness: user_id });
      setSelectedUser({ title: "Guest", key: user_id });
    }
  }, [user_id]);

  const handleSubmitPreference = ({ businessType, question }) => {
    if (!selectedUser?.key) {
      toast.warn("Select a guest first", { theme: "dark" });
      return;
    }

    setLoadingPreference(true);

    generatePreferencesOfUser(
      BTLRBeta?.selectedBusiness?._id ??
        BTLRBeta?.unlinkedGuestList?.[0]?.business,
      selectedUser?.key ?? user_id,
      businessType,
      question,
      preferenceLanguage,
      ({
        preferenceString,
        consumedPreferenceView,
        allocatedPreferenceView,
      }) => {
        setUserPreferenceText(preferenceString);
        setLoadingPreference(false);

        // Reduce tokens number in context
        if (BTLRBeta?.selectedBusiness) {
          // Only for owned businesses
          const {
            selectedBusiness: { consumedPreferenceView: usedTokens },
          } = BTLRBeta;

          setBTLRBeta({
            ...BTLRBeta,
            selectedBusiness: {
              ...BTLRBeta?.selectedBusiness,
              consumedPreferenceView: usedTokens + 1,
            },
          });
        }

        if (
          allocatedPreferenceView === undefined ||
          consumedPreferenceView === undefined
        )
          return;

        const businessAccessType =
          BTLRBeta?.selectedBusiness?.accessType ?? "GUEST";

        const remainingTokens =
          allocatedPreferenceView - consumedPreferenceView;

        // Show notification
        if (remainingTokens <= 0) {
          toast.error("Limit reached, contact  your admin to upgrade.", {
            theme: "dark",
          });
        } else if (
          businessAccessType === "ADMIN" ||
          (businessAccessType !== "ADMIN" && remainingTokens < 8)
        ) {
          toast.info(
            `${remainingTokens}/${allocatedPreferenceView} Preference requests remaining. Upgrade Now.`,
            {
              theme: "dark",
            }
          );
        }
      },
      () => {
        // navigate("/business/account");
      },
      BTLRBeta?.businessLoginToken
    );
  };

  const businessLoginToken =
    checkLoginCookieBetaBusiness() ?? BTLRBeta?.businessLoginToken;

  const loadParsedSharedUsersList = (list) => {
    console.log("Guests List to parse", list);
    const combinedList = [];
    list.forEach((su) => {
      su?.visits?.forEach((v) => {
        combinedList.push({
          key: su._id,
          ...su,
          ...v,
        });
      });
    });

    let parsedList = combinedList
      .map((su) => {
        return {
          title:
            su?.firstName && su?.lastName
              ? `${su.firstName} ${su.lastName}`
              : su?.email ?? "Anonymous Guest",
          ...su,
        };
      })
      .sort((a, b) => {
        const arrivalTimeA = new Date(a?.arrivalDate).getTime();
        const arrivalTimeB = new Date(b?.arrivalDate).getTime();

        return arrivalTimeA - arrivalTimeB;
      });

    console.log("Parsed list of guests", parsedList);

    const sharedUsersWithDates = {};
    let usersWithoutArrivalDate = [];
    parsedList.forEach((su) => {
      if (!su?.arrivalDate) {
        usersWithoutArrivalDate.push(su);
        return;
      }
      if (!sharedUsersWithDates[su?.arrivalDate])
        sharedUsersWithDates[su?.arrivalDate?.replace("Z", "")] = [];
      sharedUsersWithDates[su?.arrivalDate?.replace("Z", "")].push(su);
    });

    sharedUsersWithDates["default"] = usersWithoutArrivalDate;

    setSharedUsers(parsedList);
    setSharedUsersByDates(sharedUsersWithDates);

    if (user_id) {
      let findUser = parsedList.find((su) => su.key === user_id);
      if (findUser) setSelectedUser(findUser);
    }
  };

  const loadBusinessProfileDetails = () => {
    if (!BTLRBeta?.selectedBusiness?._id) return;
    // Get all users
    getAllUsersSharedWithBusiness(
      BTLRBeta?.selectedBusiness?._id,
      (list) => {
        loadParsedSharedUsersList(list);
      },
      businessLoginToken
    );
  };

  useEffect(() => {
    if (!businessLoginToken) {
      return;
    }

    // If loading specific user preferences URL - Not applicable right now
    // if (user_id) {
    //   checkBusinessPreferenceAccess(user_id, BTLRBeta?.businessLoginToken);
    // }

    loadBusinessProfileDetails();
  }, [businessLoginToken, BTLRBeta?.selectedBusiness?._id]);

  // Load unlinked guests list of not selected any business
  useEffect(() => {
    if (BTLRBeta?.selectedBusiness?._id || !BTLRBeta?.unlinkedGuestList) return;

    const parsedList = [];

    BTLRBeta?.unlinkedGuestList.forEach((ug) => {
      ug?.visits?.forEach((v) => {
        parsedList.push({
          ...ug?.user,
          visits: [{ ...v }],
        });
      });
    });

    loadParsedSharedUsersList(parsedList);
  }, [BTLRBeta?.unlinkedGuestList, BTLRBeta?.selectedBusiness?._id]);

  // Load old form values
  useEffect(() => {
    try {
      const prefilledValues = { businessType: "", question: "" };
      const values = JSON.parse(localStorage.getItem("preferenceValues"));
      if (!values) return;

      prefilledValues.businessType = values?.businessType ?? "";
      prefilledValues.question = values?.question ?? "";
      setPreferenceExampleValues(prefilledValues);
      setPreferenceLanguage(values?.language ?? "en-GB");
    } catch (error) {
      console.error(
        "Error trying to load old form values of prefernces generation form",
        error
      );
    }
  }, []);

  return (
    <BusinessDashboardContainer>
      <section id="view-preference">
        <div className="container rounded_box no_border">
          <div className="preferences_container">
            <div className="left">
              <h1 className="heading basier">Guest</h1>
              <div className="column beta_search_guest_input">
                <Input
                  name={"verify_preference_business_users"}
                  type="filterOptions"
                  label={"Search For Guest"}
                  noFilterOptionText={"No Guest Found"}
                  transparentInputs={true}
                  defaultValue={selectedUser?.title}
                  icon={search_magnifier_svg}
                  filterOptions={sharedUsers}
                  validationCallback={(user) => {
                    setSelectedUser(user);
                  }}
                />
              </div>
              <div className="guests_list hide_scrollbar">
                {Object.keys(sharedUsersByDates).map((date, index) => {
                  if (sharedUsersByDates[date].length === 0) return;

                  let todayDate = new Date(date) === new Date();

                  return (
                    <div key={index} className="guests_by_date">
                      <p
                        className={`text light_grey_text ${
                          todayDate ? "blue_text" : ""
                        }`}
                      >
                        {todayDate
                          ? "Today"
                          : date == "default"
                          ? "Without arrival date"
                          : format(new Date(date), "dd.MM.yyyy")}
                      </p>

                      {sharedUsersByDates[date].map((su, index) => {
                        return (
                          <div
                            key={index}
                            className={`guests_div ${
                              selectedUser?._id === su?._id ? "selected" : ""
                            }`}
                          >
                            <button
                              className={`single_guest`}
                              onClick={() => setSelectedUser(su)}
                            >
                              <div className="left">
                                <div className="user-icon">
                                  {su.title.charAt(0)}
                                </div>
                                <p className="name">
                                  {su.title}{" "}
                                  <span className="info">{su.occasion}</span>
                                </p>
                              </div>
                              <div className="right">{right_arrow_dark_bg}</div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="right">
              <div className="preference_section">
                <h1
                  className="heading basier"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Preference{" "}
                  <span
                    className="text light_grey_text manrope"
                    style={{
                      marginBottom: 0,
                      marginTop: "10px",
                      fontWeight: "normal",
                    }}
                  >
                    {selectedUser?.title}
                  </span>
                </h1>
                {/* <h2 className="text fourHundred medium manrope light_grey_text">
                View preference based on your business & activity required.
              </h2> */}
              </div>
              <div className="preference-form">
                <Form
                  inputs={updatedPreferenceInputs}
                  prefilledValues={preferenceExampleValues}
                  submit_text={
                    loadingPreference
                      ? "🪄 Thinking"
                      : !userPreferenceText || preferenceExample
                      ? "View Preference"
                      : "Regenerate"
                  }
                  // formClass={"form-style"}
                  submitForm={
                    loadingPreference
                      ? () => {}
                      : (values) =>
                          handleSubmitPreference({
                            ...values,
                            businessType:
                              values?.businessType?.title ??
                              values?.businessType,
                          })
                  }
                  transparentInputs={true}
                />
                <div className="custom_select_view_preferences">
                  <div className="examples">
                    <Input
                      name={"verify_preference_example"}
                      type="select"
                      defaultValue={preferenceExample}
                      options={examples}
                      label={"View Example?"}
                      hideSelectLabel
                      transparentInputs={true}
                      validationCallback={(value) => {
                        setPreferenceExample(value);
                        const exampleData = examplesData[value];
                        if (!exampleData) return;

                        const { result } = exampleData;

                        // setSelectedUser("");
                        setPreferenceExampleValues({ ...exampleData });
                        setUserPreferenceText(result);
                      }}
                    />
                  </div>
                  <Input
                    name={"verify_preference_business_language"}
                    type="select"
                    defaultValue={preferenceLanguage}
                    options={languagesList}
                    label={"Language"}
                    hideSelectLabel
                    transparentInputs={true}
                    validationCallback={(value) => setPreferenceLanguage(value)}
                  />
                </div>
              </div>

              {userPreferenceText && (
                <>
                  <p className="caption vertical_space blue_text">Guest</p>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                    }}
                  >
                    <div className="row-align-center">
                      <div className="user-icon medium">
                        {selectedUser?.title?.charAt(0) ?? "B"}
                      </div>
                      <h3
                        className="manrope sub_heading medium"
                        style={{ marginBottom: 0 }}
                      >
                        BTLR Preference
                      </h3>
                    </div>
                    <p className="pref_text vertical_space manrope light_grey_text">
                      {userPreferenceText}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="preference_mobile_modal">
          <SlideInPopup
            open={selectedUser}
            opener={setSelectedUser}
            backgroundColor="#000000"
            bottom
            dark
          >
            <div className="slide_in_padding">
              <div className="preference_section">
                <h1
                  className="heading basier"
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  Preference{" "}
                  <span
                    className="text light_grey_text manrope"
                    style={{
                      marginBottom: 0,
                      marginTop: "5px",
                      fontWeight: "normal",
                    }}
                  >
                    {selectedUser?.title}
                  </span>
                </h1>
                {/* <h2 className="text fourHundred medium manrope light_grey_text">
                View preference based on your business & activity required.
              </h2> */}
              </div>
              <div className="preference-form">
                <Form
                  inputs={updatedPreferenceInputs}
                  prefilledValues={preferenceExampleValues}
                  submit_text={
                    loadingPreference
                      ? "🪄 Thinking"
                      : !userPreferenceText || preferenceExample
                      ? "View Preference"
                      : "Regenerate"
                  }
                  // formClass={"form-style"}
                  submitForm={
                    loadingPreference
                      ? () => {}
                      : (values) => handleSubmitPreference(values)
                  }
                  transparentInputs={true}
                />
                <div className="custom_select_view_preferences">
                  <div className="examples">
                    <Input
                      name={"verify_preference_example"}
                      type="select"
                      defaultValue={preferenceExample}
                      options={examples}
                      label={"View Example?"}
                      transparentInputs={true}
                      validationCallback={(value) => {
                        setPreferenceExample(value);
                        const exampleData = examplesData[value];
                        if (!exampleData) return;

                        const { result } = exampleData;

                        // setSelectedUser("");
                        setPreferenceExampleValues({ ...exampleData });
                        setUserPreferenceText(result);
                      }}
                    />
                  </div>
                  <Input
                    name={"verify_preference_business_language"}
                    type="select"
                    defaultValue={preferenceLanguage}
                    options={languagesList}
                    label={"Language"}
                    transparentInputs={true}
                    validationCallback={(value) => setPreferenceLanguage(value)}
                  />
                </div>
              </div>

              {userPreferenceText && (
                <>
                  <p className="caption vertical_space blue_text">Guest</p>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                    }}
                  >
                    <div className="row-align-center">
                      <div className="user-icon medium">
                        {selectedUser?.title?.charAt(0) ?? "B"}
                      </div>
                      <h3
                        className="manrope sub_heading medium"
                        style={{ marginBottom: 0 }}
                      >
                        BTLR Preference
                      </h3>
                    </div>
                    <p className="pref_text vertical_space manrope light_grey_text">
                      {userPreferenceText}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="slide_in_bottom">
              <Button
                type={"roundedSquare"}
                color={"dark_mode_grey"}
                text={"Close"}
                size={"medium"}
                centered={true}
                callback={() => setSelectedUser(null)}
              />
            </div>
          </SlideInPopup>
        </div>
      </section>
    </BusinessDashboardContainer>
  );
};

export default UserPreferences;
