import React from "react";

import Button from "../button/button";

import "./confirmationModal.scss";

const ConfirmationModal = ({
  dark = false,
  open,
  opener,
  title,
  info,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "dark_red",
  cancelButtonColor = "grey_light",
  confirmButtonCallback,
  cancelButtonCallback = undefined,
  showConfirmButton = true,
  desktopLeftSide = false,
}) => {
  const closeModal = (e) => {
    if (e.target.classList.contains("confirmation_modal_popup")) opener(!open);
  };

  return (
    <div
      className={`confirmation_modal_popup ${!open ? "close" : "open"} ${
        desktopLeftSide ? "desktopLeftSide" : ""
      } ${dark ? "dark" : ""}`}
      onClick={(e) => closeModal(e)}
    >
      <div className={`popup_container`}>
        <h2 className={`sub_heading semi center_align black`}>{title}</h2>
        <p className="text manrope light_black_text center_align">{info}</p>
        {showConfirmButton && (
          <Button
            text={confirmButtonText}
            type={"roundedSquare"}
            size={"medium"}
            color={confirmButtonColor}
            callback={() => {
              confirmButtonCallback();
              opener(null);
            }}
          />
        )}
        <Button
          text={cancelButtonText}
          type={"roundedSquare"}
          size={"medium"}
          color={cancelButtonColor}
          callback={() => {
            opener(null);
            if (cancelButtonCallback) cancelButtonCallback();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmationModal;
