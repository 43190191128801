import React from "react";

import Modal from "../modal/modal";
import SlideInPopup from "../slideInPopup/slideInPopup";
import Button from "../button/button";

import "./styles.scss";

const HybridModal = ({ open, opener, children, ...props }) => {
  if (window.innerWidth <= 450)
    return (
      <SlideInPopup open={open} opener={opener} bottom={true} {...props}>
        <div className="slide_in_padding">{children}</div>
        <div className="slide_in_bottom">
          <Button
            type={"roundedSquare"}
            color={"dark_mode_grey"}
            text={"Close"}
            size={"medium"}
            centered={true}
            callback={opener}
          />
        </div>
      </SlideInPopup>
    );
  else
    return (
      <Modal open={open} opener={opener} {...props}>
        {children}
      </Modal>
    );
};

export default HybridModal;
